/* eslint-disable sort-imports, no-unused-vars */
import { arrayOf, shape, string } from 'prop-types'
import Headshot from '../Headshot/Headshot'
import React from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'

import stripes from '../../assets/images/stripes.svg'
import jsconf from '../../assets/images/logo.svg'
import clipPath from '../../assets/images/abstract/triangle-clip-path.svg'
import triangle from '../../assets/images/abstract/triangle.svg'
import wavyLine from '../../assets/images/abstract/wavy-line.svg'
import TeamBackground from '../../assets/images/abstract/2.svg'

const HEADSHOT = 50
const LAST_NAME = /\W(.+$)/

const CardStyles = createGlobalStyle`
  html, body {
    overflow: hidden;
    height: 628px;
    width: 1200px;
  }
`

const Canvas = styled.div`
  background-image: url(${TeamBackground});
  background-size: 500px 500px;
  height: 628px;
  overflow: hidden;
  position: relative;
  width: 1200px;
  &:after {
    bottom: 150px;
    height: 100px;
    left: 10px;
    width: 100px;
  }
  &:before {
    bottom: 50px;
    height: 200px;
    left: 50px;
    width: 200px;
  }
`

const Members = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  position: absolute;
  top: 10px;
  left: 0px;
  height: 610px;
  width: 650px;
  [data-count='2'] & {
    grid-template-rows: 1fr;
    height: 440px;
    width: 910px;
  }
  [data-count='5'] & {
    grid-template-columns: repeat(3, 1fr);
    width: 960px;
  }
  /* outline: solid 5px salmon; */
`

const Member = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  justify-content: center;
  justify-items: center;
  margin-top: -15px;
  margin-left: 10px;
  margin-right: 10px;
  width: var(--cellsize);
  height: var(--cellsize);
  [data-count='2'] & {
    --cellsize: 395px;
    --framesize: calc(var(--cellsize) - 90px);
  }
  [data-count='4'] &,
  [data-count='5'] & {
    --cellsize: 300px;
    --framesize: calc(var(--cellsize) - 80px);
  }
`

const Name = styled.div``

const FirstName = styled.div`
  color: ${({ theme }) => theme.colors.yellow300};
  font-family: Thunderstorm;
  font-size: 55px;
  letter-spacing: 1px;
  margin-bottom: -55px;
  margin-left: 12px;
  position: relative;
  text-shadow: -1px -1px black, 1px -1px black, -1px 1px black, 2px 2px black,
    3px 3px black;
  transform-origin: bottom left;
  transform: rotate(-10deg);
  z-index: 4;
  [data-count='4'] &,
  [data-count='5'] & {
    font-size: 45px;
    margin-bottom: -45px;
  }
`

const LastName = styled.div`
  ${({ theme }) => theme.typography.secondary};
  color: transparent;
  font-size: 80px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 1.5px;
  position: relative;
  white-space: nowrap;
  z-index: 3;
  [data-count='4'] &,
  [data-count='5'] & {
    font-size: 70px;
  }
  &:before {
    content: attr(data-shadow);
    display: inline-block;
    left: 0;
    position: absolute;
    text-shadow: 2px 2px ${({ theme }) => theme.colors.purple600},
      3px 3px ${({ theme }) => theme.colors.purple600},
      5px 5px ${({ theme }) => theme.colors.pink200},
      6px 6px ${({ theme }) => theme.colors.pink200},
      7px 7px ${({ theme }) => theme.colors.purple200},
      8px 8px ${({ theme }) => theme.colors.purple200};
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
  }
  &:after {
    background-clip: text;
    -webkit-background-clip: text;
    background-color: ${({ theme }) => theme.colors.green300};
    background-image: linear-gradient(
      ${({ theme }) => theme.colors.blue400},
      ${({ theme }) => theme.colors.green500}
    );
    color: transparent;
    content: attr(data-shadow);
    display: inline-block;
    left: 0;
    padding-right: 1ch;
    position: absolute;
    z-index: 2;
  }
`

const Avatar = styled.div`
  margin: -50px auto 0;
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--framesize);
  width: var(--framesize);
`

const AvatarFrame = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.blue200},
    ${({ theme }) => theme.colors.yellow200},
    ${({ theme }) => theme.colors.pink200}
  );
  border: solid 3px ${({ theme }) => theme.colors.purple200};
  bottom: 0;
  box-shadow: inset 0 0 0px 10px white;
  position: absolute;
  top: 13%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
`

const StyledHeadshot = styled(Headshot)`
  img {
    padding: 13px;
  }
`

const Footer = styled.footer`
  display: flex;
  align-items: center;
  position: absolute;
  left: 640px;
  bottom: 20px;
  width: 510px;
  height: max-content;
`

const TeamName = styled.h2`
  color: ${({ theme }) => theme.colors.blue200};
  font-size: 80px;
  font-family: Futura;
  letter-spacing: 1px;
  overflow: hidden;
  padding-right: 1ch; /* clips the bottom otherwise */
  margin-right: -1ch;
  text-align: center;
  -webkit-text-stroke: 1px white;
  text-orientation: mixed;
  text-stroke: 1px white;
  text-shadow: 2px 2px ${({ theme }) => theme.colors.purple300},
    3px 3px ${({ theme }) => theme.colors.purple300},
    4px 4px ${({ theme }) => theme.colors.purple300},
    5px 5px ${({ theme }) => theme.colors.purple300},
    6px 6px ${({ theme }) => theme.colors.purple300};
  text-transform: uppercase;
  z-index: 5;
  letter-spacing: 0;
  position: absolute;
  left: 640px;
  bottom: 180px;
  [data-count='2'] & {
    left: unset;
    right: 160px;
  }
`

const Logo = styled.div`
  flex: 1;
  img {
    width: 200px;
  }
`

const Details = styled.aside`
  color: ${({ theme }) => theme.colors.blue900};
  font-family: FuturaBook;
  font-size: 1.25em;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const Date = styled.span`
  display: block;
  font-family: FuturaHeavy;
  font-size: 1.65em;
  line-height: 1.3;
`

const Location = styled.span`
  display: block;
  text-transform: uppercase;
`

const Url = styled.span`
  color: ${({ theme }) => theme.colors.pink500};
  display: block;
`

function getFileName (name) {
  return `${name.toLowerCase().replace(' ', '-')}`
}

const TeamCard = ({ members, name }) => {
  const names = members.map(({ name: staffName }) => staffName)
  return (
    <Canvas data-count={members.length}>
      <CardStyles/>
      <Members>
        {members.map(({ name: staffName }) => {
          const avatarName = getFileName(staffName)
          const [firstName, lastName] = staffName.split(LAST_NAME)
          return (
            <Member key={staffName}>
              <Name>
                <FirstName>{firstName}</FirstName>{' '}
                <LastName data-shadow={lastName}>{lastName}</LastName>
              </Name>
              <Avatar>
                <StyledHeadshot name={avatarName}/>
                <AvatarFrame/>
              </Avatar>
            </Member>
          )
        })}
      </Members>
      <TeamName>{name}</TeamName>
      <Footer>
        <Logo>
          <img alt="" src={jsconf}/>
        </Logo>
        <Details>
          <Date>August 12 - 14</Date>
          <Location>Carlsbad, CA</Location>
          <Url>https://2019.jsconf.us</Url>
        </Details>
      </Footer>
    </Canvas>
  )
}

TeamCard.propTypes = {
  members: arrayOf(
    shape({
      name: String
    })
  ).isRequired,
  name: string
}

export default TeamCard
