import Heading from './Heading'
import React from 'react'
import styled from 'styled-components'

const H1 = styled.h1`
  color: ${({ theme }) => theme.colors.pink500};
  font-family: Thunderstorm;
  font-size: 16vw;
  font-weight: normal;
  text-align: center;
  text-shadow: 2.5px 2.5px 0 ${({ theme }) => theme.colors.darkBlue100};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacer.sm};
  @media ${({ theme }) => theme.devices.mobileMediumMin} {
    font-size: 11vw;
    margin-bottom: ${({ theme }) => theme.spacer.md};
    text-shadow: 2.5px 2.5px 0 ${({ theme }) => theme.colors.darkBlue700};
    transform: rotate(-4deg);
    position: relative;
    padding-top: ${({ theme }) => theme.spacer.md};
    padding-bottom: ${({ theme }) => theme.spacer.md};
    white-space: nowrap;
    width: max-content;
    &:before {
      background-color: ${({ theme }) => theme.colors.yellow700};
      background-image: linear-gradient(#ffaf0e 1px, transparent 1px),
        linear-gradient(90deg, #ffaf0e 1px, transparent 1px);
      background-size: 16px 16px;
      clip-path: polygon(0 20%, 100% 30%, 100% 70%, 5% 90%);
      content: ' ';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(4deg);
      z-index: -2;
    }
    &:after {
      background-color: ${({ theme }) => theme.colors.blue300};
      background-image: linear-gradient(
          ${({ theme }) => theme.colors.blue400} 8px,
          transparent 4px
        ),
        linear-gradient(
          90deg,
          ${({ theme }) => theme.colors.blue400} 0%,
          ${({ theme }) => theme.colors.blue400} 50%,
          transparent 50.1%
        );
      background-size: 1px 16px, auto;
      content: ' ';
      clip-path: polygon(10% 12%, 90% 12%, 50% 100%, 50% 100%);
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(4deg);
      z-index: -1;
    }
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    font-size: 65px;
  }
`

const H2 = styled((props) => <Heading as="h2" {...props}/>)`
  ${({ theme }) => theme.typography.heading.lg};
  color: transparent;
  font-family: 'Gill Sans';
  margin-bottom: 19px;
  margin-top: 49px;
  text-shadow: 3px 3px
    ${({ shadowColor = 'blue200', theme }) => theme.colors[shadowColor]};
  text-stroke: 1px
    ${({ strokeColor = 'black300', theme }) => theme.colors[strokeColor]};
  -webkit-text-stroke: 1px
    ${({ strokeColor = 'black300', theme }) => theme.colors[strokeColor]};
  text-transform: uppercase;
`

const H3 = styled((props) => <Heading as="h3" {...props}/>)`
  ${({ theme }) => theme.typography.heading.sm};
  color: ${({ theme }) => theme.colors.yellow900};
  font-family: Futura;
  letter-spacing: 2px;
  margin-bottom: 7px;
  text-transform: uppercase;
`

const H4 = styled((props) => <Heading as="h4" {...props}/>)`
  ${({ theme }) => theme.typography.heading.md};
  ${({ theme }) => theme.typography.secondary};
  color: ${({ theme }) => theme.colors.pink500};
  font-size: 30px;
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 12px;
`

const H5 = styled((props) => <Heading as="h5" {...props}/>)`
  ${({ theme }) => theme.typography.heading.xsm};
  color: transparent;
  font-family: 'Gill Sans';
  text-shadow: -1px -1px ${({ theme }) => theme.colors.darkBlue800};
  text-stroke: 1px ${({ theme }) => theme.colors.darkBlue800};
  -webkit-text-stroke: 1px ${({ theme }) => theme.colors.darkBlue800};
  text-transform: uppercase;
`

const H6 = styled((props) => <Heading as="h6" {...props}/>)`
  ${({ theme }) => theme.typography.heading.xsm};
  font-weight: normal;
  text-transform: uppercase;
`

export { H1, H2, H3, H4, H5, H6 }
