import Helmet from 'react-helmet'
import React from 'react'
import { string } from 'prop-types'

const TwitterCard = ({ description, image, name, title, type, url }) => {
  const meta = [
    { content: type, name: 'twitter:card' },
    { content: description, name: 'twitter:description' },
    { content: 'Date', name: 'twitter:label1' },
    { content: 'Aug. 12, 2019', name: 'twitter:data1' },
    { content: 'Location', name: 'twitter:label2' },
    { content: 'Carlsbad, CA', name: 'twitter:data2' },
    { content: name, name: 'twitter:site' },
    { content: title, name: 'twitter:title' }
  ]
  if (image) {
    meta.push({ content: image, name: 'twitter:image' })
  }
  return <Helmet meta={meta}/>
}

TwitterCard.defaultProps = {
  type: 'summary'
}

TwitterCard.propTypes = {
  description: string.isRequired,
  image: string,
  name: string.isRequired,
  title: string.isRequired,
  type: string,
  url: string.isRequired
}

export default TwitterCard
