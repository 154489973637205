import Helmet from 'react-helmet'
import React from 'react'
import { string } from 'prop-types'

const Basics = ({ description, name }) => (
  <Helmet
    meta={[
      { content: name, name: 'application-name' },
      { content: description, name: 'description' }
    ]}
  />
)

Basics.propTypes = {
  description: string.isRequired,
  name: string.isRequired
}

export default Basics
