import { node } from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Quote = styled.blockquote`
  background-color: ${({ theme }) => theme.colors.black25};
  font-family: 'Gill Sans';
  margin-left: var(--whitespace-neg);
  margin-right: var(--whitespace-neg);
  padding: ${({ theme }) => theme.spacer.md} var(--whitespace);
  p {
    &:last-of-type {
      margin-bottom: ${({ theme }) => theme.spacer.sm};
    }
  }
`

const Cite = styled.cite`
  display: block;
  ${({ theme }) => theme.typography.bodySmall}
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacer.xs};
  text-align: right;
  &:before {
    content: '— ';
  }
  &:empty {
    display: none;
  }
`

const Blockquote = ({ children, citation }) => (
  <Quote>
    <div>{children}</div>
    <Cite>{citation}</Cite>
  </Quote>
)

Blockquote.propTypes = {
  children: node.isRequired,
  citation: node
}

export default Blockquote
