import { arrayOf, node, shape, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { Headings } from '../StyledComponents'
import HeadshotComponent from '../Headshot/Headshot'
import React from 'react'

const HEADSHOT = 50
const SPHERES = 1

const Sphere = css`
  background-image: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.pink900},
    ${({ theme }) => theme.colors.purple900} 90%
  );
  opacity: 0.6;
  border-radius: 100%;
  content: '';
  color: white;
  display: block;
  overflow: hidden;
  position: absolute;
  z-index: ${SPHERES};
}
`

const Container = styled.div`
  --content-offset: calc(var(--whitespace) * -1);
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.pink500},
    ${({ theme }) => theme.colors.purple500}
  );
  margin: 0 var(--content-offset);
  min-height: 100%;
  padding-top: ${({ theme }) => theme.spacer.md};
  &:before {
    ${Sphere}
  }
  &:before {
    height: 15vw;
    right: -15px;
    top: calc(${({ theme }) => theme.navHeight} - 15px);
    width: 15vw;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    --content-offset: calc(var(--whitespace) * -1.75);
    &:before {
      height: 15vw;
      right: -50px;
      top: 120px;
      width: 15vw;
    }
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    --content-offset: calc(var(--whitespace) * -1);
  }
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacer.sm};
  margin-left: var(--whitespace);
  margin-right: var(--whitespace);
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content 1fr;
  grid-template-areas:
    'title'
    'headshots'
    'abstract';
  @media ${({ theme }) => theme.devices.mobileMediumMin} {
    grid-template-rows: max-content max-content 1fr;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-column-gap: ${({ theme }) => theme.spacer.lg};
    grid-template-columns: 200px 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
      'headshots title'
      'headshots abstract';
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-template-columns: 250px 1fr;
  }
`
const Headshots = styled.aside`
  display: flex;
  grid-area: headshots;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: ${({ theme }) => theme.spacer.md};
  &:before,
  &:after {
    ${Sphere}
  }
  &:after {
    bottom: 20px;
    height: 10vw;
    right: 10px;
    width: 10vw;
  }
  &:before {
    bottom: 20px;
    height: 20vw;
    right: 30px;
    width: 20vw;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    display: grid;
    grid-gap: ${({ theme }) => theme.spacer.md};
    grid-auto-rows: max-content;
    justify-content: unset;
    &:after {
      height: 10vw;
      left: 30px;
      top: 33%;
      width: 10vw;
    }
    &:before {
      height: 20vw;
      left: 50px;
      top: 36%;
      width: 20vw;
    }
  }
`

const SpeakerImage = styled.div`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacer.xs};
  min-width: 40%;
  max-width: 50%;
  position: relative;
  z-index: ${HEADSHOT};
  @media ${({ theme }) => theme.devices.tabletMin} {
    flex: unset;
    max-width: unset;
  }
`

const HeadshotFrame = styled.div`
  background-image: linear-gradient(
      ${({ theme }) => theme.colors.pink600} 1px,
      transparent 2px
    ),
    linear-gradient(
      127deg,
      ${({ theme }) => theme.colors.purple500},
      ${({ theme }) => theme.colors.pink500}
    );
  background-size: 1px 10px, auto;
  margin: ${({ theme }) => theme.spacer.sm};
  overflow: hidden;
  width: auto;
  @media ${({ theme }) => theme.devices.laptopMin} {
    margin: ${({ theme }) => theme.spacer.md};
  }
`

const Headshot = styled(HeadshotComponent)`
  --offset: ${({ theme }) => theme.spacer.xxs};
  filter: drop-shadow(
    var(--offset) var(--offset) 0 ${({ theme }) => theme.colors.yellow500}
  );
  @media ${({ theme }) => theme.devices.laptopMin} {
    --offset: ${({ theme }) => theme.spacer.sm};
  }
`

const Accents = styled.svg`
  display: none;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-selection: none;
  width: 100%;
  z-index: ${HEADSHOT + 1};
  polygon {
    &:nth-child(1) {
      fill: ${({ theme }) => theme.colors.blue500};
      transform: rotate(45deg);
      transform-origin: 30% 28%;
    }
    &:nth-child(2) {
      fill: ${({ theme }) => theme.colors.yellow500};
      transform: rotate(150deg) scale(0.7);
      transform-origin: 51% 32%;
    }
    &:nth-child(3) {
      fill: ${({ theme }) => theme.colors.blue500};
      transform: rotate(180deg) scale(0.6);
      transform-origin: 62% 29%;
    }
    &:nth-child(4) {
      fill: ${({ theme }) => theme.colors.white};
      transform: rotate(227deg) scale(0.6);
      transform-origin: 59% 20%;
    }
    &:nth-child(5) {
      fill: ${({ theme }) => theme.colors.yellow500};
      transform: rotate(240deg);
      transform-origin: 59% 26%;
    }
    &:nth-child(6) {
      fill: ${({ theme }) => theme.colors.blue500};
      transform: rotate(313deg);
      transform-origin: 73% 49%;
    }
    &:nth-child(7) {
      fill: ${({ theme }) => theme.colors.white};
      transform: rotate(330deg) scale(0.6);
      transform-origin: 32% 66%;
    }
    &:nth-child(8) {
      fill: ${({ theme }) => theme.colors.yellow500};
      transform: rotate(360deg) scale(0.8);
      transform-origin: 2% 53%;
    }
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    display: block;
  }
`

const Name = styled.div`
  ${({ theme }) => theme.typography.secondary};
  ${({ theme }) => theme.typography.heading.md};
  background: ${({ theme }) => theme.colors.yellow500};
  clip-path: polygon(5% 5%, 100% 0%, 98% 95%, 0% 100%);
  color: ${({ theme }) => theme.colors.pink500};
  padding: ${({ theme }) => theme.spacer.xxs};
  margin-top: -1em;
  text-align: center;
  transform: rotate(-2deg);
  width: 100%;
  @media ${({ theme }) => theme.devices.mobileMediumMin} {
    ${({ theme }) => theme.typography.heading.lg};
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    ${({ theme }) => theme.typography.heading.md};
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    top: 80%;
  }
`

const Title = styled(Headings.H4)`
  ${({ theme }) => theme.typography.heading.xxl};
  color: ${({ theme }) => theme.colors.white};
  grid-area: title;
  letter-spacing: 0.02em;
  position: relative;
  text-align: center;
  z-index: ${SPHERES + 1};
  @media ${({ theme }) => theme.devices.tabletMin} {
    ${({ theme }) => theme.typography.heading.xl};
    text-align: left;
  }
`

const Abstract = styled.div`
  color: ${({ theme }) => theme.colors.white};
  grid-area: abstract;
  position: relative;
  z-index: ${SPHERES + 1};
`

const TalkDetails = ({ children, description, speakers, title }) => (
  <Container>
    <Grid>
      <Headshots>
        {speakers.map(({ name }) => (
          <SpeakerImage key={name}>
            <HeadshotFrame>
              <Headshot name={`${name}-removebg`}/>
            </HeadshotFrame>
            <Accents>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
              <polygon points="0,65 0,80 20,70"/>
            </Accents>
            <Name>{name}</Name>
          </SpeakerImage>
        ))}
      </Headshots>
      <Title>{title}</Title>
      <Abstract>{children}</Abstract>
    </Grid>
  </Container>
)

TalkDetails.propTypes = {
  children: node.isRequired,
  description: string.isRequired,
  speakers: arrayOf(
    shape({
      name: string.isRequired
    })
  ),
  title: string.isRequired
}

export default TalkDetails
