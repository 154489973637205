import { func, string } from 'prop-types'
import React, { createContext } from 'react'
import { slugify } from '../utils/string'

const IconsContext = createContext([])
const IconsProvider = IconsContext.Provider

function findIcon (iconNode, name) {
  const filename = slugify(name).toLowerCase()
  return iconNode.base.includes(filename)
}

const IconRequireSubPath = 'icons'

const IconsConsumer = ({ children, name }) => (
  <IconsContext.Consumer>
    {(iconNodes = []) => {
      const iconImage = iconNodes.find((iconNode) => findIcon(iconNode, name))
      return children({ iconImage, subPath: IconRequireSubPath })
    }}
  </IconsContext.Consumer>
)

IconsConsumer.propTypes = {
  children: func.isRequired,
  name: string
}

export { IconsConsumer, IconsProvider }
