import { array, node, string } from 'prop-types'
import Agenda from './Agenda'
import BubbleText from '../BubbleText/BubbleText'
import Clock from './Clock'
import dateformat from 'dateformat'
import { Headings } from '../StyledComponents'
import React from 'react'
import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacer.sm};
  grid-template-columns: ${({ theme }) => theme.spacer.sm} 1fr ${({ theme }) =>
  theme.spacer.sm};
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-gap: ${({ theme }) => theme.spacer.sm};
    grid-template-columns: max-content repeat(12, 1fr);
  }
`

const Header = styled.header`
  grid-column: 1 / -1;
  text-align: center;
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-column-start: 2;
  }
`

const Title = styled(BubbleText)`
  ${({ theme }) => theme.typography.heading.xxl};
`

const ScheduleDate = styled(Headings.H3)`
  color: ${({ theme }) => theme.colors.yellow50};
  margin-top: ${({ theme }) => theme.spacer.md};
`

const Preamble = styled.div`
  color: white;
  grid-column: 1 / -1;
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-column-start: 2;
  }
`

// eslint-disable-next-line react/prop-types
function renderTick (item, timingProps, key) {
  return <Agenda key={key} {...timingProps} {...item}/>
}

const ScheduleDay = ({ agenda, children, date, title }) => {
  const clock = new Date(date)
  return (
    <Grid>
      <Header>
        <Title>{title}</Title>
        <ScheduleDate>{dateformat(clock, `UTC:dddd, mmmm dS`)}</ScheduleDate>
      </Header>
      <Preamble>{children}</Preamble>
      <Clock agenda={agenda} renderTick={renderTick} startAt={date}/>
    </Grid>
  )
}

ScheduleDay.propTypes = {
  agenda: array.isRequired,
  children: node,
  date: string.isRequired,
  title: string.isRequired
}

export default ScheduleDay
