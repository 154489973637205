/* eslint-disable no-confusing-arrow, no-ternary */
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Button from '../Button/Button'
import { Link } from '../StyledComponents'
import LinkButton from '../LinkButton/LinkButton'
import LogoSvg from '../../assets/images/logo.svg'
import { string } from 'prop-types'

const HeaderGrid = styled.header`
  align-items: stretch;
  background: ${({ theme }) => theme.colors.blueA200};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  grid-template-rows: ${({ theme }) => theme.navHeight};
  grid-template-columns:
    [brand] 100px
    [cta] 1fr
    [nav] minmax(100px, max-content);
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.heroContent + 1};
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-gap: 10px;
    grid-template-columns:
      [brand] 133px
      [nav] 1fr
      [cta] minmax(133px, max-content);
  }
`

const Brand = styled.aside`
  align-self: center;
  grid-column: brand;
`

const BrandLink = styled(Link)`
  align-content: center;
  display: flex;
  height: ${({ theme }) => theme.navHeight};
  padding: ${({ theme }) => theme.spacer.xs} 0;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
`

const ExpandedMobileNav = css`
  box-shadow: 0 0 ${({ theme }) => theme.spacer.sm} rgba(40, 83, 124, 0.5);
  display: block;
  padding: ${({ theme }) => theme.spacer.md} 0;
  transform: translateY(0%);
`

const Nav = styled.nav`
  background: ${({ theme }) => theme.colors.blueA200};
  display: block;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  transition: transform 0.3s;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.heroContent + 2};
  ${({ expanded }) => expanded && ExpandedMobileNav}
  @media ${({ theme }) => theme.devices.laptopMin} {
    background: none;
    box-shadow: none;
    position: static;
    transform: none;
  }
`

const NavItems = styled.ul`
  align-items: center;
  display: grid;
  grid-auto-column: max-content;
  grid-auto-flow: row;
  grid-gap: ${({ theme }) => theme.spacer.xs};
  list-style-type: none;
  @media ${({ theme }) => theme.devices.laptopMin} {
    align-items: stretch;
    grid-auto-flow: column;
    grid-column: nav;
    height: 100%;
  }
`

const NavItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: center;
`

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.colors.pink500};
  font-family: Futura;
  font-size: 19px;
  padding: ${({ theme }) => theme.spacer.xs};
  position: relative;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  &::before {
    background: ${({ theme }) => theme.colors.green700};
    bottom: 20%;
    content: '';
    height: 30%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    transform-origin: 0% 50%;
    transform: scale3d(0, 1, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 0.5s;
    width: 100%;
  }
  &:hover {
    &::before {
      transform: scale3d(1, 1, 1);
    }
  }
`

const HeaderAction = styled.aside`
  align-items: stretch;
  display: flex;
`

const HeaderCTA = styled(HeaderAction)`
  justify-self: center;
  grid-column: cta;
  @media ${({ theme }) => theme.devices.mobileSmallMax} {
    font-size: 0.99em;
  }
`

const MobileMenuAction = styled(HeaderAction)`
  justify-self: flex-end;
  grid-column: nav;
  @media ${({ theme }) => theme.devices.laptopMin} {
    display: none;
  }
`

const HeaderButton = css`
  &,
  &:hover {
    clip-path: none;
  }
`

const LinkBtn = styled(LinkButton)`
  align-items: center;
  display: flex;
  ${HeaderButton}
`

const StyledButton = styled(Button)`
  padding-left: ${({ theme }) => theme.spacer.md};
  padding-right: ${({ theme }) => theme.spacer.md};
  ${HeaderButton}
`

const CloseMenuButton = styled.button`
  color: rgba(0, 0, 0, 0);
  height: 40px;
  overflow: hidden;
  position: absolute;
  top: ${({ theme }) => theme.spacer.xs};
  right: ${({ theme }) => theme.spacer.xs};
  &::after,
  &::before {
    background: ${({ theme }) => theme.colors.blue600};
    content: '';
    height: 40%;
    left: 50%;
    position: absolute;
    top: 33%;
    width: 2px;
  }
  &::after {
    transform: rotate(-45deg);
  }
  &::before {
    transform: rotate(45deg);
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    display: none;
  }
`

export default class Header extends Component {
  static propTypes = {
    ticketsUrl: string.isRequired
  }
  state = {
    expanded: false
  }
  handleClick = () => {
    this.setState((state) => ({
      expanded: !state.expanded
    }))
  }
  render () {
    return (
      <HeaderGrid>
        <Brand>
          <BrandLink aria-label="Go back home" href="/">
            <Image alt="" height="150" src={LogoSvg} width="215"/>
          </BrandLink>
        </Brand>
        <MobileMenuAction>
          <StyledButton onClick={this.handleClick} secondary>
            Menu
          </StyledButton>
        </MobileMenuAction>
        <Nav expanded={this.state.expanded}>
          <NavItems>
            <NavItem>
              <NavLink href="/speakers/">Speakers</NavLink>
              {/* <NavLink href="/call-for-proposals/">Call for Proposals</NavLink> */}
            </NavItem>
            <NavItem>
              <NavLink href="/schedule/">Schedule</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/sponsors/">Sponsors</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/scholarships/">Scholarships</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about/">About</NavLink>
            </NavItem>
          </NavItems>
          <CloseMenuButton onClick={this.handleClick}>
            Close Menu
          </CloseMenuButton>
        </Nav>
        <HeaderCTA>
          <LinkBtn href={this.props.ticketsUrl}>Buy Tickets</LinkBtn>
        </HeaderCTA>
      </HeaderGrid>
    )
  }
}
