import { graphql, StaticQuery } from 'gatsby'
import { Headings, Link, Paragraph } from '../components/StyledComponents'
import { AvatarsProvider } from '../contexts/Avatars'
import blob1 from '../assets/images/abstract/blob1.svg'
import blob2 from '../assets/images/abstract/blob2.svg'
import DefaultLayout from '../layouts/DefaultLayout'
import { getNodes } from '../utils/nodeHelpers'
import GridContainer from '../components/GridContainer/GridContainer'
import GridRow from '../components/GridRow/GridRow'
import { LogosProvider } from '../contexts/Logos'
import polys from '../assets/images/abstract/polys.svg'
import React from 'react'
import Schedule from '../components/Schedule/Schedule'
import ScheduleNav from '../components/Schedule/ScheduleNav'
import styled from 'styled-components'

const StyledGridContainer = styled(GridContainer)`
  margin-bottom: calc(${({ theme }) => theme.spacer.xxl} * -1);
  padding-top: ${({ theme }) => theme.spacer.sm};
  padding-bottom: ${({ theme }) => theme.spacer.xxl};
  position: relative;
  z-index: 3;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blueA700};
  padding: 0 5px;
`

const AltStyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.greenA700};
  padding: 0 5px;
`

const Container = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.pink900},
    ${({ theme }) => theme.colors.purple500}
  );
  position: relative;
  &::before {
    background-blend-mode: overlay, normal, normal;
    background-image: url(${polys}), url(${blob1}), url(${blob2});
    background-position: top left, top right, top left;
    background-repeat: repeat-y;
    background-size: 100%, 50%, 33%;
    bottom: 0;
    content: '';
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`

const TeamPage = () => (
  <StaticQuery
    query={graphql`
      query ScheduleQuery {
        dayZero: allDayZeroJson {
          edges {
            node {
              components {
                description
                href
                image {
                  name
                }
                location
                sponsors {
                  logo
                  name
                }
                title
              }
              description
              duration
              endTime
              location
              repeatEvent
              startTime
              title
            }
          }
        }
        dayFour: allDayFourJson {
          edges {
            node {
              components {
                description
                href
                image {
                  name
                }
                location
                sponsors {
                  logo
                  name
                }
                title
              }
              description
              duration
              endTime
              location
              repeatEvent
              startTime
              title
            }
          }
        }
        dayOne: allDayOneJson {
          edges {
            node {
              components {
                description
                href
                image {
                  name
                }
                location
                speakers {
                  name
                }
                sponsors {
                  logo
                  name
                }
                title
              }
              description
              duration
              endTime
              location
              repeatEvent
              startTime
              title
            }
          }
        }
        dayThree: allDayThreeJson {
          edges {
            node {
              components {
                description
                href
                image {
                  name
                }
                location
                speakers {
                  name
                }
                sponsors {
                  logo
                  name
                }
                title
              }
              description
              duration
              endTime
              location
              repeatEvent
              startTime
              title
            }
          }
        }
        dayTwo: allDayTwoJson {
          edges {
            node {
              components {
                description
                href
                image {
                  name
                }
                location
                sponsors {
                  logo
                  name
                }
                title
              }
              description
              duration
              endTime
              location
              repeatEvent
              startTime
              title
            }
          }
        }
        speakerImages: allFile(
          filter: {
            internal: { mediaType: { regex: "/image/" } }
            relativePath: { regex: "/speakers/" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fixed(height: 45) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        sponsorLogos: allFile(
          filter: {
            internal: { mediaType: { regex: "/image/" } }
            relativePath: { regex: "/sponsors/" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fixed(height: 45) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={({
      dayFour: { edges: dayFour },
      dayOne: { edges: dayOne },
      dayThree: { edges: dayThree },
      dayTwo: { edges: dayTwo },
      dayZero: { edges: dayZero },
      speakerImages: { edges: speakerImages },
      sponsorLogos: { edges: sponsorLogos }
    }) => (
      <AvatarsProvider value={getNodes(speakerImages)}>
        <LogosProvider value={getNodes(sponsorLogos)}>
          <DefaultLayout
            description="Schedule for JSConf US 2019"
            title="Schedule"
          >
            <Container>
              <ScheduleNav>
                <a href="#day-of-arrival">
                  <span>Day of Arrival</span>
                </a>
                <a href="#first-day-of-talks">
                  <span>First Day of Talks</span>
                </a>
                <a href="#choose-your-own-adventure">
                  <span>Day of Activities</span>
                </a>
                <a href="#last-day-of-talks">
                  <span>Last Day of Talks</span>
                </a>
                <a href="#day-of-departure">
                  <span>Day of Departure</span>
                </a>
              </ScheduleNav>
              <StyledGridContainer>
                <GridRow
                  laptop="medium"
                  laptopLarge="narrow"
                  medium
                  tablet="wide"
                >
                  <Schedule
                    agenda={getNodes(dayZero).slice(1)}
                    date="2019-08-11T00:00Z"
                    title="Day of Arrival"
                  />
                  <Schedule
                    agenda={getNodes(dayOne).slice(1)}
                    date="2019-08-12T00:00Z"
                    title="First Day of Talks"
                  >
                    <Headings.H3>What is the SitePen Track?</Headings.H3>
                    <Paragraph>
                      The SitePen Track includes a curated set of speakers that
                      have submitted their proposals and have successfully made
                      it through our selection process.
                    </Paragraph>
                    <Headings.H3>What is the HPE Track?</Headings.H3>
                    <Paragraph>
                      The alternative track for JSConf US is driven by people
                      like you! We have a very unique format in that we let
                      anyone register to speak in a first-come, first-speaking
                      format. These talks are generally from the full range of
                      web development and the world of JavaScript and are some
                      of the most exciting talks at JSConf.
                    </Paragraph>
                    <Paragraph>
                      <strong>
                        Registration for
                        <AltStyledLink href="https://ti.to/jsconf-us/jsconf-us-2019">
                          ticketed attendees
                        </AltStyledLink>
                        opens on July 12th!
                      </strong>
                    </Paragraph>
                  </Schedule>
                  <Schedule
                    agenda={getNodes(dayTwo).slice(1)}
                    date="2019-08-13T00:00Z"
                    title="Choose Your Own Adventure"
                  >
                    <Paragraph>
                      Our "Choose Your Own Adventure" day is what sets JSConf US
                      apart from other conferences. It provides you with an
                      extraordinary opportunity to socialize with the brightest
                      minds in our community while providing a brief respite
                      from the mind-bending material delivered during our talks.
                      Activities are provided on a first come, first serve basis
                      and registration details will be emailed to all registered
                      attendees.
                    </Paragraph>
                    <Paragraph>
                      More information is available on our
                      <StyledLink href="/about/activities/">
                        Activities
                      </StyledLink>{' '}
                      pages!
                    </Paragraph>
                  </Schedule>
                  <Schedule
                    agenda={getNodes(dayThree).slice(1)}
                    date="2019-08-14T00:00Z"
                    title="Last Day of Talks"
                  />
                  <Schedule
                    agenda={getNodes(dayFour).slice(1)}
                    date="2019-08-15T00:00Z"
                    title="Day of Departure"
                  />
                </GridRow>
              </StyledGridContainer>
            </Container>
          </DefaultLayout>
        </LogosProvider>
      </AvatarsProvider>
    )}
  />
)

export default TeamPage
