import React, { Children, cloneElement, Component, createRef } from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import { UnstyledList } from '../StyledComponents'

const Nav = styled.nav`
  background: linear-gradient(#1115 60%, transparent 100%);
  background-blend-mode: multiply;
  counter-reset: day;
  position: sticky;
  top: 0;
  z-index: 20;
  pointer-events: none;
  @media ${({ theme }) => theme.devices.mobileMediumMin} {
    padding-left: 5px;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    margin-right: 0;
    padding-left: 0;
  }
`

const NavList = styled(UnstyledList)`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: ${({ theme }) => theme.spacer.xl};
  padding-top: ${({ theme }) => theme.spacer.md};
`

const Item = styled.li`
  counter-increment: day;
  margin-bottom: ${({ theme }) => theme.spacer.sm};
  a {
    ${({ theme }) => theme.typography.heading.sm};
    background: white;
    border: solid 5px ${({ theme }) => theme.colors.blue900};
    color: ${({ theme }) => theme.colors.blue900};
    display: block;
    font-family: Solido;
    letter-spacing: 0.5px;
    text-decoration: none;
    padding: ${({ theme }) => theme.spacer.xxs}
      ${({ theme }) => theme.spacer.xs};
    pointer-events: initial;
    position: relative;
    transition: all 0.2s ease-in-out;
    &::before,
    &::after {
      content: '';
      position: absolute;
      transform-origin: top left;
      transition: all 0.2s ease-in-out;
    }
    &::before {
      background: ${({ theme }) => theme.colors.blue700};
      position: absolute;
      pointer-events: none;
      top: calc(100% + 5px);
      height: 10px;
      left: -5px;
      width: calc(100% + 10px);
      transform: skewX(45deg);
    }
    &::after {
      background: ${({ theme }) => theme.colors.blue300};
      width: 10px;
      height: calc(100% + 10px);
      left: calc(100% + 5px);
      top: -5px;
      transform: skewY(45deg);
    }
    &[data-active='true'] {
      border-color: ${({ theme }) => theme.colors.green900};
      &::before {
        background: ${({ theme }) => theme.colors.green700};
      }
      &::after {
        background: ${({ theme }) => theme.colors.green300};
      }
    }
    &:hover {
      transform: translate(-5px, -5px);
      &::before {
        height: 15px;
      }
      &::after {
        width: 15px;
      }
    }
    &:active {
      transform: translate(5px, 5px);
      &::before {
        height: 5px;
      }
      &::after {
        width: 5px;
      }
    }
    span {
      display: inline-block;
      word-spacing: -999px;
      letter-spacing: -999px;
      &::after {
        content: '8/1' counter(day);
        display: inline-block;
        word-spacing: initial;
        letter-spacing: initial;
      }
    }
    @media ${({ theme }) => theme.devices.mobileMediumMin} {
      span {
        word-spacing: initial;
        letter-spacing: initial;
        &::after {
          display: none;
        }
      }
    }
    @media ${({ theme }) => theme.devices.tabletMin} {
      font-size: 2.5vw;
    }
    @media ${({ theme }) => theme.devices.laptopLargeMin} {
      font-size: ${({ theme }) => theme.typography.heading.lg};
    }
  }
`

export default class ScheduleNav extends Component {
  static propTypes = {
    children: node
  }
  constructor (props) {
    super(props)
    this.el = createRef()
  }
  state = {
    activeId: null
  }
  componentDidMount () {
    const scheduleEl = this.el.current.nextSibling
    const els = Children.map(this.props.children, (child) =>
      scheduleEl.querySelector(`section[id="${child.props.href.slice(1)}"]`)
    )
    this.observer = new IntersectionObserver(this.handleIntersection, {
      rootMargin: '-33%'
    })
    els.forEach((el) => this.observer.observe(el))
  }
  componentWillUnmount () {
    const scheduleEl = this.el.current.nextSibling
    const els = Children.map(this.props.children, (child) =>
      scheduleEl.querySelector(`section[id="${child.props.href.slice(1)}"]`)
    )
    els.forEach((el) => this.observer.unobserve(el))
  }
  handleClick = (event) => {
    event.preventDefault()
    const url = new URL(event.currentTarget.href)
    const el = document.querySelector(url.hash)
    if (!el) {
      return
    }
    window.scrollTo(0, el.offsetTop + 100)
  }
  handleIntersection = (changes, observer) => {
    const visibleChange = changes.find((change) => change.intersectionRatio > 0)
    if (!visibleChange) {
      return
    }
    const activeId = `#${visibleChange.target.id}`
    const url = new URL(window.location.href)
    url.hash = activeId
    window.history.replaceState({}, document.title, url)
    this.setState((state) => ({
      ...state,
      activeId
    }))
  }
  observer = null
  render () {
    const links = Children.map(this.props.children, (child, key) => (
      <Item key={key}>
        {cloneElement(child, {
          'data-active': child.props.href === this.state.activeId,
          onClick: this.handleClick
        })}
      </Item>
    ))
    return (
      <Nav ref={this.el}>
        <NavList>{links}</NavList>
      </Nav>
    )
  }
}
