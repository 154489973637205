import Activities from '../../components/Activities/Activities'
import DefaultLayout from '../../layouts/DefaultLayout'
import React from 'react'

const Acitivies = (props) => (
  <DefaultLayout
    description={`Try a new experience during our "Choose Your Own Adventure" day, a day that sets JSConf US apart from other conferences.`}
    title="Activities"
  >
    <Activities/>
  </DefaultLayout>
)

export default Acitivies
