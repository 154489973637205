import { IconsConsumer } from '../../contexts/Icons'
import Image from '../Image/Image'
import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const Img = styled(Image)`
  img {
    max-height: 50px;
  }
`

const IconImage = ({ name, ...props }) => (
  <IconsConsumer key={name} name={name}>
    {({ iconImage, subPath }) => (
      <Img src={iconImage} subPath={subPath} {...props}/>
    )}
  </IconsConsumer>
)

IconImage.propTypes = {
  logo: string,
  name: string.isRequired
}

export default IconImage
