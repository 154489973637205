import { array, bool } from 'prop-types'
import { LinkCss } from '../StyledComponents/Link'
import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  ${({ theme }) => theme.typography.bodySmall}
`

const TableOfContents = ({ hidden, items }) => {
  if (hidden || !items || items.length === 0) {
    return null
  }
  return (
    <List>
      {items.map((item) => (
        <li key={item.url}>
          <a css={LinkCss} href={item.url}>
            {item.title}
          </a>
        </li>
      ))}
    </List>
  )
}

TableOfContents.propTypes = {
  hidden: bool.isRequired,
  items: array
}

export default TableOfContents
