import { object, string } from 'prop-types'
import { absoluteUrl } from '../../utils/urlFilters'
import Basics from './Basics'
import GoogleSearch from './GoogleSearch'
import OpenGraph from './OpenGraph'
import React from 'react'
import TwitterCard from './TwitterCard'

const Seo = ({
  currentPath,
  description,
  openGraph,
  siteName,
  title,
  twitter
}) => (
  <>
    <Basics description={description} name={siteName}/>
    <OpenGraph
      description={description}
      name={siteName}
      title={title}
      url={absoluteUrl(currentPath)}
      {...openGraph}
    />
    <TwitterCard
      description={description}
      name={siteName}
      title={title}
      url={absoluteUrl(currentPath)}
      {...twitter}
    />
    <GoogleSearch/>
  </>
)

Seo.defaultProps = {
  openGraph: {},
  twitter: {}
}

Seo.propTypes = {
  currentPath: string,
  description: string,
  openGraph: object,
  siteName: string,
  title: string,
  twitter: object
}

export default Seo
