import Activity from '../Activity/Activity'
import { Paragraph } from '../StyledComponents'
import React from 'react'

const NodeArt = (props) => (
  <Activity title="NodeArt">
    <Paragraph>
      We're excited to introduce, for the first time ever, NodeArt! Learn how to
      use JavaScript to create something beautiful in either your browser, a
      hardware device <em>you</em> design, or both! We'll provide you some of
      the basics of generating visualizations with code as well as how to
      interact with various inputs such as light sensors, the WebAudioAPI, and
      the MidiAPI. Let your creativity take it from there and create some new
      and unique!
    </Paragraph>
  </Activity>
)

export default NodeArt
