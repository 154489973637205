import { graphql, StaticQuery } from 'gatsby'
import { Headings, UnstyledList } from '../StyledComponents'
import GridContainer from '../GridContainer/GridContainer'
import GridRow from '../GridRow/GridRow'
import NewsBackground from './NewsBackground'
import NewsItem from './NewsItem'
import React from 'react'
import styled from 'styled-components'

const Header = styled(Headings.H3)`
  ${({ theme }) => theme.typography.heading.xl};
  background: ${({ theme }) => theme.colors.pink500};
  color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacer.sm} ${({ theme }) => theme.spacer.md};
  text-align: center;
  text-shadow: -3px -3px ${({ theme }) => theme.colors.pink200};
  width: max-content;
`

const NewsItems = styled(UnstyledList)`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.lg};
  grid-template-columns: repeat(1, 1fr);
  margin-top: ${({ theme }) => theme.spacer.md};
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${({ theme }) => theme.devices.laptopLargeMin} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const StyledRow = styled(GridRow)`
  padding-bottom: ${({ theme }) => theme.spacer.xxl};
  padding-top: ${({ theme }) => theme.spacer.xxl};
  position: relative;
  margin-top: calc(${({ theme }) => theme.spacer.xl} * -1);
`

const NewsList = () => (
  <StaticQuery
    query={graphql`
      query NewsListQuery {
        allMdx(
          limit: 6
          filter: { fields: { sourceInstanceName: { eq: "posts" } } }
          sort: { fields: [fields___date], order: DESC }
        ) {
          edges {
            node {
              excerpt
              fields {
                date(formatString: "MMM D, YYYY")
                slug
              }
              frontmatter {
                heading
                title
              }
              id
            }
          }
        }
      }
    `}
    render={({ allMdx: { edges } }) => (
      <StyledRow as="section" full>
        <NewsBackground/>
        <GridContainer>
          <GridRow lg="medium" sm="wide">
            <Header as="h3">News</Header>
            <NewsItems as="ul" count={edges.length}>
              {edges.map(({ node }) => (
                <NewsItem
                  date={node.fields.date}
                  heading={node.frontmatter.heading}
                  key={node.id}
                  slug={node.fields.slug}
                  title={node.frontmatter.title}
                >
                  {node.excerpt}
                </NewsItem>
              ))}
            </NewsItems>
          </GridRow>
        </GridContainer>
      </StyledRow>
    )}
  />
)

export default NewsList
