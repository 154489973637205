import React from 'react'

function slugify (str) {
  if (!str || !str.replace) {
    return undefined
  }
  return str
    .trim()
    .replace(/ /g, '-')
    .replace(/,/g, '-')
    .replace(/\./g, '-')
    .replace(/á/g, 'a')
    .replace(/ó/g, 'o')
    .replace(/([^a-zA-Z0-9._-]+)/, '')
    .toLowerCase()
}

function toSentenceJSX (items) {
  items = React.Children.toArray(items)
  if (items.length === 2) {
    items.splice(1, 0, ' and ')
    return items
  }
  const lastItem = items.pop()
  const set = items.reduce(
    (sentence, item) => sentence.concat([item, ', ']),
    []
  )
  return set.concat([' and ', lastItem])
}

function toSentence (items) {
  if (items.length < 2) {
    return items[0]
  }
  if (React.isValidElement(items[0])) {
    return toSentenceJSX(items)
  }
  if (items.length === 2) {
    return items.join(' and ')
  }
  items = items.slice()
  const lastItem = items.pop()
  return `${items.join(', ')} and ${lastItem}`
}

function pluralize (set, singular, plural) {
  if (set.length === 1) {
    return singular
  }
  return plural
}

function titleize (str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ')
}

export { pluralize, slugify, titleize, toSentence, toSentenceJSX }
