import { func, string } from 'prop-types'
import { Component } from 'react'

/**
 * Adds and removes events for you
 * Usage:
 * <WindowEvent event='keydown' handler={this.handleKeyDown} />
 */
export default class WindowEvent extends Component {
  static propTypes = {
    event: string.isRequired,
    handler: func.isRequired
  }

  componentDidMount () {
    this.addEvent()
  }

  componentDidUpdate () {
    this.removeEvent()
    this.addEvent()
  }

  componentWillUnmount () {
    this.removeEvent()
  }

  addEvent () {
    const { event, handler } = this.props
    window.addEventListener(event, handler)
  }

  removeEvent () {
    const { event, handler } = this.props
    window.removeEventListener(event, handler)
  }

  render () {
    return null
  }
}
