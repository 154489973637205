import { array, object, shape, string } from 'prop-types'
import { AvatarsProvider } from '../contexts/Avatars'
import { getNodes } from '../utils/nodeHelpers'
import GlobalStyle from '../components/GlobalStyle/GlobalStyle'
import { graphql } from 'gatsby'
import React from 'react'
import TeamCard from '../components/TeamCard/TeamCard'
import themeConfig from '../theme/theme'
import { ThemeProvider } from 'styled-components'

export default function TeamCardTemplate ({
  data: {
    staffImages: { edges: staffImages },
    ...teams
  },
  theme,
  pageContext: { teamName },
  ...props
}) {
  const memberNodes = teams[teamName]
  if (!memberNodes) {
    return <div/>
  }
  const members = getNodes(memberNodes.edges)
  return (
    <AvatarsProvider value={getNodes(staffImages)}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle/>
          <main>
            <TeamCard members={members} name={teamName}/>
          </main>
        </>
      </ThemeProvider>
    </AvatarsProvider>
  )
}

TeamCardTemplate.defaultProps = {
  theme: themeConfig
}

TeamCardTemplate.propTypes = {
  data: shape({
    staffImages: shape({
      edges: array
    })
  }),
  pageContext: shape({
    teamName: string.isRequired
  }).isRequired,
  theme: object
}

export const pageQuery = graphql`
  query TeamCardQuery {
    nodeart: allNodeartJson {
      edges {
        node {
          id
          href
          name
          company
        }
      }
    }
    nodeboats: allNodeboatsJson {
      edges {
        node {
          id
          href
          name
          company
        }
      }
    }
    nodebots: allNodebotsJson {
      edges {
        node {
          id
          href
          name
          company
        }
      }
    }
    mcs: allMcsJson {
      edges {
        node {
          id
          href
          name
          company
        }
      }
    }
    noderockets: allNoderocketsJson {
      edges {
        node {
          id
          href
          name
          company
        }
      }
    }
    staffImages: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/team/[^/]+/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
