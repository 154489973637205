import { graphql, StaticQuery } from 'gatsby'
import { getNodes } from '../../utils/nodeHelpers'
import Img from 'gatsby-image'
import React from 'react'
import { string } from 'prop-types'

const ActivityImage = ({ src }) => (
  <StaticQuery
    query={graphql`
      query ActivityImageQuery {
        activityImages: allFile(
          filter: {
            internal: { mediaType: { regex: "/image/" } }
            relativePath: { regex: "/activities/" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fluid(maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={({ activityImages: { edges: activityImages } }) => {
      const images = getNodes(activityImages)
      const image = images.find((img) => img.base === src)
      return <Img fluid={image.childImageSharp.fluid}/>
    }}
  />
)

ActivityImage.propTypes = {
  src: string
}

export default ActivityImage
