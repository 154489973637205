import { graphql, StaticQuery } from 'gatsby'
import { AvatarsProvider } from '../contexts/Avatars'
import DefaultLayout from '../layouts/DefaultLayout'
import { getNodes } from '../utils/nodeHelpers'
import React from 'react'
import SpeakerLineup from '../components/SpeakerLineup/SpeakerLineup'

function getSpeakers (speakerNodes) {
  const speakers = []
  speakerNodes.forEach(({ fields, frontmatter, id }) => {
    speakers.push({
      id,
      ...fields,
      ...frontmatter
    })
  })
  return speakers.sort((a, b) =>
    a.speakers[0].name
      .toLowerCase()
      .localeCompare(b.speakers[0].name.toLowerCase())
  )
}

const SpeakersPage = () => (
  <StaticQuery
    query={graphql`
      query SpeakersQuery {
        speakers: allMdx(
          filter: {
            fields: { slug: { regex: "/speakers/" } }
            frontmatter: { visible: { eq: true } }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                speakers {
                  name
                }
                visible
              }
            }
          }
        }
        speakerImages: allFile(
          filter: {
            internal: { mediaType: { regex: "/image/" } }
            relativePath: { regex: "/speakers/" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fluid(maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={({
      speakers: { edges: speakers },
      speakerImages: { edges: speakerImages }
    }) => {
      const lineup = getSpeakers(getNodes(speakers))
      return (
        <AvatarsProvider value={getNodes(speakerImages)}>
          <DefaultLayout
            description="Speakers for JSConf US 2019"
            title="Speakers"
          >
            <SpeakerLineup lineup={lineup}/>
          </DefaultLayout>
        </AvatarsProvider>
      )
    }}
  />
)

export default SpeakersPage
