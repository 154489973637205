import { array, shape, string } from 'prop-types'
import { slugify, toSentence } from '../utils/string'
import { absoluteUrl } from '../utils/urlFilters'
import { AvatarsProvider } from '../contexts/Avatars'
import { getNodes } from '../utils/nodeHelpers'
import { graphql } from 'gatsby'
import MdxTemplate from './MdxTemplate'
import React from 'react'
import TalkDetails from '../components/TalkDetails/TalkDetails'

const CacheBuster = 9

export default function SpeakerTemplate ({
  data: {
    talk,
    speakerImages: { edges: speakerImages }
  },
  ...props
}) {
  const { code, frontmatter } = talk
  const names = toSentence(frontmatter.speakers.map((speaker) => speaker.name))
  const metaTitle = `${names}: ${frontmatter.title}`
  const twitterCard = {
    image: absoluteUrl(`/speakers/${slugify(names)}-card.png?${CacheBuster}`),
    type: 'summary_large_image'
  }
  const openGraph = {
    image: absoluteUrl(`/speakers/${slugify(names)}-plate.png`)
  }
  return (
    <AvatarsProvider value={getNodes(speakerImages)}>
      <MdxTemplate
        body={code.body}
        description={frontmatter.description}
        openGraph={openGraph}
        title={metaTitle}
        twitter={twitterCard}
        {...props}
        render={(body) => <TalkDetails {...frontmatter}>{body}</TalkDetails>}
      />
    </AvatarsProvider>
  )
}

SpeakerTemplate.propTypes = {
  data: shape({
    talk: shape({
      code: shape({
        body: string.isRequired
      }),
      frontmatter: shape({
        description: string,
        speakers: array,
        title: string,
        track: string
      }),
      tableOfContents: shape({
        items: array
      })
    })
  })
}

export const pageQuery = graphql`
  query($id: String!) {
    talk: mdx(id: { eq: $id }) {
      frontmatter {
        description
        speakers {
          name
        }
        title
        track
        visible
      }
      id
      code {
        body
      }
    }
    speakerImages: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/speakers/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
