import { array } from 'prop-types'
import backgroundSvg from '../../assets/images/abstract/4.svg'
import GridContainer from '../GridContainer/GridContainer'
import GridRow from '../GridRow/GridRow'
import HeadshotComponent from '../Headshot/Headshot'
import { Link } from '../StyledComponents'
import React from 'react'
import styled from 'styled-components'

const Container = styled(GridContainer)`
  background-image: url(${backgroundSvg});
  background-repeat: repeat;
  background-size: 125px 125px;
  margin-bottom: calc(${({ theme }) => theme.spacer.xl} * -1);
  padding-bottom: ${({ theme }) => theme.spacer.xxl};
  padding-top: ${({ theme }) => theme.spacer.md};
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacer.lg};
  @media ${({ theme }) => theme.devices.mobileLargeMin} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const SpeakerLink = styled(Link)`
  --glow-opacity: 0.5;
  --stripe-opacity: 0;
  background-color: var(--bg-color);
  color: ${({ theme }) => theme.colors.white};
  content: 'link';
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 100;
  &:hover {
    --glow-opacity: 0.8;
    --stripe-opacity: 1;
    color: ${({ theme }) => theme.colors.white};
  }
  &:nth-child(1n + 1) {
    --bg-color: ${({ theme }) => theme.colors.green900};
  }
  &:nth-child(2n + 1) {
    --bg-color: ${({ theme }) => theme.colors.purpleA700};
  }
  &:nth-child(3n + 1) {
    --bg-color: ${({ theme }) => theme.colors.blue500};
  }
  &:nth-child(5n + 1) {
    --bg-color: ${({ theme }) => theme.colors.pink500};
  }
`

const Speaker = styled.article`
  align-items: center;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  z-index: 200;
`

const Headshot = styled(HeadshotComponent)`
  filter: contrast(115%) saturate(110%);
  position: relative;
  &::after {
    background-image: radial-gradient(#fff, transparent);
    content: '';
    height: 100%;
    opacity: var(--glow-opacity);
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease-out;
    width: 100%;
    z-index: -1;
  }
  &::before {
    background-blend-mode: soft-light;
    background-color: var(--bg-color);
    background-image: repeating-radial-gradient(
      circle,
      transparent,
      transparent 10px,
      #ccc 10px,
      #ccc 20px
    );
    content: '';
    height: 100%;
    opacity: var(--stripe-opacity);
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease-out;
    width: 100%;
    z-index: -2;
  }
`

const Name = styled.header`
  ${({ theme }) => theme.typography.secondary};
  flex: 1;
  display: grid;
  align-items: center;
  font-family: Novanta;
  ${({ theme }) => theme.typography.heading.sm};
  padding: ${({ theme }) => theme.spacer.sm};
  text-align: center;
`

const SpeakerLineup = ({ lineup }) => (
  <Container>
    <GridRow>
      <Grid>
        {lineup.map(({ id, slug, speakers }) => (
          speakers.map(({ name }) => (
            <SpeakerLink href={slug} key={`${id}-${name}`}>
              <Speaker key={name}>
                <Headshot name={`${name}-removebg`}/>
                <Name>{name}</Name>
              </Speaker>
            </SpeakerLink>
          ))))}
      </Grid>
    </GridRow>
  </Container>
)

SpeakerLineup.propTypes = {
  lineup: array
}

export default SpeakerLineup
