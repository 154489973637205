import { arrayOf, bool, node, number, object, shape, string } from 'prop-types'
import React, { Children, cloneElement, Component, createRef } from 'react'
import styled, { withTheme } from 'styled-components'
import WindowEvent from '../WindowEvent/WindowEvent'

const Svg = styled.svg`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor]};
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  height: calc(100% + ${({ theme }) => theme.spacer.xs});
  position: absolute;
  top: 0;
  user-selection: none;
  width: 100%;
  z-index: -1;
`

const DEBOUNCE_RATE = 250
const PATTERN_SIZE = 200

class HeroBackground extends Component {
  static defaultProps = {
    debug: false
  }
  static propTypes = {
    bgColor: string.isRequired,
    children: node.isRequired,
    className: string,
    color: string,
    debug: bool,
    name: string.isRequired,
    pattern: arrayOf(
      shape({
        rotate: number,
        scale: number,
        x: number.isRequired,
        y: number.isRequired
      })
    ),
    stroke: string,
    theme: shape({
      colors: object
    })
  }
  constructor (props) {
    super(props)
    this.svg = createRef()
  }
  state = {
    height: PATTERN_SIZE,
    width: PATTERN_SIZE
  }
  componentDidMount () {
    this.setDims()
  }
  setDims = () => {
    const { height, width } = this.svg.current.getBoundingClientRect()
    this.setState({ height, width })
  }
  onResize = () => {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(this.setDims, DEBOUNCE_RATE)
  }
  resizeTimer = null
  render () {
    const stamp = cloneElement(Children.only(this.props.children), {
      fill: this.props.theme.colors[this.props.color] || 'none',
      id: this.props.name,
      stroke: this.props.theme.colors[this.props.stroke]
    })
    return (
      <Svg
        bgColor={this.props.bgColor}
        className={this.props.className}
        ref={this.svg}
      >
        <WindowEvent event="resize" handler={this.onResize}/>
        <defs>
          {stamp}
          <pattern
            height={PATTERN_SIZE}
            id={`${this.props.name}-pattern`}
            patternUnits="userSpaceOnUse"
            width={PATTERN_SIZE}
            x="0"
            y="0"
          >
            {this.props.pattern.map((point, idx) => {
              const x = point.x * (1 / point.scale)
              const y = point.y * (1 / point.scale)
              const transform = `scale(${point.scale}) rotate(${
                point.rotate
              }, ${x + 25}, ${y + 25})`
              return (
                <use
                  key={idx}
                  transform={transform}
                  x={x}
                  xlinkHref={`#${this.props.name}`}
                  y={y}
                />
              )
            })}
          </pattern>
        </defs>
        <rect
          fill={`url(#${this.props.name}-pattern)`}
          height={this.state.height}
          width={this.state.width}
          x="0"
          y="0"
        />
        <rect
          fill="transparent"
          height={PATTERN_SIZE}
          hidden={!this.props.debug}
          opacity="0.1"
          stroke="white"
          width={PATTERN_SIZE}
          x="0"
          y="0"
        />
      </Svg>
    )
  }
}

export default withTheme(HeroBackground)
