import CallForSpeakersPromo from '../components/Promos/CallForSpeakers'
import CodeOfConductPromo from '../components/Promos/CodeOfConduct'
import DefaultLayout from '../layouts/DefaultLayout'
import GridContainer from '../components/GridContainer/GridContainer'
import Hero from '../components/Hero/Hero'
import NewsList from '../components/News/NewsList'
import React from 'react'
import SponsorsPromo from '../components/Promos/SponsorsPromo'
import styled from 'styled-components'
import TalkScriptPromo from '../components/Promos/TalkScriptPromo/TalkScriptPromo'

const Content = styled(GridContainer)`
  margin-bottom: calc(
    (${({ theme }) => theme.spacer.lg} + ${({ theme }) => theme.spacer.sm}) * -1
  );
`

const HomePage = () => (
  <DefaultLayout>
    <Hero/>
    <Content>
      <NewsList/>
      <CallForSpeakersPromo/>
      <SponsorsPromo/>
      <TalkScriptPromo/>
      <CodeOfConductPromo/>
    </Content>
  </DefaultLayout>
)

export default HomePage
