import { bool, object, shape, string } from 'prop-types'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import React from 'react'

const Avatar = css`
  background: white;
  border: solid 3px var(--border-color);
  border-radius: 50%;
`

const StyledFixedImg = styled(Img)`
  -ms-fixed: 1;
  ${({ round }) => round && Avatar}
  picture: {
    display: inline-block;
  }
`

const StyledImage = styled.img`
  ${({ round }) => round && Avatar}
  height: auto;
  max-width: 100%;
`

const Image = ({ alt, round, src, subPath, ...props }) => {
  if (!src) {
    return null
  }
  const { base, childImageSharp } = src
  if (childImageSharp) {
    return (
      <StyledFixedImg
        alt={alt}
        fixed={childImageSharp.fixed}
        round={round}
        {...props}
      />
    )
  }
  src = require(`../../assets/images/${subPath}/${base}`)
  return (
    <div {...props}>
      <picture>
        <StyledImage alt={alt} src={src}/>
      </picture>
    </div>
  )
}

Image.defaultProps = {
  alt: '',
  round: false
}

Image.propTypes = {
  alt: string.isRequired,
  round: bool,
  src: shape({
    base: string,
    childImageSharp: object
  }),
  subPath: string
}

export default Image
