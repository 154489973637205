import { array, node, string } from 'prop-types'
import React from 'react'
import ScheduleDay from './ScheduleDay'
import { slugify } from '../../utils/string'
import styled from 'styled-components'

const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.spacer.xl};
`

const Schedule = ({ agenda, children, date, title }) => (
  <Section id={slugify(title)}>
    <ScheduleDay agenda={agenda} date={date} title={title}>
      {children}
    </ScheduleDay>
  </Section>
)

Schedule.propTypes = {
  agenda: array,
  children: node,
  date: string,
  title: string
}

export default Schedule
