import { bool, string } from 'prop-types'
import styled from 'styled-components'

const GridRow = styled.div`
  grid-column: medium;
  ${({ column }) => column && `grid-column: ${column};`}
  ${({ full }) => full && `grid-column: full;`}
  ${({ narrow }) => narrow && `grid-column: narrow;`}
  ${({ skinny }) => skinny && `grid-column: skinny;`}
  ${({ wide }) => wide && `grid-column: wide;`}
  @media ${({ theme }) => theme.devices.mobileSmallMin} {
    grid-column: ${({ mobileSmall }) => mobileSmall};
  }
  @media ${({ theme }) => theme.devices.mobileMediumMin} {
    grid-column: ${({ mobileMedium }) => mobileMedium};
  }
  @media ${({ theme }) => theme.devices.mobileLargeMin} {
    grid-column: ${({ mobileLarge }) => mobileLarge};
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-column: ${({ tablet }) => tablet};
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-column: ${({ laptop }) => laptop};
  }
  @media ${({ theme }) => theme.devices.laptopLargeMin} {
    grid-column: ${({ laptopLarge }) => laptopLarge};
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    grid-column: ${({ FourK }) => FourK};
  }
  /* DEPRECATE */
  ${({ theme }) => theme.media.xxs} {
    grid-column: ${({ xxs }) => xxs};
  }
  ${({ theme }) => theme.media.xs} {
    grid-column: ${({ xs }) => xs};
  }
  ${({ theme }) => theme.media.sm} {
    grid-column: ${({ sm }) => sm};
  }
  ${({ theme }) => theme.media.md} {
    grid-column: ${({ md }) => md};
  }
  ${({ theme }) => theme.media.lg} {
    grid-column: ${({ lg }) => lg};
  }
  ${({ theme }) => theme.media.xlg} {
    grid-column: ${({ xlg }) => xlg};
  }
`

GridRow.propTypes = {
  column: string,
  full: bool,
  lg: string,
  md: string,
  medium: bool,
  narrow: bool,
  skinny: bool,
  sm: string,
  wide: bool,
  xlg: string,
  xs: string,
  xxs: string
}

export default GridRow
