import React, { memo } from 'react'
import FooterLink from './FooterLink'
import LinkGroup from './LinkGroup'

const ProgramLinks = memo(() => (
  <LinkGroup title="Program">
    <li>
      <FooterLink href="/schedule/">Schedule</FooterLink>
    </li>
    <li>
      <FooterLink href="/scholarships/">Scholarships</FooterLink>
    </li>
    {/*
      <li>
        <FooterLink href="/seakers/">Speakers</FooterLink>
      </li>
    */}
    <li>
      <FooterLink href="/sponsors/">Sponsors</FooterLink>
    </li>
  </LinkGroup>
))

export default ProgramLinks
