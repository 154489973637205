import React, { memo } from 'react'
import FooterLink from './FooterLink'
import LinkGroup from './LinkGroup'

const PolicyLinks = memo(() => (
  <LinkGroup title="Policies">
    <li>
      <FooterLink href="/accessibility/">Accessibility Statement</FooterLink>
    </li>
    <li>
      <FooterLink href="/code-of-conduct/">Code of Conduct</FooterLink>
    </li>
  </LinkGroup>
))

export default PolicyLinks
