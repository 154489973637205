import React, { memo } from 'react'
import FooterLink from './FooterLink'
import LinkGroup from './LinkGroup'

const EventLinks = memo(() => (
  <LinkGroup title="Event">
    <li>
      <FooterLink href="/about/activities/">Activities</FooterLink>
    </li>
    <li>
      <FooterLink href="/about/childcare/">Childcare</FooterLink>
    </li>
    <li>
      <FooterLink href="/about/city-guide/">City Guide</FooterLink>
    </li>
    <li>
      <FooterLink href="/about/tickets/">Tickets</FooterLink>
    </li>
    {/*
    <li>
      <FooterLink href="/about/venue/">Venue</FooterLink>
    </li>
    */}
    <li>
      <FooterLink href="/about/team/">Our Team</FooterLink>
    </li>
    <li>
      <FooterLink href="/about/faq/">FAQ</FooterLink>
    </li>
  </LinkGroup>
))

export default EventLinks
