/* eslint-disable react/display-name, jsx-a11y/heading-has-content */
import * as Headings from './Headings'
import blockEls from './blockElements'
import Blockquote from './Blockquote'
import Link from './Link'
import React from 'react'
import styled from 'styled-components'

const BlockEl = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer.lg};
  margin-top: ${({ theme }) => theme.spacer.sm};
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const styledBlockEls = blockEls.reduce(
  (els, TagName) => ({
    ...els,
    [TagName]: (props) => <BlockEl as={TagName} {...props}/>
  }),
  {}
)

const List = styled.ul`
  margin-left: ${({ theme }) => theme.spacer.md};
  + ul {
    margin-left: ${({ theme }) => theme.spacer.md * 2};
  }
`

const UnstyledList = styled.ul`
  list-style-type: none;
`

const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacer.sm};
  ${({ theme }) => theme.media.sm} {
    margin-bottom: ${({ theme }) => theme.spacer.md};
  }
`

const StyledLink = styled(Link)``

// TODO: CoC phone table is wrapped in CTA on 2018 site... how to replicate?
const Table = styled.table`
  border-spacing: 0;
`

const Paragraph = styledBlockEls.p

export {
  Blockquote,
  ButtonGroup,
  Headings,
  Link,
  Paragraph,
  StyledLink,
  UnstyledList
}

export default {
  ...styledBlockEls,
  a: Link,
  blockquote: (props) => <BlockEl as={Blockquote} {...props}/>,
  h1: Headings.H1,
  h2: Headings.H2,
  h3: Headings.H3,
  h4: Headings.H4,
  h5: Headings.H5,
  h6: Headings.H6,
  li: (props) => <ListItem as="li" {...props}/>,
  ol: (props) => <List as="ol" {...props}/>,
  table: (props) => <Table {...props}/>,
  td: styled.td`
    border-top: solid 1px #ddd;
    hyphens: auto;
    overflow-wrap: break-word;
    padding: 8px;
    vertical-align: top;
  `,
  th: styled.th`
    border-bottom: solid 2px #ddd;
    padding: 8px;
    text-align: left;
    vertical-align: bottom;
  `,
  tr: styled.tr`
    tbody & {
      &:nth-of-type(odd) {
        background-color: #f9f9f9;
      }
    }
  `,
  ul: (props) => <List as="ul" {...props}/>
}
