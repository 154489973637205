export default [
  {
    rotate: -10,
    scale: 0.05,
    x: -15,
    y: 185
  },
  {
    rotate: -10,
    scale: 0.05,
    x: -15,
    y: -15
  },
  {
    rotate: -10,
    scale: 0.05,
    x: 185,
    y: -15
  },
  {
    rotate: -5,
    scale: 0.03,
    x: 50,
    y: 50
  },
  {
    rotate: 50,
    scale: 0.06,
    x: 100,
    y: 110
  },
  {
    rotate: 50,
    scale: 0.06,
    x: 100,
    y: -90
  },
  {
    rotate: 28,
    scale: 0.07,
    x: 140,
    y: 0
  },
  {
    rotate: 28,
    scale: 0.07,
    x: -60,
    y: 0
  },
  {
    rotate: -95,
    scale: 0.05,
    x: -25,
    y: 190
  },
  {
    rotate: -95,
    scale: 0.05,
    x: 175,
    y: 190
  },
  {
    rotate: -14,
    scale: 0.02,
    x: 115,
    y: 110
  },
  {
    rotate: 13,
    scale: 0.03,
    x: 140,
    y: 130
  },
  {
    rotate: 60,
    scale: 0.03,
    x: 170,
    y: 175
  },
  {
    rotate: 60,
    scale: 0.03,
    x: 170,
    y: -25
  },
  {
    rotate: -30,
    scale: 0.035,
    x: 40,
    y: 100
  }
]
