import { Headings, Link, Paragraph } from '../StyledComponents'
import GridRow from '../GridRow/GridRow'
import React from 'react'

const OurTeam = () => (
  <GridRow>
    <Headings.H2>Our Team</Headings.H2>
    <Paragraph>Who are the people that make JSConf US happen?</Paragraph>
    <Paragraph>
      Come find out and meet <Link href="/about/team">our team</Link>!
    </Paragraph>
  </GridRow>
)

export default OurTeam
