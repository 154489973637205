import { object, string } from 'prop-types'
import Image from '../Image/Image'
import React from 'react'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  img {
    height: 45px;
  }
`

const Prefix = styled.aside`
  ${({ theme }) => theme.typography.bodySmall}
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.pink500};
  display: ${({ src }) => (src ? 'block' : 'none')};
  font-family: Futura;
  ${Prefix} + & {
    display: block;
  }
`

const SponsorAttribution = ({ name, src, subPath }) => (
  <div>
    <Prefix>Sponsored by</Prefix>
    <StyledImage alt="Facebook" src={src} subPath={subPath}/>
    <Name>{name}</Name>
  </div>
)

SponsorAttribution.propTypes = {
  name: string.isRequired,
  src: object,
  subPath: string.isRequired
}

export default SponsorAttribution
