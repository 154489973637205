import { array, bool, shape, string } from 'prop-types'
import { graphql } from 'gatsby'
import { Headings } from '../components/StyledComponents'
import MdxTemplate from './MdxTemplate'
import React from 'react'
import TableOfContents from '../components/TableOfContents/TableOfContents'

export default function ContentTemplate ({ children, data: { mdx }, ...props }) {
  const { code, frontmatter, tableOfContents } = mdx
  return (
    <MdxTemplate
      body={code.body}
      description={frontmatter.description}
      title={frontmatter.title}
      {...props}
      render={(body) => (
        <>
          <Headings.H1>{frontmatter.title}</Headings.H1>
          <TableOfContents
            hidden={frontmatter.toc !== true}
            items={tableOfContents.items}
          />
          {body}
        </>
      )}
    />
  )
}

MdxTemplate.propTypes = {
  data: shape({
    code: shape({
      body: string.isRequired
    }),
    mdx: shape({
      frontmatter: shape({
        description: string,
        title: string,
        toc: bool
      }),
      tableOfContents: shape({
        items: array
      })
    })
  })
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        description
        title
        toc
      }
      id
      code {
        body
      }
      tableOfContents
    }
  }
`
