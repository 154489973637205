import { graphql, StaticQuery } from 'gatsby'
import { Headings, Link, Paragraph } from '../components/StyledComponents'
import DefaultLayout from '../layouts/DefaultLayout'
import { getNodes } from '../utils/nodeHelpers'
import GridContainer from '../components/GridContainer/GridContainer'
import GridRow from '../components/GridRow/GridRow'
import { LogosProvider } from '../contexts/Logos'
import React from 'react'
import SponsorLineup from '../components/SponsorLineup/SponsorLineup'

const SponsorsPage = () => (
  <StaticQuery
    query={graphql`
      query SponsorsQuery {
        sponsors: allMdx(
          filter: {
            fields: { slug: { regex: "/sponsors/" } }
            frontmatter: { visible: { eq: true } }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                level
                logo
                name
                scholarship
                visible
              }
            }
          }
        }
        sponsorLogos: allFile(
          filter: {
            internal: { mediaType: { regex: "/image/" } }
            relativePath: { regex: "/sponsors/" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fixed(height: 125) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={({
      sponsors: { edges: sponsors },
      sponsorLogos: { edges: sponsorLogos }
    }) => (
      <LogosProvider value={getNodes(sponsorLogos)}>
        <DefaultLayout
          description="The best developers will gather here to learn and showcase the future of JavaScript & we want you as a sponsor!"
          title="Sponsors"
        >
          <GridContainer>
            <GridRow>
              <Headings.H1>Sponsors</Headings.H1>
              <Paragraph>
                At JSConf US 2019, the best JS programmers will gather for a two
                track, three day conference that will showcase the future of
                JavaScript and we want you there as a sponsor!
              </Paragraph>
              <Paragraph>
                Our sponsorships are are little bit different than what you may
                be accustomed to. There are no booths, no exhibit halls, and we
                encourage you to send your best technical talent. We focus the
                sponsorships on creating amazing shared experiences which have a
                far broader and longer lasting effects for you and our
                attendees.
              </Paragraph>
              <Paragraph>
                Whether your goal is brand exposure, recruiting, product launch,
                or something else, we have a sponsorship opportunity for you.
              </Paragraph>
              <Paragraph>
                Interested in sponsoring?{' '}
                <Link href="http://bit.ly/JSConfUS2019Sponsorship">
                  Contact us for details!
                </Link>
              </Paragraph>
            </GridRow>
          </GridContainer>
          <SponsorLineup sponsors={getNodes(sponsors)}/>
        </DefaultLayout>
      </LogosProvider>
    )}
  />
)

export default SponsorsPage
