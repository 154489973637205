import { node, string } from 'prop-types'
import Image from '../Image/Image'
import { LogosConsumer } from '../../contexts/Logos'
import React from 'react'
import styled from 'styled-components'

const Img = styled(Image)`
  img {
    max-height: 125px;
    width: 100%;
  }
`

const SponsorLogo = ({ children, logo, name, ...props }) => (
  <LogosConsumer key={name} logo={logo} name={name}>
    {({ logoImage, subPath }) => {
      if (!logoImage) {
        return children
      }
      return <Img src={logoImage} subPath={subPath} {...props}/>
    }}
  </LogosConsumer>
)

SponsorLogo.propTypes = {
  children: node,
  logo: string,
  name: string.isRequired
}

export default SponsorLogo
