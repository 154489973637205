import { any, bool, string } from 'prop-types'
import Button from '../Button/Button'
import { Link } from '../StyledComponents'
import React from 'react'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: inline-block;
`

const LinkButton = (props) => <Button as={StyledLink} {...props}/>

LinkButton.propTypes = {
  children: any,
  href: string,
  primary: bool,
  secondary: bool
}

export default LinkButton
