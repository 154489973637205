export default [
  {
    rotate: 0,
    scale: 1.5,
    x: 25,
    y: 25
  },
  {
    rotate: -40,
    scale: 1.5,
    x: 55,
    y: -35
  },
  {
    rotate: -40,
    scale: 1.5,
    x: 55,
    y: 165
  },
  {
    rotate: -45,
    scale: 1.5,
    x: 45,
    y: 45
  },
  {
    rotate: 35,
    scale: 1.5,
    x: 55,
    y: 30
  },
  {
    rotate: 65,
    scale: 1.5,
    x: 100,
    y: 45
  },
  {
    rotate: -100,
    scale: 1.5,
    x: 50,
    y: -15
  },
  {
    rotate: -140,
    scale: 1.5,
    x: 65,
    y: -15
  },
  {
    rotate: -180,
    scale: 1.5,
    x: 50,
    y: -65
  },
  {
    rotate: -180,
    scale: 1.5,
    x: 50,
    y: 135
  },
  {
    rotate: 25,
    scale: 1.5,
    x: 40,
    y: 135
  },
  {
    rotate: 75,
    scale: 1.5,
    x: 20,
    y: 100
  },
  {
    rotate: 46,
    scale: 1.5,
    x: -35,
    y: 100
  },
  {
    rotate: 46,
    scale: 1.5,
    x: 165,
    y: 100
  },
  {
    rotate: -97,
    scale: 1.5,
    x: 100,
    y: 50
  },
  {
    rotate: -25,
    scale: 1.5,
    x: 160,
    y: 80
  },
  {
    rotate: 136,
    scale: 1.5,
    x: 90,
    y: 40
  },
  {
    rotate: -136,
    scale: 1.5,
    x: 150,
    y: -55
  },
  {
    rotate: 136,
    scale: 1.5,
    x: 85,
    y: -35
  },
  {
    rotate: 136,
    scale: 1.5,
    x: 85,
    y: 165
  },
  {
    rotate: 3,
    scale: 1.5,
    x: 85,
    y: 155
  },
  {
    rotate: 97,
    scale: 1.5,
    x: 125,
    y: 155
  },
  {
    rotate: 97,
    scale: 1.5,
    x: -75,
    y: 155
  },
  {
    rotate: 59,
    scale: 1.5,
    x: 100,
    y: 160
  },
  {
    rotate: -52,
    scale: 1.5,
    x: 10,
    y: 90
  },
  {
    rotate: -52,
    scale: 1.5,
    x: 210,
    y: 90
  },
  {
    rotate: -95,
    scale: 1.5,
    x: 25,
    y: 90
  }
]
