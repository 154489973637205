import pattern from './pattern'
import React from 'react'
import styled from 'styled-components'
import SvgBackground from '../SvgBackground/SvgBackground'

const StyledSvgBg = styled(SvgBackground)`
  margin-top: calc(${({ theme }) => theme.spacer.lg} * -1);
  transform: skewY(2deg);
  z-index: ${({ theme }) => theme.zIndex.heroContent - 1};
`

const HeroBackground = (props) => (
  <StyledSvgBg
    bgColor="purple500"
    color="purple600"
    name="hero"
    pattern={pattern}
    {...props}
  >
    <polygon points="25,0 0,50 50,50"/>
  </StyledSvgBg>
)

export default HeroBackground
