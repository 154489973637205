import React, { memo } from 'react'
import FooterLink from './FooterLink'
import LinkGroup from './LinkGroup'

const InvolvementLinks = memo(() => (
  <LinkGroup title="Get Involved">
    <li>
      {/* <FooterLink href="/call-for-proposals/">Call for Proposals</FooterLink> */}
      <FooterLink href="/speakers/">Speakers</FooterLink>
    </li>
    <li>
      <FooterLink href="/sponsors/">Become a Sponsor</FooterLink>
    </li>
    <li>
      <FooterLink href="/scholarships/#want-to-contribute">
        Scholarship Contributions
      </FooterLink>
    </li>
  </LinkGroup>
))

export default InvolvementLinks
