export default [
  {
    rotate: -10,
    scale: 0.5,
    x: -15,
    y: -15
  },
  {
    rotate: -10,
    scale: 0.5,
    x: 185,
    y: 185
  },
  {
    rotate: -10,
    scale: 0.5,
    x: 185,
    y: -15
  },
  {
    rotate: -10,
    scale: 0.5,
    x: -15,
    y: 185
  },
  {
    rotate: -10,
    scale: 0.4,
    x: -10,
    y: 90
  },
  {
    rotate: -10,
    scale: 0.4,
    x: 190,
    y: 90
  },
  {
    rotate: -5,
    scale: 0.4,
    x: 25,
    y: 12
  },
  {
    rotate: 50,
    scale: 0.5,
    x: 57,
    y: -17
  },
  {
    rotate: 50,
    scale: 0.5,
    x: 57,
    y: 183
  },
  {
    rotate: 28,
    scale: 0.85,
    x: 68,
    y: 17
  },
  {
    rotate: -95,
    scale: 0.5,
    x: 7,
    y: 55
  },
  {
    rotate: -14,
    scale: 0.2,
    x: 45,
    y: 62
  },
  {
    rotate: 13,
    scale: 0.6,
    x: 31,
    y: 90
  },
  {
    rotate: 60,
    scale: 0.35,
    x: 74,
    y: 77
  },
  {
    rotate: -15,
    scale: 0.8,
    x: 11,
    y: 130
  },
  {
    rotate: 0,
    scale: 0.4,
    x: 67,
    y: 120
  },
  {
    rotate: -12,
    scale: 0.2,
    x: 148,
    y: 17
  },
  {
    rotate: 20,
    scale: 0.5,
    x: 162,
    y: 33
  },
  {
    rotate: 15,
    scale: 0.55,
    x: 100,
    y: 135
  },
  {
    rotate: -10,
    scale: 0.95,
    x: 110,
    y: 45
  },
  {
    rotate: -10,
    scale: 0.2,
    x: 100,
    y: 110
  },
  {
    rotate: 15,
    scale: 0.9,
    x: 140,
    y: 110
  },
  {
    rotate: 55,
    scale: 0.2,
    x: 75,
    y: 160
  },
  {
    rotate: -35,
    scale: 0.99,
    x: 100,
    y: 175
  },
  {
    rotate: -35,
    scale: 0.99,
    x: 100,
    y: -25
  },
  {
    rotate: 85,
    scale: 0.4,
    x: 155,
    y: 165
  }
]
