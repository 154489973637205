import { Headings, Link } from '../../StyledComponents'
import GridContainer from '../../GridContainer/GridContainer'
import GridRow from '../../GridRow/GridRow'
import LinkButton from '../../LinkButton/LinkButton'
import React from 'react'
import styled from 'styled-components'
import TalkScriptBackground from './TalkScriptBackground'
import TalkScriptLogo from '../../../assets/images/sponsors/talkscript.svg'

const StyledRow = styled(GridRow)`
  padding-bottom: ${({ theme }) => theme.spacer.md};
  padding-top: ${({ theme }) => theme.spacer.xxl};
  position: relative;
  margin-top: calc(${({ theme }) => theme.spacer.xl} * -1);
`

const Content = styled.div`
  justify-items: center;
  color: ${({ contentColor, theme }) => theme.colors[contentColor]};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.md};
  grid-template-rows: max-content 1fr max-content;
  text-align: center;
`

const Logo = styled.img`
  width: 33%;
`

const Header = styled(Headings.H3)`
  ${({ theme }) => theme.typography.heading.lg};
  background: ${({ theme }) => theme.colors.blueB700};
  color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacer.sm} ${({ theme }) => theme.spacer.md};
  text-align: center;
  width: 100%;
`

const Copy = styled.div`
  background: ${({ theme }) => theme.colors.blueB700};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacer.sm} ${({ theme }) => theme.spacer.md};
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blueA700};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

const TalkScriptPromo = () => (
  <StyledRow as="section" full>
    <TalkScriptBackground/>
    <GridContainer>
      <GridRow narrow>
        <Content>
          <Logo alt="" src={TalkScriptLogo}/>
          <Header as="h3">The official podcast of JSConf US</Header>
          <Copy>
            Join us for a <strong>live</strong> recording of SitePen's podcast,{' '}
            <StyledLink href="https://twitter.com/talkscript">
              TalkScript
            </StyledLink>
            , during lunch on August 12th in the SitePen Track!
          </Copy>
          <LinkButton
            aria-label="Learn more about TalkScript Presents! at JSConf US"
            href="/sponsors/sitepen"
            secondary
          >
            Learn More
          </LinkButton>
        </Content>
      </GridRow>
    </GridContainer>
  </StyledRow>
)

export default TalkScriptPromo
