import { graphql, StaticQuery } from 'gatsby'
import { AvatarsProvider } from '../../contexts/Avatars'
import DefaultLayout from '../../layouts/DefaultLayout'
import { getNodes } from '../../utils/nodeHelpers'
import React from 'react'
import TeamRoster from '../../components/TeamRoster/TeamRoster'

const TeamPage = () => (
  <StaticQuery
    query={graphql`
      query TeamQuery {
        mcs: allMcsJson {
          edges {
            node {
              company
              href
              name
            }
          }
        }
        nodeart: allNodeartJson {
          edges {
            node {
              company
              href
              name
            }
          }
        }
        nodeboats: allNodeboatsJson {
          edges {
            node {
              company
              href
              name
            }
          }
        }
        nodebots: allNodebotsJson {
          edges {
            node {
              company
              href
              name
            }
          }
        }
        noderockets: allNoderocketsJson {
          edges {
            node {
              company
              href
              name
            }
          }
        }
        staff: allStaffJson {
          edges {
            node {
              href
              name
              roles
            }
          }
        }
        staffImages: allFile(
          filter: {
            internal: { mediaType: { regex: "/image/" } }
            relativePath: { regex: "/team/[^/]+/" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                fluid(maxWidth: 380) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={({
      mcs: { edges: mcs },
      nodeart: { edges: nodeart },
      nodeboats: { edges: nodeboats },
      nodebots: { edges: nodebots },
      noderockets: { edges: noderockets },
      staff: { edges: staff },
      staffImages: { edges: staffImages }
    }) => (
      <AvatarsProvider value={getNodes(staffImages)}>
        <DefaultLayout
          description="Meet the team that makes JSConf US happen!"
          title="Our Team"
        >
          <TeamRoster
            mcs={getNodes(mcs)}
            nodeart={getNodes(nodeart)}
            nodeboats={getNodes(nodeboats)}
            nodebots={getNodes(nodebots)}
            noderockets={noderockets}
            staff={getNodes(staff)}
          />
        </DefaultLayout>
      </AvatarsProvider>
    )}
  />
)

export default TeamPage
