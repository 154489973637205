import { node, oneOf } from 'prop-types'
import React from 'react'
import { slugify } from '../../utils/string'

const Heading = ({ as: TagName, children, ...props }) => (
  <TagName id={slugify(children)} {...props}>
    {children}
  </TagName>
)

Heading.propTypes = {
  as: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  children: node.isRequired
}

export default Heading
