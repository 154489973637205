import { Headings, Link, Paragraph } from '../StyledComponents'
import GridRow from '../GridRow/GridRow'
import React from 'react'

const Tickets = () => (
  <GridRow>
    <Headings.H2>Tickets</Headings.H2>
    <Paragraph>
      Tickets are currently on sale! Get yours now before our activity registration opens on June 11th!
    </Paragraph>
    <Paragraph>
      Check out our <Link href="/about/tickets">ticket information</Link> page!
    </Paragraph>
  </GridRow>
)

export default Tickets
