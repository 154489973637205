import styled from 'styled-components'
import { StyledLink } from '../StyledComponents'

const FooterLink = styled(StyledLink)`
  font-size: 0.9em;
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.purpleB700};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.yellow500};
  }
`

export default FooterLink
