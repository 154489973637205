import { Link } from '../StyledComponents'
import React from 'react'
import SponsorLogo from '../SponsorLogo/SponsorLogo'
import { string } from 'prop-types'
import styled from 'styled-components'

const Logo = styled(SponsorLogo)`
  img {
    object-fit: contain !important;
    max-height: 125px;
    max-width: 200px;
  }
`

const Item = styled.li`
  text-align: center;
`

const SponsorItem = ({ className, logo, name, slug, ...props }) => (
  <Item className={className}>
    <Link href={slug}>
      <Logo logo={logo} name={name} {...props}>
        <span>{name}</span>
      </Logo>
    </Link>
  </Item>
)

SponsorItem.propTypes = {
  logo: string,
  name: string.isRequired,
  slug: string.isRequired
}

export default SponsorItem
