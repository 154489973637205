import CallToAction from '../CallToAction/CallToAction'
import GridContainer from '../GridContainer/GridContainer'
import GridRow from '../GridRow/GridRow'
import HeroBackground from './HeroBackground'
import HeroCopy from './HeroCopy'
import LinkButton from '../LinkButton/LinkButton'
import logo from '../../assets/images/logo.svg'
import React from 'react'
import styled from 'styled-components'

const HeroContainer = styled.div`
  display: grid;
  padding-bottom: ${({ theme }) => theme.spacer.lg};
  position: relative;
`

const HeroContent = styled.div`
  display: grid;
  height: 100%;
  padding: ${({ theme }) => theme.spacer.lg} 0;
  z-index: ${({ theme }) => theme.zIndex.heroContent};
`

const HeroImage = styled(GridRow)`
  align-self: center;
  text-align: center;
  img {
    max-width: 100%;
    height: 60vmin;
  }
`

const HeroGrid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.md};
  grid-template-columns: 1fr;
  grid-template-areas:
    'cta'
    'actions'
    'title'
    'copy';
  margin-top: ${({ theme }) => theme.spacer.md};
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-template-columns: 1fr max-content;
    grid-template-areas:
      'title cta'
      'copy actions';
  }
  aside {
    grid-area: cta;
  }
`

const Cta = styled.aside`
  grid-area: cta;
  margin-left: ${({ theme }) => theme.spacer.xs};
  margin-right: ${({ theme }) => theme.spacer.xs};
  @media ${({ theme }) => theme.devices.laptopMin} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Actions = styled.div`
  display: flex;
  grid-area: actions;
  justify-content: space-evenly;
`

const Hero = () => (
  <HeroContainer>
    <HeroContent>
      <GridContainer>
        <HeroImage medium>
          <img alt="" src={logo}/>
        </HeroImage>
        <GridRow lg="medium" md="wide" sm="narrow" wide xlg="narrow">
          <HeroGrid>
            <HeroCopy/>
            <Cta>
              <CallToAction as="div" subhead="Carlsbad, CA" width="auto">
                August 12th - 14th
              </CallToAction>
            </Cta>
            <Actions>
              <div>
                <LinkButton
                  href="https://ti.to/jsconf-us/jsconf-us-2019"
                  primary
                >
                  Buy Tickets
                </LinkButton>
              </div>
              <div>
                <LinkButton
                  aria-label="Learn more about JSConf US Tickets"
                  href="/about/tickets/"
                  secondary
                >
                  Learn More
                </LinkButton>
              </div>
            </Actions>
          </HeroGrid>
        </GridRow>
      </GridContainer>
    </HeroContent>
    <HeroBackground/>
  </HeroContainer>
)

export default Hero
