import { bool, object, string } from 'prop-types'
import Image from '../Image/Image'
import React from 'react'

import styled from 'styled-components'

const Root = styled.div`
  --border-color: ${({ theme }) => theme.colors.blue400};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.xs};
  grid-template-columns: 48px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    'prefix prefix'
    'icon name';
  &:nth-child(odd) {
    --border-color: ${({ theme }) => theme.colors.green400};
  }
`

const StyledImage = styled(Image)`
  grid-area: icon;
  justify-self: center;
  width: 100%;
`

const Prefix = styled.aside`
  ${({ theme }) => theme.typography.bodySmall}
  align-self: flex-end;
  grid-area: prefix;
  line-height: 1;
  &:empty {
    display: none;
  }
`

const Name = styled.div`
  align-self: center;
  font-family: Futura;
  grid-area: name;
  ${Prefix} + & {
    color: ${({ theme }) => theme.colors.pink500};
    grid-column: 1 / span 2;
  }
  ${Prefix}:empty + & {
    color: currentColor;
  }
`

const Attribution = ({ avatar, name, prefix, round, subPath }) => (
  <Root>
    <Prefix>{prefix}</Prefix>
    <StyledImage key={avatar} round={round} src={avatar} subPath={subPath}/>
    <Name>{name}</Name>
  </Root>
)

Attribution.defaultProps = {
  name: 'Sponsored by'
}

Attribution.propTypes = {
  avatar: object,
  name: string.isRequired,
  prefix: string,
  round: bool,
  subPath: string
}

export default Attribution
