import pattern from './pattern'
import React from 'react'
import SvgBackground from '../../SvgBackground/SvgBackground'

const TalkScriptBackground = () => (
  <SvgBackground
    bgColor="blue900"
    color="blue700"
    name="talkscript"
    pattern={pattern}
  >
    <path d="M152 337h224v59H237v533h139v59H152zM1125 929h139V396h-139v-59h224v651h-224zM531 868V545H415v-75h317v75H617v323zM756 812l46-64a175 175 0 0 0 126 54c47 0 69-22 69-45 0-70-229-22-229-172 0-66 57-121 151-121 63 0 116 19 155 56l-47 62c-32-30-75-44-116-44-36 0-56 16-56 40 0 64 228 21 228 170 0 73-52 128-159 128-76 0-131-26-168-64zM175 1161v-67h-24v-16h66v16h-24v67zM305 1161l-6-14h-35l-5 14h-20l32-83h22l32 83zm-23-65l-13 35h26zM360 1161v-83h17v67h35v16zM501 1161l-25-33-7 7v26h-17v-83h17v37l30-37h21l-33 39 36 44zM552 1149l10-13a36 36 0 0 0 26 11c10 0 14-5 14-9 0-15-47-5-47-36 0-14 12-25 31-25 13 0 24 4 32 11l-9 13a36 36 0 0 0-24-9c-8 0-12 4-12 9 0 13 47 4 47 35 0 15-10 26-33 26-16 0-27-5-35-13zM656 1119c0-25 19-42 44-42 18 0 28 10 34 20l-15 7a22 22 0 0 0-19-12c-15 0-26 12-26 27s11 28 26 28a22 22 0 0 0 19-12l15 7c-6 10-16 20-34 20-25 0-44-17-44-43zM819 1161l-17-30h-12v30h-18v-83h39c17 0 27 11 27 27 0 14-9 22-18 24l19 32zm1-56c0-7-5-11-12-11h-18v22h18c7 0 12-4 12-11zM879 1161v-83h18v83zM940 1161v-83h39c18 0 28 12 28 27s-10 26-28 26h-21v30zm49-56c0-7-6-11-12-11h-19v22h19c6 0 12-5 12-11zM1064 1161v-67h-24v-16h66v16h-24v67zM1115 1142h19v19h-19zM1164 1078h63v17h-45v17h40v17h-40v32h-18zM1265 1078h20l22 35 22-35h19v83h-18v-54l-23 35h-1l-23-35v54h-18z"/>
  </SvgBackground>
)

export default TalkScriptBackground
