import { node, string } from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  height: 100%;
`

const PhotoGrid = ({ children, float, id, ...props }) => (
  <Layout float={float} id={id} {...props}>
    {children}
  </Layout>
)

PhotoGrid.propTypes = {
  children: node,
  float: string,
  id: string
}

export default PhotoGrid
