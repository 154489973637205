import { Link, Paragraph } from '../StyledComponents'
import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :first-child {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :last-child {
    grid-column: 1 / -1;
    grid-row: 6 / -1;
  }
`

const NodeBots = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="nodebots.jpg"/>
        <ActivityImage src="sensor-breakout.jpg"/>
      </StyledPhotoGrid>
    )}
    title="NodeBots"
  >
    <Paragraph>
      Team up (or go solo) to hack together electronic circuits, all using just
      JavaScript!
    </Paragraph>
    <Paragraph>
      If you have no idea what an LED is, or a resistor for that matter, have no
      fear.
    </Paragraph>
    <Paragraph>
      The{' '}
      <Link href="/about/team/#nodebots">
        experts that literally invented the JavaScript-powered hardware
        community
      </Link>{' '}
      will be on hand to help you hack your way to success as you learn all
      about sensors, the difference between motors and servos, breadboards,
      breakout boards, LCD and segmented displays, photocells, trimpots, and
      more!
    </Paragraph>
  </Activity>
)

export default NodeBots
