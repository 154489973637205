import Activity from '../../components/Activity/Activity'
import ActivityImage from '../../components/PhotoGrid/ActivityImage'
import { Paragraph } from '../../components/StyledComponents'
import PhotoGrid from '../../components/PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :first-child {
    grid-column: 1 / -1;
    grid-row: 1 / span 6;
  }
  > :last-child {
    grid-column: 3 / span 6;
    grid-row: 6 / -1;
  }
`

const SurfingLessons = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="surfing.jpg"/>
        <ActivityImage src="surfing2.jpg"/>
      </StyledPhotoGrid>
    )}
    title="Surfing Lessons"
  >
    <Paragraph>What could be more Southern Californian than surfing?</Paragraph>
    <Paragraph>
      Professional instructors will teach you essential fundamentals which guide
      you through the amazing process of learning to surf. No matter your skill
      level, you will learn the basics of board handling, positioning, paddling,
      various techniques on getting to your feet, surfing etiquette, ocean
      knowledge, and water safety.
    </Paragraph>
    <Paragraph>
      The instructors will be in the water assisting you into waves and giving
      you tips after each ride to help you successfully ride your next wave to
      the beach. The lesson will start with an instruction session on the beach
      that will last approximately 20 minutes before getting into the water with
      your instructor.
    </Paragraph>
    <Paragraph>
      <strong>Note:</strong> You will get wet! Basic swimming competency is
      required. Please bring a towel, extra clothes, and a bathing suit.
      Wetsuits will be provided. Space is limited, register early!
    </Paragraph>
  </Activity>
)

export default SurfingLessons
