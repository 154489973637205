export default {
  childcareClose: {
    description:
      "Time to get your kiddos! Our on-site childcare service will close at 7pm sharp! Please don't be late! All unattended children will be left in the care of @horse_js!",
    endTime: 1900,
    location: 'Coastal Events Center',
    startTime: 1845,
    title: 'Childcare Child Pick-Up'
  },
  childcareOpen: {
    description:
      'Our on-site childcare service is now open for child registration and drop-off!',
    duration: 60,
    location: 'Coastal Events Center',
    sponsors: [],
    startTime: 800,
    title: 'Childcare Child Drop-Off'
  },
  poolsideCampfires: {
    components: [
      {
        description:
          'Whether you just need a break from the dinner or are looking for deep conversation, stop by the evening campfires located on the upper-level of the Edge Pool. Grab a spot around the natural gas firepits and enjoy some enlightened conversation. Make plans with your fellow attendees for the next day, or plan your space mission to the moon and back!',

        location: 'EDGE Adult Pool & Terrace',
        sponsors: [
          {
            name: 'Restaurant Brands International'
          }
        ],
        title: 'Starry Night Poolside Campfires'
      }
    ],
    duration: 120
  },
  poolsideCampfiresKaraoke: {
    components: [
      {
        description:
          'Whether you just need a break from the dinner or are looking for deep conversation, stop by the evening campfires located on the upper-level of the Edge Pool. Grab a spot around the natural gas firepits and enjoy some enlightened conversation. Make plans with your fellow attendees for the next day, or plan your space mission to the moon and back!',

        location: 'EDGE Adult Pool & Terrace',
        sponsors: [
          {
            name: 'Restaurant Brands International'
          }
        ],
        title: 'Starry Night Poolside Campfires'
      },
      {
        description:
          "Calling all crooners, aspiring broadway stars, shower singers, wall-flowers, and everyone in between! Come watch the industry's best and brightest sing the night away with our on-site conference Karaoke DJ!",
        location: 'The Parlor',
        sponsors: [],
        title: 'Karaoke.js'
      }
    ],
    duration: 120
  }
}
