import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import { Paragraph } from '../StyledComponents'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :nth-child(2) {
    grid-column: 1 / span 5;
    grid-row: 6 / -1;
  }
  > :nth-child(3) {
    grid-column: 6 / -1;
    grid-row: 6 / -1;
  }
`

const NodeRockets = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="rocketpad.jpg"/>
        <ActivityImage src="liftoff1.jpg"/>
        <ActivityImage src="liftoff2.jpg"/>
      </StyledPhotoGrid>
    )}
    title="NodeRockets"
  >
    <Paragraph>
      3... 2... 1... BLAST OFF!!! Why stop with just Earth? It is time to take
      JavaScript to outer space!
    </Paragraph>
    <Paragraph>
      NodeRockets combines all the power of hobby rockets with all the geekery
      of Node.js. Sign up, flight control, as we take to the sky, terrify anyone
      on the driving range, and break the altitude barrier WITH CODE!
    </Paragraph>
    <Paragraph>
      After learning how to build your rocket tube, you'll get to work on your
      mission payload and re-entry module using JavaScript. Once you've been
      cleared for take off, you'll take the controls of our remote controlled,
      JavaScript-powered launch pad, fuel your rocket with pressurized water,
      and shoot for the moon! Once your mission is complete, your rocket will
      deploy the parachute and return safely to Earth! And if not, well, the
      driving range isn't just for golf balls anymore!
    </Paragraph>
  </Activity>
)

export default NodeRockets
