import EventLinks from './EventLinks'
import InvolvementLinks from './InvolvementLinks'
import PastEventLinks from './PastEventLinks'
import PolicyLinks from './PolicyLinks'
import ProgramLinks from './ProgramLinks'
import React from 'react'
import styled from 'styled-components'

const COPYRIGHT = `Copyright &copy; ${new Date().getFullYear()} JSConf US LLC`

const Container = styled.footer`
  height: max-content;
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.spacer.sm};
  padding-top: ${({ theme }) => theme.spacer.xl};
  position: relative;
  z-index: 1;
  @media ${({ theme }) => theme.devices.tabletMin} {
    padding-top: ${({ theme }) => theme.spacer.xxl};
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.purple900};
  height: 100%;
  position: absolute;
  top: ${({ theme }) => theme.spacer.md};
  transform: skewY(2deg);
  user-selection: none;
  width: 100%;
  z-index: -1;
`

const Nav = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 var(--whitespace);
  text-align: center;
  @media ${({ theme }) => theme.devices.tabletMin} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
`

const Foot = styled.aside`
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.colors.white};
  font-family: Novanta;
  margin-top: ${({ theme }) => theme.spacer.lg};
  padding: 0 var(--whitespace);
  text-align: center;
  text-transform: uppercase;
`

const Footer = () => (
  <Container>
    <Nav>
      <EventLinks/>
      <ProgramLinks/>
      <InvolvementLinks/>
      <PolicyLinks/>
    </Nav>
    <Foot>
      <div
        className="Footer-Legal"
        dangerouslySetInnerHTML={{ __html: COPYRIGHT }}
      />
      <PastEventLinks/>
    </Foot>
    <Background/>
  </Container>
)

export default Footer
