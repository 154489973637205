import { graphql, StaticQuery } from 'gatsby'
import BubbleText from '../BubbleText/BubbleText'
import FullPromo from './FullPromo'
import LinkButton from '../LinkButton/LinkButton'
import MemphisLowerLeft from '../../assets/images/memphis-ll.svg'
import MemphisLowerRight from '../../assets/images/memphis-lr.svg'
import MemphisUpperLeft from '../../assets/images/memphis-ul.svg'
import MemphisUpperRight from '../../assets/images/memphis-ur.svg'
import React from 'react'
import styled from 'styled-components'

const StyledPromo = styled(FullPromo)`
  background-color: ${({ theme }) => theme.colors.yellow300};
  background-image: url(${MemphisLowerLeft}), url(${MemphisLowerRight}),
    url(${MemphisUpperLeft}), url(${MemphisUpperRight});
  background-position: bottom left, bottom right, top left, top right;
  background-size: 45%;
  background-repeat: no-repeat;
  @media ${({ theme }) => theme.devices.tabletMin} {
    background-size: 35%;
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    background-size: 25%;
    p {
      max-width: 40vw;
    }
  }
  @media ${({ theme }) => theme.devices.laptopLargeMin} {
    background-size: 18%;
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    background-size: 10%;
    p {
      ${({ theme }) => theme.typography.heading.xl};
      max-width: 40vw;
    }
  }
`

const Title = styled(BubbleText)`
  ${({ theme }) => theme.typography.heading.xxl};
  text-align: center;
`

const SponsorsPromo = (props) => (
  <StaticQuery
    query={graphql`
      query SponsorsPromoQuery {
        allMdx(
          limit: 6
          filter: { fields: { sourceInstanceName: { eq: "posts" } } }
          sort: { fields: [fields___date], order: DESC }
        ) {
          edges {
            node {
              excerpt
              fields {
                date(formatString: "MMM D, YYYY")
                slug
              }
              frontmatter {
                heading
                title
              }
              id
            }
          }
        }
      }
    `}
    render={({ allMdx: { edges } }) => (
      <StyledPromo
        contentColor="darkBlue900"
        footer={
          <LinkButton
            aria-label="Learn more about sponsoring JSConf US"
            href="/sponsors/"
            secondary
          >
            Learn More
          </LinkButton>
        }
        gridColumn="skinny"
        title={<Title strokeColor="darkBlue500">Sponsorships Available!</Title>}
      >
        We are currently offering sponsorship packages for JSConf US 2019. Let's
        create a unique and meaningful experience together!
      </StyledPromo>
    )}
  />
)

export default SponsorsPromo
