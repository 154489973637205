import { any, string } from 'prop-types'
import { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'

const EXTERNAL_LINK = /^[^:]+:/
const JUMP_TO = /^#/

export const LinkCss = css`
  color: ${({ theme }) => theme.colors.blue900};
  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.blue600};
  }
`

const Link = ({ children, href, primary, secondary, ...props }) => {
  if (!href) {
    return children
  }
  if (EXTERNAL_LINK.test(href)) {
    return (
      <OutboundLink
        css={LinkCss}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        {...props}
      >
        {children}
      </OutboundLink>
    )
  }
  if (JUMP_TO.test(href)) {
    return (
      <a css={LinkCss} href={href}>
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink css={LinkCss} to={href} {...props}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  children: any,
  href: string
}

export default Link
