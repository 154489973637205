import { Link, Paragraph } from '../StyledComponents'
import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :first-child {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :last-child {
    grid-column: 1 / -1;
    grid-row: 6 / -1;
  }
`

const SafariZooAdventure = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="safari-lion.jpg"/>
        <ActivityImage src="safari-park.jpg"/>
      </StyledPhotoGrid>
    )}
    title="Safari Park Adventure"
  >
    <Paragraph>
      The{' '}
      <Link href="http://www.sdzsafaripark.org/">
        San Diego Zoo Safari Park
      </Link>{' '}
      is a one-of-a-kind 2,200 acre wildlife preserve where over 4,000 rare and
      endangered species can be seen in habitats replicating their native
      homelands. Take a ride on the African Tram – it’s the absolute best way to
      see the diverse collection of animals on the African Outpost. Then, take
      your time checking out the extensive individual animal enclosures or catch
      a show! There are so many options to choose from such as the Tiger Keeper
      Talk, Frequent Flyers Bird Show, or the crowd favorite, Shiley’s Cheetah
      Run!
    </Paragraph>
    <Paragraph>
      Transportation to and from the Safari Park will be provided. Companion
      tickets for children and adults will be available for purchase.
    </Paragraph>
  </Activity>
)

export default SafariZooAdventure
