import { css } from 'styled-components'
import FuturaHeavyWoff2 from './Futura-Heavy.woff2'
import FuturaWoff2 from './Futura-Regular.woff2'
import NovantaWoff from './Novanta-Regular.woff'
import NovantaWoff2 from './Novanta-Regular.woff2'
import SolidoCompactBookWoff from './Solido-Compact-Book.woff'
import SolidoCompactBookWoff2 from './Solido-Compact-Book.woff2'
import ThunderstormWoff from './Thunderstorm-Update.woff'
import ThunderstormWoff2 from './Thunderstorm-Update.woff2'

const fontFaces = css`
  @font-face {
    font-display: swap;
    font-family: Futura;
    font-style: normal;
    font-weight: 300;
    src: url('${FuturaWoff2}') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: Futura;
    font-style: normal;
    font-weight: 700;
    src: url('${FuturaHeavyWoff2}') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: Novanta;
    font-style: normal;
    font-weight: 300;
    src: url('${NovantaWoff2}') format('woff2'),
         url('${NovantaWoff}') format('woff');
  }
  @font-face {
    font-display: swap;
    font-family: Solido;
    font-style: normal;
    font-weight: 300;
    src: url('${SolidoCompactBookWoff2}') format('woff2'),
         url('${SolidoCompactBookWoff}') format('woff');
  }
  @font-face {
    font-display: swap;
    font-family: Thunderstorm;
    font-style: normal;
    font-weight: 300;
    src: url('${ThunderstormWoff2}') format('woff2'),
         url('${ThunderstormWoff}') format('woff');
  }
`

export { fontFaces }

export default {
  accent: {
    regular: css`
      font-family: Novanta;
    `
  },
  primary: {
    /* System font https://medium.com/designing-medium/system-shock-6b1dc6d6596f */
    regular: css`
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      font-style: normal;
      font-weight: 400;
    `
  },
  secondary: {
    regular: css`
      font-family: Solido;
    `
  }
}
