import { Headings, Link, Paragraph } from '../StyledComponents'
import GridRow from '../GridRow/GridRow'
import React from 'react'

const CityGuide = () => (
  <GridRow>
    <Headings.H2>City Guide</Headings.H2>
    <Paragraph>
      JSConf US 2019 will be held in sunny Carlsbad, California just outside of
      San Diego.
    </Paragraph>
    <Paragraph>
      Want to learn more about Carlsbad? Check out our{' '}
      <Link href="/about/city-guide">City Guide</Link>!
    </Paragraph>
  </GridRow>
)

export default CityGuide
