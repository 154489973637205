import { absoluteUrl } from '../../utils/urlFilters'
import Helmet from 'react-helmet'
import React from 'react'
import { string } from 'prop-types'

const OpenGraph = ({ description, image, name, title, url }) => {
  const meta = [
    { content: 'website', name: 'og:type' },
    { content: name, name: 'og:site_name' },
    { content: title, name: 'og:title' },
    { content: description, name: 'og:description' },
    { content: absoluteUrl('/icons/icon-96x96.png'), name: 'og:image' },
    { content: url, name: 'og:url' }
  ]
  if (image) {
    meta.push({ content: image, name: 'og:image' })
  }
  return <Helmet meta={meta}/>
}

OpenGraph.propTypes = {
  description: string.isRequired,
  image: string,
  name: string.isRequired,
  title: string.isRequired,
  url: string.isRequired
}

export default OpenGraph
