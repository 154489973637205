import { bool, shape, string } from 'prop-types'
import { absoluteUrl } from '../utils/urlFilters'
import { getNodes } from '../utils/nodeHelpers'
import { graphql } from 'gatsby'
import { Headings } from '../components/StyledComponents'
import { IconsProvider } from '../contexts/Icons'
import { LogosProvider } from '../contexts/Logos'
import MdxTemplate from './MdxTemplate'
import React from 'react'
import { slugify } from '../utils/string'
import Sponsor from '../components/Sponsor/Sponsor'
import styled from 'styled-components'

const CacheBuster = 4

const H2 = styled(Headings.H3)`
  color: ${({ theme }) => theme.colors.pink700};
`

const SponsorHeadings = {
  h2: H2
}

export default function ContentTemplate ({
  children,
  data: {
    iconImages: { edges: iconImages },
    mdx,
    sponsorLogos: { edges: sponsorLogos }
  },
  ...props
}) {
  const { code, frontmatter } = mdx
  let { level } = frontmatter
  if (level === 'conference') {
    level = 'supporter of'
  }
  let metaTitle = `${frontmatter.name} - A ${level} JSConf US`
  if (frontmatter.level !== 'conference') {
    metaTitle += ' sponsor'
  }
  if (frontmatter.component) {
    metaTitle += ` of ${frontmatter.component}`
  }
  const twitterCard = {
    image: absoluteUrl(
      `/sponsors/${slugify(frontmatter.name)}-card.png?${CacheBuster}`
    ),
    type: 'summary_large_image'
  }
  const openGraph = {
    image: absoluteUrl(`/speakers/${slugify(frontmatter.name)}-plate.png`)
  }
  return (
    <LogosProvider value={getNodes(sponsorLogos)}>
      <IconsProvider value={getNodes(iconImages)}>
        <MdxTemplate
          body={code.body}
          components={SponsorHeadings}
          description={frontmatter.description}
          openGraph={openGraph}
          title={metaTitle}
          twitter={twitterCard}
          {...props}
          render={(body) => <Sponsor {...frontmatter}>{body}</Sponsor>}
        />
      </IconsProvider>
    </LogosProvider>
  )
}

MdxTemplate.propTypes = {
  data: shape({
    code: shape({
      body: string.isRequired
    }),
    mdx: shape({
      frontmatter: shape({
        component: string,
        description: string,
        footer: bool,
        level: string,
        logo: string,
        name: string,
        scholarship: bool,
        url: string
      })
    })
  })
}

export const pageQuery = graphql`
  query($id: String!) {
    iconImages: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/icons/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        component
        footer
        description
        level
        logo
        name
        scholarship
        url
      }
      id
      code {
        body
      }
    }
    sponsorLogos: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/sponsors/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fixed(height: 115) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
