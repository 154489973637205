import { arrayOf, func, number, shape, string } from 'prop-types'
import dateformat from 'dateformat'
import repeatedEvents from './repeatedEvents'

const TIME = /(\d{2})(\d{2})/
const MINUTES = 60000

function addTickTime (tick, duration) {
  tick.setTime(tick.getTime() + duration * MINUTES)
  return tick
}

function setTickTime (tick, time) {
  // eslint-disable-next-line no-unused-vars
  const [_, hours, minutes] = String(time)
    .padStart(4, '0')
    .match(TIME)
  tick.setUTCHours(hours)
  tick.setUTCMinutes(minutes)
  return tick
}

function getItem (item) {
  if (!item) {
    return null
  }
  if (item.repeatEvent) {
    return { ...item, ...repeatedEvents[item.repeatEvent] }
  }
  return item
}

const Clock = ({ agenda, renderTick, startAt }) => {
  let tick = new Date(startAt)
  return agenda.map((item, index) => {
    item = getItem(item)
    let showEndTime = false
    let lastItem = false
    const currentTick = new Date(tick)
    if (item.startTime) {
      tick = setTickTime(tick, String(item.startTime))
      item = {
        ...item,
        startTime: dateformat(tick, 'UTC:h:MMTT')
      }
    } else {
      item = { ...item, startTime: dateformat(tick, 'UTC:h:MMTT') }
    }
    if (item.endTime) {
      tick = setTickTime(tick, String(item.endTime))
      item = { ...item, endTime: dateformat(tick, 'UTC:h:MMTT') }
      showEndTime = true
    }
    if (item.duration) {
      tick = addTickTime(tick, item.duration)
      item = { ...item, endTime: dateformat(tick, 'UTC:h:MMTT') }
    }
    const nextItem = getItem(item[index + 1])
    if (
      nextItem &&
      nextItem.startTime &&
      new Date(nextItem.startTime) !== currentTick
    ) {
      showEndTime = true
    }
    if (index === agenda.length - 1) {
      showEndTime = true
      lastItem = true
    }
    return renderTick(item, { lastItem, showEndTime }, `${index}-${item.title}`)
  })
}

Clock.propTypes = {
  agenda: arrayOf(
    shape({
      duration: number,
      endTime: number,
      startTime: number,
      title: string
    })
  ),
  renderTick: func.isRequired,
  startAt: string
}

export default Clock
