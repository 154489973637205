import { node } from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Title = styled.header`
  ${({ theme }) => theme.typography.secondary}
  ${({ theme }) => theme.typography.heading.xxl};
  color: ${({ theme }) => theme.colors.blueA100};
  text-shadow: -2px -2px 0 ${({ theme }) => theme.colors.purple400};
  margin-top: ${({ theme }) => theme.spacer.lg};
  @media ${({ theme }) => theme.devices.tabletMin} {
    ${({ theme }) => theme.typography.heading.lg};
    margin-top: 0;
  }
`

export const List = styled.ul`
  list-style-type: none;
  margin-top: ${({ theme }) => theme.spacer.xs};
  line-height: 1.5;
  @media ${({ theme }) => theme.devices.tabletMin} {
    margin-top: ${({ theme }) => theme.spacer.sm};
  }
`

const LinkGroup = ({ children, title }) => (
  <section>
    <Title>{title}</Title>
    <List>{children}</List>
  </section>
)

LinkGroup.propTypes = {
  children: node.isRequired,
  title: node.isRequired
}

export default LinkGroup
