import { groupBy, pluck } from '../../utils/array'
import { array } from 'prop-types'
import React from 'react'
import SponsorList from './SponsorList'

const SponsorLineup = ({ sponsors }) => {
  const groupedSponsors = groupBy(sponsors, 'level')
  let scholarshipSponsors = groupedSponsors.scholarship || []
  scholarshipSponsors = scholarshipSponsors.concat(
    pluck(sponsors, 'scholarship')
  )
  return (
    <div>
      <SponsorList level="Platinum" sponsors={groupedSponsors.platinum}>
        Our social activities are possible due to the support from our
        Platinum-level sponsors!
      </SponsorList>
      <SponsorList level="Gold" sponsors={groupedSponsors.gold}>
        Not only would we not have any stages for our presenters, but our famous
        "Choose Your Own Adventure Day" would not be a success without our
        Gold-level sponsors!
      </SponsorList>
      <SponsorList level="Silver" sponsors={groupedSponsors.silver}>
        These sponsors ensure that everyone has a way to unwind after a long day
        of amazing talks and amazing people!
      </SponsorList>
      <SponsorList level="Startup" sponsors={groupedSponsors.startup}>
        The support from our Startup Sponsors is much appreciated!
      </SponsorList>
      <SponsorList level="Conference" sponsors={groupedSponsors.conference}>
        A huge thank you to our Conference Support Sponsors for helping to make
        JSConf US a success!
      </SponsorList>
      <SponsorList level="Scholarship" sponsors={scholarshipSponsors}>
        Our amazingly generous Scholarship Sponsors are doing everything they
        can to ensure that our community continues to grow into a diverse,
        inclusive, and welcoming environment!
      </SponsorList>
    </div>
  )
}

SponsorLineup.propTypes = {
  sponsors: array.isRequired
}

export default SponsorLineup
