import { array, string } from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import TeamMember from './TeamMember'
import { UnstyledList } from '../StyledComponents'

const Root = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.sm};
  grid-template-columns: 1fr;
  padding: ${({ theme }) => theme.spacer.md};
  &:last-child {
    padding-bottom: ${({ theme }) => theme.spacer.xxl};
    margin-bottom: calc(${({ theme }) => theme.spacer.xl} * -1);
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-template-columns: max-content 1fr;
    padding: ${({ theme }) => theme.spacer.md} ${({ theme }) => theme.spacer.sm};
  }
`

const TeamMembers = styled(UnstyledList)`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.lg};
  grid-template-columns: 1fr;
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${({ theme }) => theme.devices.laptopLargeMin} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const TeamName = styled.h2`
  color: ${({ theme }) => theme.colors.blue200};
  font-size: 11vw;
  font-family: Futura;
  letter-spacing: 1px;
  overflow: hidden;
  padding-bottom: 1ch; /* clips the bottom otherwise */
  text-align: center;
  -webkit-text-stroke: 1px white;
  text-stroke: 1px white;
  text-shadow: 2px 2px ${({ theme }) => theme.colors.purple300},
    3px 3px ${({ theme }) => theme.colors.purple300},
    4px 4px ${({ theme }) => theme.colors.purple300};
  text-transform: uppercase;
  z-index: 5;
  @media ${({ theme }) => theme.devices.tabletMin} {
    font-size: 80px;
    letter-spacing: 0;
    text-orientation: mixed;
    text-shadow: 2px 2px ${({ theme }) => theme.colors.purple300},
      3px 3px ${({ theme }) => theme.colors.purple300},
      4px 4px ${({ theme }) => theme.colors.purple300},
      5px 5px ${({ theme }) => theme.colors.purple300},
      6px 6px ${({ theme }) => theme.colors.purple300};
    writing-mode: vertical-rl;
  }
`

const TeamList = ({ id, people, title }) => {
  if (!people[0].name) {
    return null
  }
  return (
    <Root id={id}>
      <header>
        <TeamName>{title}</TeamName>
      </header>
      <TeamMembers>
        {people.map((person, index) => (
          <TeamMember key={index} {...person}/>
        ))}
      </TeamMembers>
    </Root>
  )
}

TeamList.propTypes = {
  id: string,
  people: array.isRequired,
  title: string.isRequired
}

export default TeamList
