/**
 * http://mcg.mbitson.com/#!?mcgpalette0=%2300b8f1&mcgpalette1=%2328537c&mcgpalette2=%236ae582&mcgpalette3=%2311073a&mcgpalette4=%23663399&mcgpalette5=%23ec008c&mcgpalette6=%23ffd633
 */

const palette = {
  black25: '#ebf0f5',
  black50: '#E0E3E4',
  black100: '#B3B9BC',
  black200: '#808B8F',
  black300: '#4D5D62',
  black400: '#263A41',
  black500: '#00171F',
  black600: '#00141B',
  black700: '#001117',
  black800: '#000D12',
  black900: '#00070A',
  blackA100: '#4FC4FF',
  blackA200: '#1CB3FF',
  blackA400: '#009BE8',
  blackA700: '#008ACE',
  blue50: '#E0F6FD',
  blue100: '#B3EAFB',
  blue200: '#80DCF8',
  blue300: '#4DCDF5',
  blue400: '#26C3F3',
  blue500: '#00b8f1',
  blue600: '#00B1EF',
  blue700: '#00A8ED',
  blue800: '#00A0EB',
  blue900: '#0091E7',
  blueA100: '#fff',
  blueA200: '#DAF0FF',
  blueA400: '#A7DAFF',
  blueA700: '#8ED0FF',
  blueB700: '#007BC5',
  darkBlue50: '#E5EAEF',
  darkBlue100: '#BFCBD8',
  darkBlue200: '#94A9BE',
  darkBlue300: '#6987A3',
  darkBlue400: '#486D90',
  darkBlue500: '#28537c',
  darkBlue600: '#244C74',
  darkBlue700: '#1E4269',
  darkBlue800: '#18395F',
  darkBlue900: '#0F294C',
  darkBlueA100: '#85B4FF',
  darkBlueA200: '#5295FF',
  darkBlueA400: '#1F76FF',
  darkBlueA700: '#0566FF',
  green50: '#EDFCF0',
  green100: '#D2F7DA',
  green200: '#B5F2C1',
  green300: '#97EDA8',
  green400: '#80E995',
  green500: '#6ae582',
  green600: '#62E27A',
  green700: '#57DE6F',
  green800: '#4DDA65',
  green900: '#3CD352',
  greenA100: '#FFFFFF',
  greenA200: '#EDFFF0',
  greenA400: '#BAFFC4',
  greenA700: '#A1FFAE',
  pink50: '#FCE0F1',
  pink100: '#F7B3DB',
  pink200: '#F280C3',
  pink300: '#EC4DAB',
  pink400: '#E82699',
  pink500: '#E40087',
  pink600: '#E1007F',
  pink700: '#DD0074',
  pink800: '#D9006A',
  pink900: '#D10057',
  pinkA100: '#FFF9FB',
  pinkA200: '#FFC6DA',
  pinkA400: '#FF93B9',
  pinkA700: '#FF7AA8',
  pinkB900: '#A1234A', // a11y AAA with yellow100 on top
  purple50: '#EDE7F3',
  purple100: '#D1C2E0',
  purple200: '#B399CC',
  purple300: '#9470B8',
  purple400: '#7D52A8',
  purple500: 'rebeccapurple',
  purple600: '#5b2d89',
  purple700: '#532786',
  purple800: '#49207C',
  purple900: '#38146B',
  purpleA100: '#C5A2FF',
  purpleA200: '#A56FFF',
  purpleA400: '#843CFF',
  purpleA700: '#7423FF',
  purpleB100: '#6D4893', // a11y AAA on white
  purpleB700: '#C8A7FF', // a11y AAA on purple900
  white: '#fff',
  yellow50: '#FFFAE6',
  yellow100: '#FFF3C2',
  yellow200: '#FFEB99',
  yellow300: '#FFE370',
  yellow400: '#FFDD51',
  yellow500: '#ffd732',
  yellow600: '#FFD32D',
  yellow700: '#FFCD26',
  yellow800: '#FFC71F',
  yellow900: '#FFBE13',
  yellowA100: '#FFFFFF',
  yellowA200: '#FFFEFB',
  yellowA400: '#FFEEC8',
  yellowA700: '#FFE6AE'
}

export default {
  ...palette,
  selectionBg: palette.yellow500,
  selectionText: palette.black600
}
