import { node, string } from 'prop-types'
import GridContainer from '../GridContainer/GridContainer'
import GridRow from '../GridRow/GridRow'
import React from 'react'
import styled from 'styled-components'

const StyledRow = styled(GridRow)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacer.xl};
  padding-top: ${({ theme }) => theme.spacer.xl};
`

const Content = styled.div`
  color: ${({ contentColor, theme }) => theme.colors[contentColor]};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.md};
  grid-template-rows: max-content 1fr max-content;
  text-align: center;
`

const Copy = styled.p`
  color: ${({ color, theme }) => theme.colors[color]};
  justify-self: center;
  margin-bottom: ${({ theme }) => theme.spacer.md};
  margin-top: ${({ theme }) => theme.spacer.sm};
`

const FullPromo = ({
  children,
  className,
  contentColor,
  footer,
  gridColumn,
  title
}) => {
  const footerContent = <footer>{footer}</footer>
  return (
    <StyledRow as="section" className={className} full>
      <GridContainer>
        <GridRow column={gridColumn}>
          <Content>
            {title}
            <Copy color={contentColor}>{children}</Copy>
            {footerContent}
          </Content>
        </GridRow>
      </GridContainer>
    </StyledRow>
  )
}

FullPromo.propTypes = {
  children: node.isRequired,
  className: string,
  contentColor: string.isRequired,
  footer: node,
  gridColumn: string.isRequired,
  title: node.isRequired
}

export default FullPromo
