import { array, object, shape, string } from 'prop-types'
import { AvatarsProvider } from '../contexts/Avatars'
import { getNodes } from '../utils/nodeHelpers'
import GlobalStyle from '../components/GlobalStyle/GlobalStyle'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/tag'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import React from 'react'
import SpeakerCard from '../components/SpeakerCard/SpeakerCard'
import themeConfig from '../theme/theme'
import { ThemeProvider } from 'styled-components'

export default function SpeakerCardTemplate ({
  data: {
    talk,
    speakerImages: { edges: speakerImages },
    ...data
  },
  theme,
  ...props
}) {
  const { code, frontmatter } = talk
  return (
    <AvatarsProvider value={getNodes(speakerImages)}>
      <MDXProvider>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle/>
            <main>
              <SpeakerCard {...frontmatter}>
                <MDXRenderer {...props}>{code.body}</MDXRenderer>
              </SpeakerCard>
            </main>
          </>
        </ThemeProvider>
      </MDXProvider>
    </AvatarsProvider>
  )
}

SpeakerCardTemplate.defaultProps = {
  theme: themeConfig
}

SpeakerCardTemplate.propTypes = {
  data: shape({
    talk: shape({
      code: shape({
        body: string.isRequired
      }),
      frontmatter: shape({
        description: string,
        speakers: array,
        title: string,
        track: string
      })
    })
  }),
  theme: object
}

export const pageQuery = graphql`
  query($id: String!) {
    talk: mdx(id: { eq: $id }) {
      frontmatter {
        description
        speakers {
          name
        }
        title
        track
        visible
      }
      id
      code {
        body
      }
    }
    speakerImages: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/speakers/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
