import {
  Activities,
  CityGuide,
  FAQ,
  OurTeam,
  Tickets,
  Venue
} from '../components/About'
import { Headings, Link, Paragraph } from '../components/StyledComponents'
import DefaultLayout from '../layouts/DefaultLayout'
import GridContainer from '../components/GridContainer/GridContainer'
import GridRow from '../components/GridRow/GridRow'
import React from 'react'

const AboutPage = () => (
  <DefaultLayout
    description="Everything you need to know about what makes JSConf US special!"
    title="About JSConf"
  >
    <GridContainer>
      <GridRow>
        <Headings.H1>About</Headings.H1>
        <Paragraph>
          JSConf US has pushed the language outside of its comfort zone, the web
          browser, and into the forefront of servers,{' '}
          <Link href="http://nodecopter.com/">drones</Link>,{' '}
          <Link href="http://nodebots.io/">robots</Link> and{' '}
          <Link href="http://www.youtube.com/watch?v=O83-d0t0Ldw">
            video games
          </Link>
          . Enjoy a very special day of exciting activities that are sure to
          make anyone happy from golfing to robot hacking to segway tours during
          our "choose-your-own-adventure" activity day only at JSConf US.
        </Paragraph>
      </GridRow>
      <Activities/>
      <CityGuide/>
      <Tickets/>
      {/* <Venue/> */}
      <OurTeam/>
      <FAQ/>
    </GridContainer>
  </DefaultLayout>
)

export default AboutPage
