/* eslint-disable no-confusing-arrow, no-ternary */
import styled from 'styled-components'

/**
 * Ideally, I'd like to use:
 *
 *    paint-order: stroke fill;
 *    text-shadow: 0 calc(var(--stroke) * 2.5) 0 black;
 *    -webkit-text-stroke: calc(var(--stroke) * 2.5) black;
 *
 * But `paint-order` passes the `@supports` test despite Chrome not actually
 * doing anything whereas Firefox and Safari (desipite caniuse.com saying it
 * is not supported) works fine.
 *
 * Since I cannot reliably target Chrome only without falling back to UA
 * sniffing with an 8-year old library, we'll just cheat.
 */

const dropShadowStyle = `0 var(--shadow) var(--stroke-color),
 var(--shadow-neg-half) var(--shadow) var(--stroke-color),
 var(--shadow-half) var(--shadow) var(--stroke-color)`

const textStrokeStyle = `0 var(--stroke-neg) var(--stroke-color),
 var(--stroke-neg-half) var(--stroke-neg) var(--stroke-color),
 var(--stroke-neg) 0 var(--stroke-color),
 var(--stroke-neg) var(--stroke-neg-half) var(--stroke-color),
 var(--stroke-neg) var(--stroke-neg) var(--stroke-color),
 0 var(--stroke) var(--stroke-color),
 var(--stroke-half) var(--stroke) var(--stroke-color),
 var(--stroke) 0 var(--stroke-color),
 var(--stroke) var(--stroke-half) var(--stroke-color),
 var(--stroke) var(--stroke) var(--stroke-color),
 var(--stroke) var(--stroke-neg) var(--stroke-color),
 var(--stroke-neg) var(--stroke) var(--stroke-color)`

const BubbleText = styled.div.attrs(
  ({
    strokeColor = 'black500',
    dropShadow = true,
    strokeWidth = 2,
    ...props
  }) => ({
    dropShadow,
    strokeColor,
    strokeWidth,
    ...props
  })
)`
  ${({ theme }) => theme.typography.accent}
  --stroke: ${({ strokeWidth }) => strokeWidth / 2}px;
  ${({ theme }) => theme.media.sm} {
    --stroke: ${({ strokeWidth }) => strokeWidth}px;
  }
  --stroke-color: ${({ strokeColor, theme }) => theme.colors[strokeColor]};
  --stroke-neg: calc(var(--stroke) * -1);
  --stroke-half: calc(var(--stroke) / 2);
  --stroke-neg-half: calc((var(--stroke) / 2) * -1);
  --shadow: calc(var(--stroke) * 3);
  --shadow-half: calc(var(--shadow) / 2);
  --shadow-neg-half: calc((var(--shadow) / 2) * -1);
  line-height: 1;
  color: white;
  text-shadow: ${({ dropShadow }) =>
    dropShadow ? `${textStrokeStyle}, ${dropShadowStyle}` : textStrokeStyle};
  text-transform: lowercase;
`

export default BubbleText
