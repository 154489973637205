import React, { memo } from 'react'
import BubbleText from '../BubbleText/BubbleText'
import styled from 'styled-components'
import { StyledLink } from '../StyledComponents'

const Copy = styled.div`
  grid-area: copy;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.75;
  ${StyledLink} {
    color: ${({ theme }) => theme.colors.green500};
    transition: color 0.2s;
    &:hover {
      color: ${({ theme }) => theme.colors.yellow500};
    }
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    justify-self: center;
    ${({ theme }) => theme.typography.heading.lg};
    max-width: 25em;
  }
`

const StyledBubbleText = styled(BubbleText)`
  align-self: flex-end;
  ${({ theme }) => theme.typography.heading.xxxxl};
  max-width: 7.9em;
  text-align: center;
  @media ${({ theme }) => theme.devices.tabletMin} {
    ${({ theme }) => theme.typography.heading.xl};
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    ${({ theme }) => theme.typography.heading.xl};
  }
  @media ${({ theme }) => theme.devices.laptopLargeMin} {
    ${({ theme }) => theme.typography.heading.xxl};
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    ${({ theme }) => theme.typography.heading.xxxxl};
  }
`

const HeroCopy = memo(() => (
  <>
    <StyledBubbleText>Two days of talks, one day of adventure</StyledBubbleText>
    <Copy>
      JSConf US is the only conference where you can learn how to push your
      favorite language beyond the confines of the browser and into{' '}
      <StyledLink href="http://nodebots.io/">robots</StyledLink>, and{' '}
      <StyledLink href="http://www.youtube.com/watch?v=O83-d0t0Ldw">
        video games
      </StyledLink>
      .
    </Copy>
  </>
))

export default HeroCopy
