// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-team-card-template-js": () => import("/opt/build/repo/src/templates/TeamCardTemplate.js" /* webpackChunkName: "component---src-templates-team-card-template-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/d834ff04a67aa6e40e7abcaddb36ffc9--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-3127-cf-38580-f-286-eb-059-eba-7-a-76-d-5-bbd-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/d834ff04a67aa6e40e7abcaddb36ffc9--scope-hash-3127cf38580f286eb059eba7a76d5bbd.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-3127-cf-38580-f-286-eb-059-eba-7-a-76-d-5-bbd-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-dd-1-e-0-c-4-e-67-ec-49-e-4-f-5-f-2617082-ceb-075-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/dd1e0c4e67ec49e4f5f2617082ceb075--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-dd-1-e-0-c-4-e-67-ec-49-e-4-f-5-f-2617082-ceb-075-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-0-c-7-be-8-ed-323037-ca-809-cdb-17198-d-9720-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/0c7be8ed323037ca809cdb17198d9720--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-0-c-7-be-8-ed-323037-ca-809-cdb-17198-d-9720-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-dd-1-e-0-c-4-e-67-ec-49-e-4-f-5-f-2617082-ceb-075-scope-hash-5-ad-43257480922-f-8-ba-62-c-7-f-6-d-81-dffcf-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/dd1e0c4e67ec49e4f5f2617082ceb075--scope-hash-5ad43257480922f8ba62c7f6d81dffcf.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-dd-1-e-0-c-4-e-67-ec-49-e-4-f-5-f-2617082-ceb-075-scope-hash-5-ad-43257480922-f-8-ba-62-c-7-f-6-d-81-dffcf-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-0-c-7-be-8-ed-323037-ca-809-cdb-17198-d-9720-scope-hash-5-ad-43257480922-f-8-ba-62-c-7-f-6-d-81-dffcf-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/0c7be8ed323037ca809cdb17198d9720--scope-hash-5ad43257480922f8ba62c7f6d81dffcf.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-0-c-7-be-8-ed-323037-ca-809-cdb-17198-d-9720-scope-hash-5-ad-43257480922-f-8-ba-62-c-7-f-6-d-81-dffcf-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-98398-aaee-9-f-91-f-8-e-4916-a-22-e-8-cefc-0-cf-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/98398aaee9f91f8e4916a22e8cefc0cf--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-98398-aaee-9-f-91-f-8-e-4916-a-22-e-8-cefc-0-cf-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-20-c-111-f-82975628-d-2-ee-9-dcafa-1-f-85704-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/20c111f82975628d2ee9dcafa1f85704--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-20-c-111-f-82975628-d-2-ee-9-dcafa-1-f-85704-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-ae-9821-c-29-ee-6-dcb-3-f-675-b-2-c-705581261-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/d834ff04a67aa6e40e7abcaddb36ffc9--scope-hash-ae9821c29ee6dcb3f675b2c705581261.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-ae-9821-c-29-ee-6-dcb-3-f-675-b-2-c-705581261-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-5-fccc-3093559-a-628-cce-151-d-87317-bc-90-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/d834ff04a67aa6e40e7abcaddb36ffc9--scope-hash-5fccc3093559a628cce151d87317bc90.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-5-fccc-3093559-a-628-cce-151-d-87317-bc-90-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-32-a-3-bf-00-e-923-a-69637-fb-793004562686-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/d834ff04a67aa6e40e7abcaddb36ffc9--scope-hash-32a3bf00e923a69637fb793004562686.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-d-834-ff-04-a-67-aa-6-e-40-e-7-abcaddb-36-ffc-9-scope-hash-32-a-3-bf-00-e-923-a-69637-fb-793004562686-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-activities-js": () => import("/opt/build/repo/src/pages/about/activities.js" /* webpackChunkName: "component---src-pages-about-activities-js" */),
  "component---src-pages-about-team-js": () => import("/opt/build/repo/src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/opt/build/repo/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-socialmedia-twitter-background-js": () => import("/opt/build/repo/src/pages/socialmedia/twitter-background.js" /* webpackChunkName: "component---src-pages-socialmedia-twitter-background-js" */),
  "component---src-pages-speakers-js": () => import("/opt/build/repo/src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-sponsors-js": () => import("/opt/build/repo/src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

