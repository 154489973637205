const siteUrl = `https://2019.jsconf.us`

module.exports = {
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      options: {
        name: `content`,
        path: `${__dirname}/content`
      },
      resolve: `gatsby-source-filesystem`
    },
    {
      options: {
        name: `data`,
        path: `${__dirname}/data`
      },
      resolve: `gatsby-source-filesystem`
    },
    {
      options: {
        name: `posts`,
        path: `${__dirname}/posts`
      },
      resolve: `gatsby-source-filesystem`
    },
    {
      options: {
        extensions: ['.mdx', '.md']
      },
      resolve: `gatsby-mdx`
    },
    {
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`
      },
      resolve: `gatsby-source-filesystem`
    },
    'gatsby-transformer-json',
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    'gatsby-plugin-styled-components',
    {
      options: {
        background_color: `#f0db4f`,
        display: 'standalone',
        icon: 'src/assets/favicons/favicon-source.png',
        name: `JSConf US`,
        short_name: `JSConf US`,
        start_url: '/',
        theme_color: `#f0db4f`
      },
      resolve: `gatsby-plugin-manifest`
    },
    {
      options: {
        anonymize: true,
        cookieDomain: 'jsconf.us',
        respectDNT: true,
        trackingId: 'UA-130932566-1'
      },
      resolve: `gatsby-plugin-google-analytics`
    },
    `gatsby-plugin-offline`,
    {
      options: {
        siteUrl
      },
      resolve: `gatsby-plugin-canonical-urls`
    },
    {
      options: {
        exclude: ['/socialmedia/*']
      },
      resolve: `gatsby-plugin-sitemap`
    },
    'gatsby-plugin-zopfli',
    'gatsby-plugin-netlify-cache',
    'gatsby-plugin-netlify'
  ],
  siteMetadata: {
    baseUrl: '',
    cfpUrl: 'https://goo.gl/forms/4DpEVvoPX1hxY5Vv1',
    description: `JSConf US 2019 | Carlsbad, CA: Aug. 12 - Aug. 14`,
    siteUrl,
    sponsorFormUrl: `https://goo.gl/forms/nCD1Fi3hS4fq4RNL2`,
    ticketChildcare: `https://ti.to/jsconf-us/jsconf-us-2019/with/t6ak1yn4-nq`,
    title: `JSConf US 2019`,
    titoUrl: `https://ti.to/jsconf-us/jsconf-us-2019`
  }
}
