/* eslint-disable no-confusing-arrow, no-ternary */
import { arrayOf, bool, object, string } from 'prop-types'
import React from 'react'
import Session from './Session'
import styled from 'styled-components'

const Timing = styled.aside`
  color: white;
  grid-column: 1 / -1;
  font-family: Futura;
  ${({ theme }) => theme.typography.heading.sm};
  margin-top: ${({ theme }) => theme.spacer.md};
  text-transform: lowercase;
  @media ${({ theme }) => theme.devices.tabletMin} {
    ${({ theme }) => theme.typography.heading.xsm};
    display: grid;
    grid-column: 1;
    grid-template-rows: max-content 1fr;
    text-align: right;
    margin-top: 0;
  }
`

const StartTime = styled.span`
  @media ${({ theme }) => theme.devices.tabletMin} {
    display: block;
  }
`

const EndTime = styled.span`
  align-self: ${({ lastItem }) => (lastItem ? 'flex-end' : 'flex-start')};
  display: ${({ hidden }) => (hidden ? 'none' : 'inline')};
  width: max-content;
  &::before {
    content: ' - ';
  }
  @media ${({ theme }) => theme.devices.mobileLargeMax} {
    ${({ theme }) => theme.typography.bodySmall};
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
    ${({ lastItem, theme }) => !lastItem && theme.typography.bodySmall};
    justify-self: flex-end;
    &::before {
      ${({ theme }) => theme.typography.bodySmall};
      content: '${({ lastItem }) => (lastItem ? '' : ' to ')}';
      display: block;
      text-align: center;
    }
  }
`

const Agenda = ({
  components,
  endTime,
  lastItem,
  showEndTime,
  startTime,
  location,
  ...props
}) => {
  if (!components) {
    components = [props]
  }
  return (
    <>
      <Timing>
        <StartTime>{startTime}</StartTime>
        <EndTime hidden={!showEndTime} lastItem={lastItem}>
          {endTime}
        </EndTime>
      </Timing>
      {components.map((compProps, key) => (
        <Session
          data-count={components.length}
          data-index={key}
          key={key}
          location={location}
          {...compProps}
        />
      ))}
    </>
  )
}

Agenda.propTypes = {
  components: arrayOf(object),
  contentTag: string,
  endTime: string.isRequired,
  lastItem: bool.isRequired,
  showEndTime: bool.isRequired,
  startTime: string.isRequired
}

export default Agenda
