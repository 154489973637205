import { css } from 'styled-components'
import fonts from './fonts'
import fontSizes from './fontSizes'

export default {
  accent: css`
    ${fonts.accent.regular}
  `,
  body: css`
    ${fonts.primary.regular}
    line-height: 1.5;
  `,
  bodySmall: css`
    ${fonts.primary.regular}
    ${fontSizes.size0};
    line-height: 1.75;
  `,
  heading: {
    lg: css`
      ${fontSizes.size4};
      line-height: 1.3;
    `,
    md: css`
      ${fontSizes.size3};
      line-height: 1.3;
    `,
    sm: css`
      ${fontSizes.size2};
      line-height: 1.3;
    `,
    xl: css`
      ${fontSizes.size5};
      line-height: 1.2;
    `,
    xsm: css`
      ${fontSizes.size1};
      line-height: 1.3;
    `,
    xxl: css`
      ${fontSizes.size6};
      line-height: 1.2;
    `,
    xxsm: css`
      ${fontSizes.size0};
      line-height: 1.4;
    `,
    xxxl: css`
      ${fontSizes.size7};
      line-height: 1.2;
    `,
    xxxxl: css`
      ${fontSizes.size8};
      line-height: 1.2;
    `
  },
  secondary: css`
    ${fonts.secondary.regular}
    text-transform: uppercase;
  `
}
