const fontCapHeight = 0.9 // Cap-height of the base0 font size
const sizeModifier = fontCapHeight * 10 // used to convert to px in the end

// Calculate individual spacer heights/widths
const spacerNone = 0
const spacer2xs = Math.round(sizeModifier * 0.5) // 5px
const spacerXs = sizeModifier // 9px
const spacerSm = Math.round(sizeModifier * 1.5) // 14px
const spacerMd = Math.round(sizeModifier * 2.5) // 23px
const spacerLg = Math.round(sizeModifier * 4) // 36px
const spacerXl = Math.round(sizeModifier * 6.5) // 59px
const spacer2xl = Math.round(sizeModifier * 10.5) // 95px

export default {
  lg: `${spacerLg}px`,
  md: `${spacerMd}px`,
  none: `${spacerNone}px`,
  sm: `${spacerSm}px`,
  xl: `${spacerXl}px`,
  xs: `${spacerXs}px`,
  xxl: `${spacer2xl}px`,
  xxs: `${spacer2xs}px`
}
