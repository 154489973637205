import React, { memo } from 'react'
import Helmet from 'react-helmet'

const GoogleSearch = memo(() => (
  <Helmet
    meta={[
      {
        content: 'GLENwPD93_SEwwtLyWmAFuLfBx24dGh89iID_9qWMkA',
        name: 'google-site-verification'
      }
    ]}
  />
))

export default GoogleSearch
