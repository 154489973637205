import { func, string } from 'prop-types'
import React, { createContext } from 'react'
import { slugify } from '../utils/string'

const AvatarsContext = createContext([])
const AvatarsProvider = AvatarsContext.Provider

function findAvatar (imageNode, name) {
  const filename = slugify(name).toLowerCase()
  const avatarPath = new RegExp(`${filename}\\.\\w{3,4}$`)
  return avatarPath.test(imageNode.base)
}

const AvatarsConsumer = ({ children, name }) => (
  <AvatarsContext.Consumer>
    {(imageNodes = []) => {
      const speakerImage = imageNodes.find((imageNode) =>
        findAvatar(imageNode, name)
      )
      return children({ speakerImage })
    }}
  </AvatarsContext.Consumer>
)

AvatarsConsumer.propTypes = {
  children: func.isRequired,
  name: string
}

export { AvatarsConsumer, AvatarsProvider }
