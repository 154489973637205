import { array, node, string } from 'prop-types'
import { Headings, Paragraph } from '../StyledComponents'
import GridContainer from '../GridContainer/GridContainer'
import GridRow from '../GridRow/GridRow'
import React from 'react'
import SponsorItem from './SponsorItem'
import styled from 'styled-components'

const LogoList = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  margin-bottom: ${({ theme }) => theme.spacer.xl};
`

const StyledSponsorItem = styled(SponsorItem)`
  padding-left: ${({ theme }) => theme.spacer.sm};
  padding-right: ${({ theme }) => theme.spacer.sm};
  width: 50%;
  @media ${({ theme }) => theme.devices.tabletMin} {
    width: 33%;
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    width: 25%;
  }
`

const SponsorLineup = ({ children, level, sponsors }) => {
  if (!sponsors || !sponsors.length) {
    return null
  }
  return (
    <GridContainer>
      <GridRow>
        <Headings.H3>{level} Sponsors</Headings.H3>
        <Paragraph>{children}</Paragraph>
        <LogoList>
          {sponsors.map(
            ({ id, frontmatter: { logo, name }, fields: { slug } }) => (
              <StyledSponsorItem key={id} logo={logo} name={name} slug={slug}/>
            )
          )}
        </LogoList>
      </GridRow>
    </GridContainer>
  )
}

SponsorLineup.propTypes = {
  children: node,
  level: string.isRequired,
  sponsors: array
}

export default SponsorLineup
