import { array, object, string } from 'prop-types'
import Headshot from '../Headshot/Headshot'
import React from 'react'
import styled from 'styled-components'

const LAST_NAME = /\W(.+$)/

const Title = styled.header`
  margin: 0 auto;
  width: max-content;
`

const FirstName = styled.div`
  color: ${({ theme }) => theme.colors.yellow300};
  font-family: Thunderstorm;
  font-size: 17vw;
  letter-spacing: 1px;
  margin-bottom: -20vw;
  margin-left: 5vw;
  position: relative;
  text-shadow: -1px -1px black, 1px -1px black, -1px 1px black, 2px 2px black,
    3px 3px black;
  transform-origin: bottom left;
  transform: rotate(-10deg);
  z-index: 2;
  @media ${({ theme }) => theme.devices.tabletMin} {
    font-size: 7vw;
    margin-bottom: -8vw;
    margin-left: 2vw;
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    font-size: 4vw;
    margin-bottom: -5vw;
    margin-left: 1vw;
  }
`

const LastName = styled.div`
  ${({ theme }) => theme.typography.secondary};
  color: transparent;
  font-size: 28vw;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 1.5px;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  @media ${({ theme }) => theme.devices.tabletMin} {
    font-size: 12vw;
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    font-size: 6vw;
  }
  &:before {
    content: attr(data-shadow);
    display: inline-block;
    left: 0;
    position: absolute;
    text-shadow: 2px 2px ${({ theme }) => theme.colors.purple600},
      3px 3px ${({ theme }) => theme.colors.purple600},
      5px 5px ${({ theme }) => theme.colors.pink200},
      6px 6px ${({ theme }) => theme.colors.pink200},
      7px 7px ${({ theme }) => theme.colors.purple200},
      8px 8px ${({ theme }) => theme.colors.purple200};
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
  }
  &:after {
    background-clip: text;
    -webkit-background-clip: text;
    background-color: ${({ theme }) => theme.colors.green300};
    background-image: linear-gradient(
      ${({ theme }) => theme.colors.blue400},
      ${({ theme }) => theme.colors.green500}
    );
    color: transparent;
    content: attr(data-shadow);
    display: inline-block;
    left: 0;
    padding-right: 1ch;
    position: absolute;
    z-index: 2;
  }
`

const Avatar = styled.div`
  margin: -18vw auto 0;
  position: relative;
  z-index: 3;
  @media ${({ theme }) => theme.devices.tabletMin} {
    margin-top: -8vw;
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    margin-top: -4vw;
  }
`

const AvatarFrame = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.blue200},
    ${({ theme }) => theme.colors.yellow200},
    ${({ theme }) => theme.colors.pink200}
  );
  border: solid 3px ${({ theme }) => theme.colors.purple200};
  bottom: 0;
  box-shadow: inset 0 0 0px 10px white;
  position: absolute;
  top: 13%;
  width: 100%;
  z-index: -1;
`

const StyledHeadshot = styled(Headshot)`
  img {
    filter: brightness(1.5) contrast(1.1) grayscale(100%);
    transition: filter 1.5s ease-in-out;
    padding: 13px;
  }
  &:hover {
    img {
      filter: brightness(1) contrast(1) grayscale(0%);
    }
  }
`

const Details = styled.footer`
  color: ${({ theme }) => theme.colors.purple900};
  font-family: Futura;
  margin-top: ${({ theme }) => theme.spacer.xs};
  text-align: center;
`

const TeamMember = ({ company, name, roles }) => {
  const [firstName, lastName] = name.split(LAST_NAME)
  const details = roles.join(', ') || company
  return (
    <li>
      <Title>
        <FirstName>{firstName}</FirstName>{' '}
        <LastName data-shadow={lastName}>{lastName}</LastName>
      </Title>
      <Avatar>
        <StyledHeadshot name={name}/>
        <AvatarFrame/>
      </Avatar>
      <Details>{details}</Details>
    </li>
  )
}

TeamMember.defaultProps = {
  roles: []
}

TeamMember.propTypes = {
  avatar: object,
  company: string,
  name: string.isRequired,
  roles: array
}

export default TeamMember
