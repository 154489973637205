import { Headings, Link } from '../StyledComponents'
import DiagonalsLowerRight from '../../assets/images/diag-lr.svg'
import FullPromo from './FullPromo'
import GridSlantLeft from '../../assets/images/grid-slant-left.svg'
import React from 'react'
import styled from 'styled-components'

const StyledPromo = styled(FullPromo)`
  background-blend-mode: normal, hue;
  background-image: url(${GridSlantLeft}), url(${DiagonalsLowerRight}),
    linear-gradient(
      ${({ theme }) => theme.colors.blue100},
      ${({ theme }) => theme.colors.green200}
    );
  background-position: top left, bottom right, center center;
  background-repeat: no-repeat;
  p {
    background-color: ${({ theme }) => theme.colors.greenA200};
    padding: ${({ theme }) => theme.spacer.md};
  }
`

const Header = styled(Headings.H3)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-shadow: -2px -2px ${({ theme }) => theme.colors.blue600};
  ${({ theme }) => theme.typography.heading.xl};
`

const CodeOfConduct = (props) => (
  <StyledPromo
    contentColor="darkBlue900"
    gridColumn="narrow"
    title={<Header>Code of Conduct</Header>}
  >
    JSConf was one of the first conferences to implement and enforce a
    conference Code of Conduct. This year is no different. We expect, and
    require, all organizers, sponsors, speakers, volunteers, delegates, and
    attendees of all levels to adhere to our{' '}
    <Link href="/code-of-conduct/">Code of Conduct</Link>.
  </StyledPromo>
)

export default CodeOfConduct
