import React, { Component } from 'react'
import { number } from 'prop-types'
import pattern from './pattern'
import SvgBackground from '../SvgBackground/SvgBackground'

const DECREMENT = 'DEC'
const INCREMENT = 'INC'
const MAX = 5.2
const MIN = -2.5

export default class NewsBackground extends Component {
  static defaultProps = {
    stepValue: 0.375
  }
  static propTypes = {
    stepValue: number
  }
  state = {
    curve: MAX
  }
  componentDidMount () {
    if (!window) {
      return
    }
    this.animate = true
    window.requestAnimationFrame(this.oscillate)
    this.motionQuery.addListener(this.handleReduceMotionChanged)
    this.handleReduceMotionChanged()
  }
  componentWillUnmount () {
    this.animate = false
    this.motionQuery.removeListener(this.handleReduceMotionChanged)
  }
  animate = false
  direction = DECREMENT
  handleReduceMotionChanged = () => {
    this.animate = !this.motionQuery.matches
  }
  motionQuery =
    typeof window !== 'undefined' &&
    window.matchMedia &&
    matchMedia('(prefers-reduced-motion)')
  oscillate = () => {
    if (!this.animate) {
      return
    }
    if (this.state.curve >= MAX) {
      this.direction = DECREMENT
    } else if (this.state.curve <= MIN) {
      this.direction = INCREMENT
    }
    let curve = this.state.curve
    if (this.direction === INCREMENT) {
      curve = curve + this.props.stepValue
    } else {
      curve = curve - this.props.stepValue
    }
    this.setState(
      (state) => ({
        ...state,
        curve
      }),
      () => {
        window.requestAnimationFrame(this.oscillate)
      }
    )
  }
  render () {
    return (
      <SvgBackground
        bgColor="pink500"
        name="news"
        pattern={pattern}
        stroke="pink200"
      >
        <path
          d={`M1.2 1.3c${this.state.curve} 3.8 3 8.5 3 8.5`}
          origd="M1.2 1.3c5.2 3.8 3 8.5 3 8.5"
          strokeLinecap="round"
          strokeWidth="2.5px"
        />
      </SvgBackground>
    )
  }
}
