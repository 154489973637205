import { bool, string } from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { LogosConsumer } from '../../contexts/Logos'
import React from 'react'

const CardStyles = createGlobalStyle`
  html, body {
    overflow: hidden;
    height: 628px;
    width: 1200px;
  }
`

const Canvas = styled.div`
  display: grid;
  height: 628px;
  overflow: hidden;
  padding: 20px 40px;
  width: 1200px;
`

const Img = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const SponsorCard = ({ level, name, scholarship }) => (
  <Canvas>
    <CardStyles/>
    <LogosConsumer key={name} name={name}>
      {({ logoImage, subPath }) => {
        let src = logoImage.publicURL
        if (logoImage.childImageSharp) {
          src = logoImage.childImageSharp.fixed.src
        }
        return <Img alt="" src={src}/>
      }}
    </LogosConsumer>
  </Canvas>
)

SponsorCard.defaultProps = {
  scholarship: false
}

SponsorCard.propTypes = {
  level: string,
  name: string,
  scholarship: bool
}

export default SponsorCard
