import { func, object, string } from 'prop-types'
import DefaultLayout from '../layouts/DefaultLayout'
import { MDXProvider } from '@mdx-js/tag'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import React from 'react'
import styled from 'styled-components'
import StyledComponents from '../components/StyledComponents'

const BaseComponents = {
  ...StyledComponents
}

const MdxContent = styled.article`
  height: 100%;
  margin-left: var(--whitespace);
  margin-right: var(--whitespace);
`

export default function MdxTemplate ({
  body,
  components,
  description,
  openGraph,
  render,
  title,
  twitter,
  ...props
}) {
  components = {
    ...BaseComponents,
    ...components
  }
  return (
    <DefaultLayout
      description={description}
      openGraph={openGraph}
      title={title}
      twitter={twitter}
    >
      <MDXProvider components={components}>
        <MdxContent>
          {render(<MDXRenderer {...props}>{body}</MDXRenderer>)}
        </MdxContent>
      </MDXProvider>
    </DefaultLayout>
  )
}

MdxTemplate.staticProps = {
  components: {}
}

MdxTemplate.propTypes = {
  body: string.isRequired,
  components: object,
  description (props, propName, componentName) {
    if (props[propName] && props[propName].length > 160) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to` +
          ` \`${componentName}\`. Validation failed (length: ${
            props[propName].length
          }).`
      )
    }
    return null
  },
  openGraph: object,
  render: func.isRequired,
  title: string,
  twitter: object
}
