import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { string } from 'prop-types'

const ANIMATION_DURATION = 3500

const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`

const Animate = css`
  animation-name: ${fadeInOut};
`

const Root = styled.span`
  animation-duration: ${ANIMATION_DURATION / 1000}s;
  animation-fill-mode: both;
  background: ${({ theme }) => theme.colors.yellow500};
  opacity: 0;
  font-weight: bold;
  margin-left: 0.5ch;
  ${({ animated }) => animated && Animate}
`

const RotatingText = ({ children }) => {
  const textNodes = children.split(',')
  const [nodeIndex, setNodeIndex] = useState(0)
  const [animated, setAnimated] = useState(false)
  const [currentTextNode, setCurrentNode] = useState(textNodes[nodeIndex])
  let animationTimer = null
  function nextNode () {
    let nextIndex = nodeIndex + 1
    if (nextIndex >= textNodes.length) {
      nextIndex = 0
    }
    setNodeIndex(nextIndex)
    setCurrentNode(textNodes[nextIndex].trim())
  }
  function transitionWord () {
    setAnimated(false)
    nextNode()
  }
  useEffect(
    () => {
      setAnimated(true)
      animationTimer = setTimeout(transitionWord, ANIMATION_DURATION)
      return () => clearTimeout(animationTimer)
    },
    [children, currentTextNode]
  )
  return <Root animated={animated}>{currentTextNode}</Root>
}

RotatingText.propTypes = {
  children: string.isRequired
}

export default RotatingText
