/* eslint-disable sort-imports, no-unused-vars */
import { arrayOf, shape, string } from 'prop-types'
import Headshot from '../Headshot/Headshot'
import React from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'

import stripes from '../../assets/images/stripes.svg'
import jsconf from '../../assets/images/logo.svg'
import clipPath from '../../assets/images/abstract/triangle-clip-path.svg'
import triangle from '../../assets/images/abstract/triangle.svg'
import wavyLine from '../../assets/images/abstract/wavy-line.svg'

const HEADSHOT = 50
const SPHERES = 1

const CardStyles = createGlobalStyle`
  html, body {
    overflow: hidden;
    height: 628px;
    width: 1200px;
  }
`

const Sphere = css`
  background-image: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.pink900},
    ${({ theme }) => theme.colors.purple900} 90%
  );
  opacity: 0.6;
  border-radius: 100%;
  content: '';
  color: white;
  display: block;
  overflow: hidden;
  position: absolute;
  z-index: ${SPHERES};
}
`

const Canvas = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.pink500},
    ${({ theme }) => theme.colors.purple500}
  );
  height: 628px;
  overflow: hidden;
  position: relative;
  width: 1200px;
  &:before,
  &:after {
    ${Sphere}
  }
  &:after {
    bottom: 150px;
    height: 100px;
    left: 10px;
    width: 100px;
  }
  &:before {
    bottom: 50px;
    height: 200px;
    left: 50px;
    width: 200px;
  }
`

const Profile = styled.div`
  position: absolute;
  height: 100%;
  left: 10px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 510px;
  &:before {
    ${Sphere}
  }
  &:before {
    height: 100px;
    right: 105px;
    top: 10px;
    width: 100px;
  }
`

const Frame = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.purpleB100},
    #d6216f
  );
  align-items: flex-end;
  display: flex;
  height: 315px;
  left: 50px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  width: 310px;
`

const ProfileBackground = styled.div`
  background-image: url(${wavyLine});
  background-size: 100% 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50px;
  z-index: ${SPHERES + 1};
`

const StyledHeadshot = styled(Headshot)`
  filter: drop-shadow(10px 0 0 yellow);
  z-index: ${SPHERES + 1};
  padding-top: 0;
`

const Accents = styled.svg`
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-selection: none;
  width: 100%;
  z-index: ${HEADSHOT + 1};
  polygon {
    &:nth-child(1) {
      fill: ${({ theme }) => theme.colors.blue500};
      transform: rotate(45deg) scale(2, 2);
      transform-origin: 3% 17%;
    }
    &:nth-child(2) {
      fill: ${({ theme }) => theme.colors.yellow500};
      transform: rotate(150deg) scale(1.7);
      transform-origin: 25% 19%;
    }
    &:nth-child(3) {
      fill: ${({ theme }) => theme.colors.blue500};
      transform: rotate(180deg) scale(1.6);
      transform-origin: 30% 17%;
    }
    &:nth-child(4) {
      fill: ${({ theme }) => theme.colors.white};
      transform: rotate(227deg) scale(1.6);
      transform-origin: 37% 11%;
    }
    &:nth-child(5) {
      fill: ${({ theme }) => theme.colors.yellow500};
      transform: rotate(240deg) scale(1.75);
      transform-origin: 37% 11%;
    }
    &:nth-child(6) {
      fill: ${({ theme }) => theme.colors.blue500};
      transform: rotate(313deg) scale(1.5);
      transform-origin: 52% 4%;
    }
    &:nth-child(7) {
      fill: ${({ theme }) => theme.colors.white};
      transform: rotate(330deg) scale(1.6);
      transform-origin: 33% -5%;
    }
    &:nth-child(8) {
      fill: ${({ theme }) => theme.colors.yellow500};
      transform: rotate(360deg) scale(1.8);
      transform-origin: -3% 0%;
    }
  }
`

const NameTape = styled.div`
  background: yellow;
  clip-path: polygon(5% 5%, 100% 0%, 98% 95%, 0% 100%);
  color: #9c1d78;
  font-family: FuturaCondensed;
  font-size: 65px;
  left: 25px;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 350px;
  transform: rotate(-4deg);
  width: 375px;
  z-index: ${SPHERES + 1};
`

const TalkDetails = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  position: absolute;
  right: 0;
  position: absolute;
  left: 450px;
  top: 45px;
  width: 675px;
  height: 325px;
  &:before,
  &:after {
    ${Sphere}
  }
  &:after {
    height: 75px;
    right: 80px;
    top: -50px;
    width: 75px;
  }
  &:before {
    height: 155px;
    right: -20px;
    top: -140px;
    width: 155px;
  }
`

const Title = styled.div`
  font-family: FuturaCondensed;
  font-size: 60px;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`

const Byline = styled.div`
  color: white;
  font-family: FuturaCondensedLightOblique;
  font-size: 45px;
  text-transform: uppercase;
  &:before {
    content: 'with ';
    text-transform: lowercase;
    font-size: 0.5em;
    padding-right: 1ch;
  }
`

const Footer = styled.footer`
  display: flex;
  align-items: center;
  position: absolute;
  left: 450px;
  top: 400px;
  width: 675px;
  z-index: ${SPHERES + 1};
  height: max-content;
  &:before,
  &:after {
    ${Sphere}
  }
  &:before {
    height: 75px;
    left: 175px;
    bottom: -70px;
    width: 75px;
  }
  &:after {
    height: 275px;
    right: -160px;
    bottom: -10px;
    width: 275px;
  }
`

const Logo = styled.div`
  flex: 1;
  img {
    width: 250px;
  }
`

const Details = styled.aside`
  color: white;
  font-family: FuturaBook;
  font-size: 1.5em;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: ${SPHERES + 1};
`

const Date = styled.span`
  display: block;
  font-family: FuturaHeavy;
  font-size: 1.65em;
  line-height: 1.3;
`

const Location = styled.span`
  display: block;
  text-transform: uppercase;
`

const Url = styled.span`
  color: ${({ theme }) => theme.colors.yellow700};
  display: block;
`

const SpeakerCard = ({ title, speakers }) => {
  const fullName = speakers.map(({ name }) => name).join(' and ')
  const firstNames =
    speakers.map(({ name }) => name.split(' ')[0]).join(' & ')
  const avatars = speakers.map(({ name }) => {
    const avatarName = `${name}-removebg`
    return <StyledHeadshot key={name} name={avatarName}/>
  })
  return (
    <Canvas>
      <CardStyles/>
      <Profile>
        <Frame>
          <ProfileBackground/>
          {avatars}
        </Frame>
        <Accents>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
          <polygon points="0,65 0,80 20,70"/>
        </Accents>
      </Profile>
      <NameTape>{firstNames}</NameTape>
      <TalkDetails>
        <Title>{title}</Title>
        <Byline>{fullName}</Byline>
      </TalkDetails>
      <Footer>
        <Logo>
          <img alt="" src={jsconf}/>
        </Logo>
        <Details>
          <Date>August 12 - 14</Date>
          <Location>Carlsbad, CA</Location>
          <Url>https://2019.jsconf.us</Url>
        </Details>
      </Footer>
    </Canvas>
  )
}

SpeakerCard.propTypes = {
  speakers: arrayOf(
    shape({
      name: String
    })
  ).isRequired,
  title: string
}

export default SpeakerCard
