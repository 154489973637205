import { func, node, string } from 'prop-types'
import { Headings, Link } from '../StyledComponents'
import GridRow from '../../components/GridRow/GridRow'
import React from 'react'
import styled from 'styled-components'

const Title = styled(Headings.H2)`
  align-self: flex-end;
  grid-area: title;
  margin-bottom: 0;
  margin-top: 0;
`

const Photos = styled.aside`
  grid-area: photos;
`

const Content = styled.section`
  grid-area: content;
  aside:empty + & {
    grid-column: 1 / -1;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    align-self: center;
  }
`

const Root = styled(GridRow)`
  align-self: center;
  border-top: solid 1px ${({ theme }) => theme.colors.pink100};
  display: grid;
  grid-template-areas: 'title' 'photos' 'content';
  grid-template-rows: max-content max-content;
  margin-top: 50px;
  padding-top: 50px;
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-gap: 20px;
    &:nth-child(even) {
      grid-template-columns: 2fr 3fr;
      grid-template-areas:
        'photos title'
        'photos content';
    }
    &:nth-child(odd) {
      grid-template-columns: 3fr 2fr;
      grid-template-areas:
        'title photos'
        'content photos';
    }
  }
  @media ${({ theme }) => theme.devices.laptopLargeMin} {
    &:nth-child(even) {
      grid-template-columns: 2fr 6fr;
    }
    &:nth-child(odd) {
      grid-template-columns: 6fr 2fr;
    }
  }
`

const BackToTopLink = styled(Link)`
  font-size: ${({ theme }) => theme.typography.bodySmall};
`

const Activity = ({ children, photos, title }) => (
  <Root>
    <Title>{title}</Title>
    <Photos>{photos && photos()}</Photos>
    <Content>{children}</Content>
    <BackToTopLink href="#top">Back to Top</BackToTopLink>
  </Root>
)

Activity.propTypes = {
  children: node.isRequired,
  photos: func,
  title: string.isRequired
}

export default Activity
