import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import { Paragraph } from '../StyledComponents'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :nth-child(2) {
    grid-column: 1 / span 5;
    grid-row: 6 / -1;
  }
  > :nth-child(3) {
    grid-column: 6 / -1;
    grid-row: 6 / -1;
  }
`

const SeaKayaking = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="kayaking.jpg"/>
        <ActivityImage src="MVIMG_20180822_152941.jpg"/>
        <ActivityImage src="IMG_8352.jpg"/>
      </StyledPhotoGrid>
    )}
    title="Sea Kayaking"
  >
    <Paragraph>
      Do you like to play in the open ocean waters, the cool sea spray
      whispering over you? Then join us for an exciting day of adventure and
      exploration!
    </Paragraph>
    <Paragraph>
      You will be kayaking in La Jolla, one of the many breathtaking ocean water
      areas in San Diego County. If you've ever wanted to try sea kayaking or
      just want to enjoy the thrill once again, this is the experience for you!
      After donning your wetsuit, you'll be paired up into a tandem kayak, and
      head right into the water, receiving instruction as you go.
    </Paragraph>
    <Paragraph>
      <strong>Note:</strong> You will get wet! Basic swimming competency is
      required. Please bring a towel, extra clothes, and a bathing suit.
      Wetsuits will be provided. Space is limited, register early!
    </Paragraph>
  </Activity>
)

export default SeaKayaking
