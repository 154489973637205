import { array, object, shape } from 'prop-types'
import { getNodes } from '../utils/nodeHelpers'
import GlobalStyle from '../components/GlobalStyle/GlobalStyle'
import { graphql } from 'gatsby'
import { LogosProvider } from '../contexts/Logos'
import React from 'react'
import SponsorCard from '../components/SponsorCard/SponsorCard'
import themeConfig from '../theme/theme'
import { ThemeProvider } from 'styled-components'

export default function SponsorCardTemplate ({
  data: {
    sponsorLogos: { edges: sponsorLogos },
    sponsor: {
      frontmatter: { level, name, scholarship }
    }
  },
  theme,
  ...props
}) {
  return (
    <LogosProvider value={getNodes(sponsorLogos)}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle/>
          <main>
            <SponsorCard level={level} name={name} scholarship={scholarship}/>
          </main>
        </>
      </ThemeProvider>
    </LogosProvider>
  )
}

SponsorCardTemplate.defaultProps = {
  theme: themeConfig
}

SponsorCardTemplate.propTypes = {
  data: shape({
    sponsorLogos: shape({
      edges: array
    })
  }),
  theme: object
}

export const pageQuery = graphql`
  query($id: String!) {
    sponsor: mdx(id: { eq: $id }) {
      frontmatter {
        component
        footer
        description
        level
        logo
        name
        scholarship
        url
      }
      id
      code {
        body
      }
    }
    sponsorLogos: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/sponsors/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fixed(width: 1120) {
              ...GatsbyImageSharpFixed
            }
          }
          publicURL
        }
      }
    }
  }
`
