import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import { Paragraph } from '../StyledComponents'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  grid-template-rows: 1fr repeat(6, 4fr) 1fr 1fr 1fr;
  > :nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 2 / -4;
  }
`

const GolfTournament = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="rhockelle.jpg"/>
      </StyledPhotoGrid>
    )}
    title="Golf Tournament"
  >
    <Paragraph>
      Take advantage of either of La Costa's world-class "Legends" golf courses!
    </Paragraph>
    <Paragraph>
      The tournament will be organized into teams of four (4) with all teams
      playing scramble format (AKA best shot of team is taken). Solo players
      will be formed into teams. Golf attire will be required: collared shirts,
      no jeans. Club rentals are available or you can bring your own.
    </Paragraph>
  </Activity>
)

export default GolfTournament
