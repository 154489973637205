import { Headings, Link, Paragraph } from '../StyledComponents'
import { node, string } from 'prop-types'
import React from 'react'
import SponsorLogo from '../SponsorLogo/SponsorLogo'
import styled from 'styled-components'

const Title = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacer.md};
  margin-top: ${({ theme }) => theme.spacer.lg};
  text-align: center;
`

const Subtitle = styled(Headings.H3)`
  margin-top: calc(${({ theme }) => theme.spacer.sm} * -1);
  margin-bottom: ${({ theme }) => theme.spacer.lg};
  text-align: center;
`

const Logo = styled(SponsorLogo)`
  img {
    max-width: 450px;
  }
`

const Footer = styled(Paragraph)`
  font-size: ${({ theme }) => theme.typography.bodySmall};
`

const Sponsor = ({ children, component, footer, logo, name, url }) => {
  let sponsoring = null
  if (component) {
    sponsoring = <Subtitle>Sponsoring {component}</Subtitle>
  }
  if (footer !== false) {
    footer = (
      <Footer>
        For more information, check out the{' '}
        <Link href={url}>official {name} website</Link>.
      </Footer>
    )
  }
  return (
    <>
      <Title>
        <Link aria-label={`Go to the ${name} website`} href={url}>
          <Logo name={name}/>
        </Link>
      </Title>
      {sponsoring}
      {children}
      {footer}
    </>
  )
}

Sponsor.propTypes = {
  children: node.isRequired,
  component: string,
  logo: string,
  name: string,
  url: string
}

export default Sponsor
