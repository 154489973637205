import { Blockquote, Headings, Link, Paragraph } from '../StyledComponents'
import GolfTournament from './GolfTournament'
import GridContainer from '../GridContainer/GridContainer'
import GridRow from '../GridRow/GridRow'
import NodeArt from './NodeArt'
import NodeBoats from './NodeBoats'
import NodeBots from './NodeBots'
import NodeRockets from './NodeRockets'
import OnYourOwn from './OnYourOwn'
import PoolsideRelaxation from './PoolsideRelaxation'
import React from 'react'
import SafariZooAdventure from './SafariZooAdventure'
import SeaKayaking from './SeaKayaking'
import StandUpPaddleBoarding from './StandUpPaddleBoarding'
import SurfingLessons from './SurfingLessons'
import TableOfContents from '../TableOfContents/TableOfContents'

const Activities = () => (
  <GridContainer>
    <GridRow>
      <Headings.H1>Activities</Headings.H1>
    </GridRow>
    <GridRow>
      <Headings.H3>How do I choose an activity?</Headings.H3>
      <Paragraph>
        Registration for our "Choose Your Own Adventure Day" activities will
        open to all{' '}
        <Link href="https://ti.to/jsconf-us/jsconf-us-2019">
          <em>ticketed conference attendees</em>
        </Link>{' '}
        on June 11th at 12pm Eastern Time, 9am Pacific Time.
      </Paragraph>
      <Paragraph>
        All attendees will receive an email the day prior with a link to the
        registration page where you can reserve a ticket for your activity of
        choice. Many of our activities have a{' '}
        <strong>limited number of tickets</strong> available! In the event an
        activity sells out, waitlists will be available to join in the event
        someone changes their mind or we add more tickets.
      </Paragraph>
      <Paragraph>
        <Blockquote>
          <Headings.H3>Activity Registration Full</Headings.H3>
          <strong>NOTE:</strong> All activities are currently at maximum
          capacity. However, we always have a few cancellations, so please join
          the waitlist of your preferred activity (available on the activity
          registration page which will be emailed to you upon registration). In
          the event of any day-of no-shows, spots will be offered on a
          first-come, first-serve basis at the pick-up area.
        </Blockquote>
      </Paragraph>
    </GridRow>
    <GridRow>
      <Headings.H3>Activities</Headings.H3>
      <TableOfContents
        items={[
          {
            title: 'NodeBoats',
            url: '#nodeboats'
          },
          {
            title: 'NodeBots',
            url: '#nodebots'
          },
          {
            title: 'NodeRockets',
            url: '#noderockets'
          },
          {
            title: 'NodeArt',
            url: '#nodeart'
          },
          {
            title: 'Safari Park Adventure',
            url: '#safari-park-adventure'
          },
          {
            title: 'Surfing Lessons',
            url: '#surfing-lessons'
          },
          {
            title: 'Sea Kayaking',
            url: '#sea-kayaking'
          },
          {
            title: 'Stand-Up Paddle Board Lessons',
            url: '#stand-up-paddle-board-lessons'
          },
          {
            title: 'Golf Tournament',
            url: '#golf-tournament'
          },
          {
            title: 'Poolside Relaxation',
            url: '#poolside-relaxation'
          },
          {
            title: 'On Your Own',
            url: '#on-your-own'
          }
        ]}
      />
    </GridRow>
    <NodeBoats/>
    <NodeBots/>
    <NodeRockets/>
    <NodeArt/>
    <SafariZooAdventure/>
    <SurfingLessons/>
    <SeaKayaking/>
    <StandUpPaddleBoarding/>
    <GolfTournament/>
    <PoolsideRelaxation/>
    <OnYourOwn/>
  </GridContainer>
)

export default Activities
