import { Headings, Link } from '../StyledComponents'
import { node, string } from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Item = styled.li`
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  padding: ${({ theme }) => theme.spacer.md};
`

const Title = styled(Headings.H6)`
  margin-bottom: ${({ theme }) => theme.spacer.sm};
`

const TitleLink = styled(Link)`
  color: ${({ theme }) => theme.colors.purple900};
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.pink700};
    text-decoration: underline;
  }
`

const Footer = styled.footer`
  margin-top: ${({ theme }) => theme.spacer.sm};
`

const ReadMoreLink = styled(Link)`
  color: ${({ theme }) => theme.colors.purple900};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.black700};
  }
  &:visited {
    color: ${({ theme }) => theme.colors.purple700};
  }
`

const Date = styled.aside`
  ${({ theme }) => theme.typography.bodySmall}
  color: ${({ theme }) => theme.colors.purpleB100};
`

const Body = styled.p`
  ${({ theme }) => theme.typography.bodySmall}
`

const NewsItem = ({ children, date, heading, slug, title }) => (
  <Item>
    <Title as="h3">
      <TitleLink href={slug}>{heading || title}</TitleLink>
      <Date>{date}</Date>
    </Title>
    <Body>{children}</Body>
    <Footer>
      <ReadMoreLink href={slug}>Read More</ReadMoreLink>
    </Footer>
  </Item>
)

NewsItem.propTypes = {
  children: node,
  date: string,
  heading: string,
  slug: string.isRequired,
  title: string.isRequired
}

export default NewsItem
