import { array } from 'prop-types'
import Attribution from './Attribution'
import { AvatarsConsumer } from '../../contexts/Avatars'
import { LogosConsumer } from '../../contexts/Logos'
import React from 'react'
import SponsorAttribution from './SponsorAttribution'
import styled from 'styled-components'

const List = styled.aside`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacer.xxs};
`

function componentizeSpeaker (entity, key) {
  return (
    <AvatarsConsumer key={key} {...entity}>
      {({ speakerImage }) => (
        <Attribution {...entity} avatar={speakerImage} round/>
      )}
    </AvatarsConsumer>
  )
}

function componentizeSponsor (entity, key) {
  return (
    <LogosConsumer key={key} {...entity}>
      {({ logoImage, subPath }) => (
        <SponsorAttribution
          {...entity}
          key={key}
          src={logoImage}
          subPath={subPath}
        />
      )}
    </LogosConsumer>
  )
}

const Attributions = ({ speakers, sponsors }) => {
  speakers || (speakers = [])
  sponsors || (sponsors = [])
  if (!speakers.length && !sponsors.length) {
    return null
  }
  const speakerList = speakers.map(componentizeSpeaker)
  const sponsorList = sponsors.map(componentizeSponsor)
  return (
    <List>
      {speakerList}
      {sponsorList}
    </List>
  )
}

Attributions.propTypes = {
  speakers: array,
  sponsors: array
}

export default Attributions
