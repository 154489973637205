// .cache/gatsby-mdx/wrapper-components/{wrapper-filepath-hash}-{scope-hash}.js
import React from 'react';
import { MDXScopeProvider } from 'gatsby-mdx/context';

import __mdxScope_0 from '/opt/build/repo/.cache/gatsby-mdx/mdx-scopes-dir/5ad43257480922f8ba62c7f6d81dffcf.js';

import OriginalWrapper from '/opt/build/repo/src/templates/SponsorCardTemplate.js';

import { graphql } from 'gatsby';

// pageQuery, etc get hoisted to here
export const pageQuery = graphql`
  query($id: String!) {
    sponsor: mdx(id: { eq: $id }) {
      frontmatter {
        component
        footer
        description
        level
        logo
        name
        scholarship
        url
      }
      id
      code {
        body
      }
    }
    sponsorLogos: allFile(
      filter: {
        internal: { mediaType: { regex: "/image/" } }
        relativePath: { regex: "/sponsors/" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fixed(width: 1120) {
              ...GatsbyImageSharpFixed
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default ({children, ...props}) => <MDXScopeProvider __mdxScope={{...__mdxScope_0}}>
  <OriginalWrapper
    {...props}
  >
    {children}
  </OriginalWrapper>
</MDXScopeProvider>