import { Link, Paragraph } from '../../components/StyledComponents'
import Activity from '../../components/Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :first-child {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :last-child {
    grid-column: 1 / -1;
    grid-row: 6 / -1;
  }
`

const OnYourOwn = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="carlsbad.jpg"/>
        <ActivityImage src="twilio-beach.jpg"/>
      </StyledPhotoGrid>
    )}
    title="On Your Own"
  >
    <Paragraph>
      Want to venture out on your own? Great! Carlsbad and the surrounding
      cities of Oceanside, Escondido, and Encinitas have plenty to offer!
      LEGOLand, the Flower Fields, nearly twenty craft breweries, several
      beaches, and dozens of great restaurants are all a short drive away!
    </Paragraph>
    <Paragraph>
      Check out our <Link href="/about/city-guide">City Guide</Link> for more
      information!
    </Paragraph>
  </Activity>
)

export default OnYourOwn
