/* eslint-disable no-ternary, no-confusing-arrow */
import { bool, oneOf } from 'prop-types'
import styled from 'styled-components'

const Button = styled.button`
  ${({ theme }) => theme.typography.secondary}
  ${({ theme }) => theme.typography.heading.lg}
  background: ${({ secondary, theme }) =>
    secondary ? theme.colors.darkBlue500 : theme.colors.yellow500};
  clip-path: polygon(0% 0, 100% 10%, 90% 100%, 5% 90%);
  && {
    color: ${({ secondary, theme }) =>
    secondary ? theme.colors.white : theme.colors.darkBlue500};
  }
  padding: ${({ theme }) => theme.spacer.sm};
  text-decoration: none;
  text-shadow: -2px -2px 0 ${({ secondary, theme }) =>
    secondary ? theme.colors.darkBlue800 : theme.colors.white};
  transition: clip-path 0.2s ease-in-out;
  &:hover {
    clip-path: polygon(5% 10%, 90% 0%, 100% 90%, 0% 100%);
  }
`

Button.defaultProps = {
  type: 'button'
}

Button.propTypes = {
  primary: bool,
  secondary: bool,
  type: oneOf(['button', 'submit'])
}

export default Button
