import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import { Paragraph } from '../../components/StyledComponents'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :nth-child(1) {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :nth-child(2) {
    grid-column: 1 / -1;
    grid-row: 6 / span 5;
  }
`

const PoolsideRelaxation = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="edge.jpg"/>
        <ActivityImage src="cabana.jpg"/>
      </StyledPhotoGrid>
    )}
    title="Poolside Relaxation"
  >
    <Paragraph>
      Want to be outside, but still need access to the Internet? Come on down to
      the Omni La Costa's Edge Pool where you can get a nice cold drink, a bit
      of food, WiFi, and cool off with a dip in the pool.
    </Paragraph>
    <Paragraph>
      Have kids along for the ride? Are you a kid yourself? Head over to the
      kids pool and check out their sandy beach or take a slide down not one,
      but TWO waterslides!
    </Paragraph>
    <Paragraph>
      Even if you are registered for another event, stop by the pool for a bit!
      Who knows, you may even catch a repeat of synchronized.swimming.js.
    </Paragraph>
  </Activity>
)

export default PoolsideRelaxation
