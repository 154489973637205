/* eslint-disable no-confusing-arrow, no-ternary */
import { arrayOf, shape, string } from 'prop-types'
import Attributions from './Attributions'
import Image from '../Image/Image'
import { Link } from '../StyledComponents'
import { LogosConsumer } from '../../contexts/Logos'
import React from 'react'
import { slugify } from '../../utils/string'
import styled from 'styled-components'

const Root = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.spacer.xs};
  color: currentColor;
  display: grid;
  grid-column-start: 2;
  grid-template-rows:
    [title] min-content
    [location] min-content
    [descr] 1fr
    [attr] max-content;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacer.xs};
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  &[href] {
    &:hover {
      color: currentColor;
      transform: scale(1.02);
    }
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    &[data-count='1'] {
      grid-column-end: -1;
    }
    &[data-count='2'] {
      grid-column-end: span 6;
      &[data-index='0'] {
        grid-column-start: 2;
      }
      &[data-index='1'] {
        grid-column-start: 8;
      }
    }
    &[data-count='3'] {
      grid-column-end: span 4;
      &[data-index='0'] {
        grid-column-start: 2;
      }
      &[data-index='1'] {
        grid-column-start: 6;
      }
      &[data-index='2'] {
        grid-column-start: 10;
      }
    }
    &[data-count='4'] {
      grid-column-end: span 3;
      &[data-index='0'] {
        grid-column-start: 2;
      }
      &[data-index='1'] {
        grid-column-start: 5;
      }
      &[data-index='2'] {
        grid-column-start: 8;
      }
      &[data-index='3'] {
        grid-column-start: 11;
      }
    }
    padding: ${({ theme }) => theme.spacer.sm};
  }
`

const Title = styled.header`
  color: ${({ theme }) => theme.colors.purple600};
  font-family: Futura;
  ${({ theme }) => theme.typography.heading.sm};
  margin-bottom: 0;
`

const Descr = styled.div`
  ${({ theme }) => theme.typography.bodySmall};
  margin-top: 15px;
  margin-bottom: 15px;
`

const Location = styled.aside`
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.colors.purple500};
`

const StyledImage = styled(Image)`
  margin: 0 auto;
  img {
    max-height: 150px;
  }
`

const Session = ({
  description,
  href,
  image,
  location,
  speakers,
  sponsors,
  title,
  ...props
}) => {
  let rootProps = {
    ...props,
    id: slugify(title)
  }
  let tag = 'div'
  if (href) {
    rootProps = {
      ...rootProps,
      href
    }
    tag = Link
  }
  image || (image = {})
  return (
    <LogosConsumer name={image.name}>
      {({ logoImage, subPath }) => (
        <Root as={tag} {...rootProps}>
          <Title>{title}</Title>
          <Location>{location}</Location>
          <Descr>{description}</Descr>
          <StyledImage src={logoImage} subPath={subPath}/>
          <Attributions speakers={speakers} sponsors={sponsors}/>
        </Root>
      )}
    </LogosConsumer>
  )
}

Session.propTypes = {
  description: string,
  href: string,
  image: shape({
    name: string.isRequired
  }),
  location: string,
  speakers: arrayOf(
    shape({
      avatar: string,
      name: string.isRequired
    })
  ),
  sponsors: arrayOf(
    shape({
      logo: string,
      name: string.isRequired
    })
  ),
  title: string.isRequired
}

export default Session
