import { AvatarsConsumer } from '../../contexts/Avatars'
import Img from 'gatsby-image'
import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const StyledImg = styled(Img)`
  height: 0;
  padding-top: 100%;
  width: 100%;
`

const Headshot = ({ name, ...props }) => (
  <AvatarsConsumer key={name} name={name}>
    {({ speakerImage }) => {
      if (!speakerImage) {
        return null
      }
      // TODO: Replace this image with Image.js
      return (
        <StyledImg
          fixed={speakerImage.childImageSharp.fixed}
          fluid={speakerImage.childImageSharp.fluid}
          {...props}
        />
      )
    }}
  </AvatarsConsumer>
)

Headshot.propTypes = {
  name: string.isRequired
}

export default Headshot
