import { createGlobalStyle } from 'styled-components'
import { fontFaces } from '../../theme/typography/fonts'

const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${({ theme }) => theme.colors.selectionBg}
    color: ${({ theme }) => theme.colors.selectionText}
  }

  ::-moz-selection {
    background: var(--color-selection-bg);
  }

  :root {
    --whitespace: ${({ theme }) => theme.spacer.md};
    --whitespace-neg: calc(${({ theme }) => theme.spacer.md} * -1);
    @media ${({ theme }) => theme.devices.tabletMin} {
      --whitespace: 16.4vw;
      --whitespace-neg: -16.4vw;
    }
  }

  ${fontFaces}

  *,
  *:before,
  *:after {
    border: 0;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  html,
  body,
  #___gatsby,
  #___gatsby > div {
    height: 100%;
    ${({ theme }) => theme.typography.body}
  }

  button {
    background: none;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: inherit;
  }

  *[hidden] {
    display: none !important;
  }
`

export default GlobalStyle
