import styled from 'styled-components'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns:
    [full-start] ${({ theme }) => theme.spacer.sm}
    [wide-start] 0
    [medium-start] 0
    [narrow-start] minmax(0, 1fr)
    [skinny-start] 7fr
    [skinny-end] minmax(0, 1fr)
    [narrow-end] 0
    [medium-end] 0
    [wide-end] ${({ theme }) => theme.spacer.sm}
    [full-end];
  ${({ theme }) => theme.media.sm} {
    grid-template-columns:
      [full-start] ${({ theme }) => theme.spacer.sm}
      [wide-start] 1fr
      [medium-start] minmax(1em, 1fr)
      [narrow-start] minmax(0, 1fr)
      [skinny-start] 7fr
      [skinny-end] minmax(0, 1fr)
      [narrow-end] minmax(1em, 1fr)
      [medium-end] 1fr
      [wide-end] ${({ theme }) => theme.spacer.sm}
      [full-end];
  }
`
export default GridContainer
