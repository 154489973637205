import { node, oneOf } from 'prop-types'
import React, { Children } from 'react'
import styled from 'styled-components'

const Root = styled.ul`
  display: grid;
  grid-auto-columns: ${({ align }) => {
    switch (align) {
      case 'stretch':
        return 'initial'
      default:
        return 'max-content'
    }
  }};
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacer.xs};
  list-style-type: none;
  margin-bottom: ${({ theme }) => theme.spacer.sm};
  margin-top: ${({ theme }) => theme.spacer.sm};
`

const ImageStrip = ({ align, children }) => {
  const wrappedChildren = Children.map(children, (child) => <li>{child}</li>)
  return <Root align={align}>{wrappedChildren}</Root>
}

ImageStrip.propTypes = {
  align: oneOf(['stretch']),
  children: node.isRequired
}

export default ImageStrip
