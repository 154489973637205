import { createContext, useContext } from 'react'
import { func, string } from 'prop-types'
import { slugify } from '../utils/string'

const LogosContext = createContext([])
const LogosProvider = LogosContext.Provider

function findLogo (imageNode, logo, name) {
  if (!name) {
    return false
  }
  const filename = logo || slugify(name).toLowerCase()
  return imageNode.base.includes(filename)
}

const LogoRequireSubPath = 'sponsors'

const LogosConsumer = ({ children, logo, name }) => {
  const imageNodes = useContext(LogosContext)
  const logoImage = imageNodes.find((imageNode) =>
    findLogo(imageNode, logo, name)
  )
  return children({ logoImage, subPath: LogoRequireSubPath })
}

LogosConsumer.propTypes = {
  children: func.isRequired,
  logo: string,
  name: string
}

export { LogosConsumer, LogosContext, LogosProvider }
