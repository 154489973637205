import styled, { ThemeProvider } from 'styled-components'
import CallToAction from '../../components/CallToAction/CallToAction'
import GlobalStyle from '../../components/GlobalStyle/GlobalStyle'
import HeroBackground from '../../components/Hero/HeroBackground'
import logo from '../../assets/images/logo.svg'
import { object } from 'prop-types'
import React from 'react'
import themeConfig from '../../theme/theme'

// See also: https://blog.snappa.com/twitter-header-size/

const Canvas = styled.div`
  height: 500px;
  padding-bottom: 83px;
  padding-top: 83px;
  overflow: hidden;
  position: relative;
  width: 1500px;
`

const Background = styled(HeroBackground)`
  height: 100%;
  margin: 0;
  transform: none;
  z-index: -1;
`

const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 1fr 96px;
  height: 100%;
  overflow: hidden;
`

const HeroImage = styled.div`
  text-align: center;
  position: relative;
  z-index: 3;
  height: 100%;
  img {
    height: auto;
    max-height: 100%;
  }
`

const Cta = styled(CallToAction)`
  margin-bottom: 0;
  margin-top: 0;
  overflow: visible;
`

const TwitterBackground = ({ theme }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle/>
      <Canvas>
        <Content>
          <HeroImage medium>
            <img alt="" src={logo}/>
          </HeroImage>
          <Cta subhead="Carlsbad, CA" width="auto">
            August 12th - 14th
          </Cta>
        </Content>
        <Background/>
      </Canvas>
    </>
  </ThemeProvider>
)

TwitterBackground.defaultProps = {
  theme: themeConfig
}

TwitterBackground.propTypes = {
  theme: object
}

export default TwitterBackground
