import { graphql, StaticQuery } from 'gatsby'
import { Headings, Paragraph } from '../StyledComponents'
import GridRow from '../GridRow/GridRow'
import Img from 'gatsby-image'
import LinkButton from '../LinkButton/LinkButton'
import MemphisLowerLeft from '../../assets/images/memphis-ll.svg'
import MemphisUpperRight from '../../assets/images/memphis-ur.svg'
import React from 'react'
import styled from 'styled-components'

const StyledImg = styled(Img)`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLargeOnly} {
    &:nth-of-type(-n + 3) {
      display: initial;
    }
  }
  @media ${({ theme }) => theme.devices.tabletOnly} {
    &:nth-of-type(-n + 7) {
      display: initial;
    }
  }
  @media ${({ theme }) => theme.devices.laptopOnly} {
    &:nth-of-type(-n + 9) {
      display: initial;
    }
  }
  @media ${({ theme }) => theme.devices.laptopLargeOnly} {
    &:nth-of-type(-n + 13) {
      display: initial;
    }
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    display: initial;
  }
`

const Photos = styled.div`
  background-color: ${({ theme }) => theme.colors.yellowA700};
  display: grid;
  @media ${({ theme }) => theme.devices.mobileLargeOnly} {
    -ms-wtf: 3;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 220px);
  }
  @media ${({ theme }) => theme.devices.tabletOnly} {
    -ms-wtf: 4;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 220px);
  }
  @media ${({ theme }) => theme.devices.laptopOnly} {
    -ms-wtf: 5;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 220px);
  }
  @media ${({ theme }) => theme.devices.laptopLargeOnly} {
    -ms-wtf: 6;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 220px);
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    -ms-wtf: 7;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 220px);
  }
`

const Content = styled.div`
  align-content: center;
  background-image: url(${MemphisLowerLeft}), url(${MemphisUpperRight});
  background-position: bottom left, top right;
  background-size: 30%;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.colors.black400};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content);
  justify-items: center;
  padding: ${({ theme }) => theme.spacer.lg} ${({ theme }) => theme.spacer.md};
  @media ${({ theme }) => theme.devices.mobileLargeMin} {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    grid-column: 1 / span 3;
    grid-row: 2 / span 2;
    padding: ${({ theme }) => theme.spacer.lg} ${({ theme }) => theme.spacer.xl};
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
    padding: ${({ theme }) => theme.spacer.lg} ${({ theme }) => theme.spacer.xl};
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    background-size: 25%;
    grid-column: 1 / span 3;
    grid-row: 2 / span 2;
    padding: ${({ theme }) => theme.spacer.lg}
      ${({ theme }) => theme.spacer.xxl};
  }
`

const RainbowText = styled(Headings.H2)`
  ${({ theme }) => theme.typography.heading.xl};
  color: ${({ theme }) => theme.colors.black400};
  margin-bottom: ${({ theme }) => theme.spacer.sm};
  margin-top: 0;
  text-align: center;
  text-shadow: -2px 2px ${({ theme }) => theme.colors.yellow500},
    -4px 4px orange, -6px 6px red,
    -6px 6px ${({ theme }) => theme.colors.purple500},
    -8px 8px ${({ theme }) => theme.colors.darkBlue500},
    -10px 10px ${({ theme }) => theme.colors.blue500};
`

const StyledLinkButton = styled(LinkButton)`
  display: block;
`

const Activities = () => (
  <StaticQuery
    query={graphql`
      query {
        activity1: file(relativePath: { eq: "nodeboats-nick-and-nick.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity2: file(relativePath: { eq: "nodeboats-group.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity3: file(relativePath: { eq: "nodeboats-build.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity4: file(relativePath: { eq: "nodeboats-boat.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity5: file(relativePath: { eq: "twilio-beach.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity6: file(relativePath: { eq: "kayaking.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity7: file(relativePath: { eq: "nodebots.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity8: file(relativePath: { eq: "sup.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity9: file(relativePath: { eq: "kayaking-drone.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity10: file(relativePath: { eq: "safari-park.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity11: file(relativePath: { eq: "surfing.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity12: file(relativePath: { eq: "golf.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity13: file(relativePath: { eq: "safari-lion.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        activity14: file(relativePath: { eq: "kayak-setup.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 220) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <GridRow full>
        <Photos>
          <Content>
            <RainbowText>Activities</RainbowText>
            <Paragraph>
              Our "Choose Your Own Adventure" day is what sets JSConf US apart
              from other conferences. It provides you with an extraordinary
              opportunity to socialize with the brightest minds in our community
              in a new and different environment. We've got IoT workshops like
              NodeBots and NodeBoats for those that want to learn how to program
              the physical world and Surfing lessons or a trip to the San Diego
              Zoo Safari Park for those that want to step away from the computer
              and go outside!
            </Paragraph>
            <div>
              <StyledLinkButton href="/about/activities">
                Learn More
              </StyledLinkButton>
            </div>
          </Content>
          <StyledImg fluid={data.activity4.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity5.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity6.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity7.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity1.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity2.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity3.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity8.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity9.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity10.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity11.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity12.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity13.childImageSharp.fluid}/>
          <StyledImg fluid={data.activity14.childImageSharp.fluid}/>
        </Photos>
      </GridRow>
    )}
  />
)

export default Activities
