import { array } from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import TeamBackground from '../../assets/images/abstract/2.svg'
import TeamList from '../TeamList/TeamList'

const Root = styled.div`
  background-image: url(${TeamBackground});
  background-size: 500px 500px;
`

const TeamRoster = ({
  mcs,
  nodeart,
  nodeboats,
  nodebots,
  noderockets,
  staff
}) => (
  <Root>
    <TeamList people={staff} title="Staff"/>
    <TeamList id="mcs" people={mcs} title="MCs"/>
    <TeamList id="nodeart" people={nodeart} title="NodeArt"/>
    <TeamList id="nodeboats" people={nodeboats} title="NodeBoats"/>
    <TeamList id="nodebots" people={nodebots} title="NodeBots"/>
    <TeamList id="noderockets" people={noderockets} title="NodeRockets"/>
  </Root>
)

TeamRoster.propTypes = {
  mcs: array,
  nodeart: array,
  nodeboats: array,
  nodebots: array,
  noderockets: array,
  staff: array
}

export default TeamRoster
