import React, { memo } from 'react'
import FooterLink from './FooterLink'
import { List } from './LinkGroup'
import styled from 'styled-components'

const EventList = styled(List)`
  display: flex;
  justify-content: space-evenly;
  ${FooterLink} {
    text-decoration: none;
  }
`

const PastEventLinks = memo(() => (
  <EventList>
    <li>
      <FooterLink href="https://2018.jsconf.us">2018</FooterLink>
    </li>
    <li>
      <FooterLink href="https://lastcall.jsconf.us">Last Call</FooterLink>
    </li>
    <li>
      <FooterLink href="https://2015.jsconf.us">2015</FooterLink>
    </li>
    <li>
      <FooterLink href="https://2014.jsconf.us">2014</FooterLink>
    </li>
    <li>
      <FooterLink href="https://2013.jsconf.us">2013</FooterLink>
    </li>
    <li>
      <FooterLink href="https://2012.jsconf.us">2012</FooterLink>
    </li>
    <li>
      <FooterLink href="https://2010.jsconf.us">2010</FooterLink>
    </li>
    <li>
      <FooterLink href="https://2009.jsconf.us">2009</FooterLink>
    </li>
  </EventList>
))

export default PastEventLinks
