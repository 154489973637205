import { graphql, StaticQuery } from 'gatsby'
import { node, object, shape, string } from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import Footer from '../components/Footer/Footer'
import GlobalStyle from '../components/GlobalStyle/GlobalStyle'
import Header from '../components/Header/Header'
import Helmet from 'react-helmet'
import React from 'react'
import Seo from '../components/Seo/Seo'
import themeConfig from '../theme/theme'

const Root = styled.div`
  height: 100%;
`

const Page = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  height: 100%;
  min-width: 320px;
  &:before,
  &:after {
    background: ${({ theme }) => theme.colors.black500};
    border: solid 5px ${({ theme }) => theme.colors.pink500};
    color: white;
    display: inline-block;
    display: none;
    font-weight: bold;
    padding: ${({ theme }) => theme.spacer.xxs};
    position: fixed;
    top: 0;
    text-transform: uppercase;
    z-index: 10000000;
  }
  &:before {
    left: 0;
    @media ${({ theme }) => theme.devices.mobileSmallMin} {
      content: 'Mobile S';
    }
    @media ${({ theme }) => theme.devices.mobileMediumMin} {
      content: 'Mobile M';
    }
    @media ${({ theme }) => theme.devices.mobileLargeMin} {
      content: 'Mobile L';
    }
    @media ${({ theme }) => theme.devices.tabletMin} {
      content: 'Tablet';
    }
    @media ${({ theme }) => theme.devices.laptopMin} {
      content: 'Laptop';
    }
    @media ${({ theme }) => theme.devices.laptopLargeMin} {
      content: 'Laptop L';
    }
    @media ${({ theme }) => theme.devices.FourKMin} {
      content: '4K';
    }
  }
  &:after {
    right: 0;
    ${({ theme }) => theme.media.xxs} {
      content: 'xxs';
    }
    ${({ theme }) => theme.media.xs} {
      content: 'xs';
    }
    ${({ theme }) => theme.media.sm} {
      content: 'sm';
    }
    ${({ theme }) => theme.media.md} {
      content: 'md';
    }
    ${({ theme }) => theme.media.lg} {
      content: 'lg';
    }
    ${({ theme }) => theme.media.xlg} {
      content: 'xlg';
    }
    @media (max-width: 319px) {
      background: ${({ theme }) => theme.colors.pink500};
      content: 'TOO NARROW';
      font-size: 2em;
      text-align: center;
      width: 100%;
    }
  }
`

const Main = styled.main`
  height: calc(100% + ${({ theme }) => theme.spacer.xl});
`

const DefaultLayout = ({
  children,
  description,
  location: { pathname },
  openGraph,
  theme,
  title,
  twitter
}) => (
  <StaticQuery
    query={graphql`
      query DefaultLayoutQuery {
        site {
          siteMetadata {
            description
            siteUrl
            sponsorFormUrl
            title
            titoUrl
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => {
      description || (description = siteMetadata.description)
      if (title) {
        title = `${title} | ${siteMetadata.title}`
      } else {
        title = siteMetadata.title
      }
      return (
        <Root id="top">
          <Helmet
            link={[
              {
                href: 'https://goo.gl',
                rel: 'preconnect'
              },
              {
                href: 'https://ti.to',
                rel: 'preconnect'
              },
              {
                href: siteMetadata.titoUrl,
                rel: 'preconnect'
              },
              {
                href: siteMetadata.sponsorFormUrl,
                rel: 'preconnect'
              }
            ]}
            meta={[
              { content: 'text/html;charSet=UTF-8', httpEquiv: 'Content-type' },
              {
                content:
                  'width=device-width, initial-scale=1, minimum-scale=1.0',
                name: 'viewport'
              }
            ]}
            title={title}
          >
            <html lang="en"/>
          </Helmet>
          <Seo
            currentPath={pathname}
            description={description}
            openGraph={openGraph}
            siteName={siteMetadata.title}
            title={title}
            twitter={twitter}
          />
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyle/>
              <Page>
                <Header ticketsUrl={siteMetadata.titoUrl}/>
                <Main>{children}</Main>
                <Footer/>
              </Page>
            </>
          </ThemeProvider>
        </Root>
      )
    }}
  />
)

DefaultLayout.defaultProps = {
  location: {
    pathname: '/'
  },
  theme: themeConfig
}

DefaultLayout.propTypes = {
  children: node.isRequired,
  description: string,
  location: shape({
    pathname: string.isRequired
  }),
  openGraph: object,
  theme: object,
  title: string,
  twitter: object
}

export default DefaultLayout
