import Activity from '../Activity/Activity'
import ActivityImage from '../PhotoGrid/ActivityImage'
import { Paragraph } from '../StyledComponents'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import React from 'react'
import styled from 'styled-components'

const StyledPhotoGrid = styled(PhotoGrid)`
  > :first-child {
    grid-column: 1 / -1;
    grid-row: 1 / span 5;
  }
  > :last-child {
    grid-column: 1 / -1;
    grid-row: 6 / -1;
  }
`

const NodeBoats = (props) => (
  <Activity
    photos={() => (
      <StyledPhotoGrid>
        <ActivityImage src="nodeboats-boat.jpg"/>
        <ActivityImage src="nodeboats-build.jpg"/>
      </StyledPhotoGrid>
    )}
    title="NodeBoats"
  >
    <Paragraph>
      Take the mission of spreading JavaScript to every corner of the world to
      its next logical conclusion, the water!
    </Paragraph>
    <Paragraph>
      Join a worthy crew (or cross the seas solo) to hack together a boat (or a
      boat-like floaty object) using Node.js to bring the seas under your
      control. At the end of the day, all participants will be invited to leave
      the safety of harbor to head out for a one-of-a-kind JavaScript regatta as
      entrants race their boats across the hotel's kiddie pool! Bonus points if
      you can turn around and head back to shore!
    </Paragraph>
    <Paragraph>
      <strong>Note:</strong> You may get wet on this ride as you will
      undoubtedly need to rescue your vessel at least once. Dress appropriately!
    </Paragraph>
  </Activity>
)

export default NodeBoats
