import { Headings, Link, Paragraph } from '../StyledComponents'
import GridRow from '../GridRow/GridRow'
import React from 'react'

const FAQ = () => (
  <GridRow>
    <Headings.H2>F.A.Q.</Headings.H2>
    <Paragraph>
      Never been to JSConf US? Do you have questions about the venue,
      conference, meals, or activities?
    </Paragraph>
    <Paragraph>
      Check out our <Link href="/about/faq">F.A.Q.</Link> for some helpful
      answers!
    </Paragraph>
  </GridRow>
)

export default FAQ
