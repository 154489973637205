import { bool, node, number, string } from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.aside`
  margin-bottom: 30px;
  margin-top: 30px;
  @media ${({ theme }) => theme.devices.laptopMin} {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }
`

const Box = styled.div`
  background: white;
  border: solid 5px #000;
  font-family: Futura;
  letter-spacing: 2px;
  margin-left: calc(var(--whitespace-neg) + 10px);
  margin-right: calc(var(--whitespace-neg) + 10px);
  outline: solid 10px ${({ theme }) => theme.colors.pink500};
  padding: ${({ theme }) => theme.spacer.xs};
  text-align: center;
  text-transform: uppercase;
  strong {
    display: inline-block;
    font-weight: 700;
    letter-spacing: 2px;
    margin-left: 1ch;
    margin-right: 1ch;
  }
  @media ${({ theme }) => theme.devices.tabletMin} {
    margin-left: 0;
    margin-right: 0;
    outline: none;
    box-shadow: 10px 10px 0 ${({ theme }) => theme.colors.pink500};
    strong {
      ${({ theme }) => theme.typography.heading.md};
      letter-spacing: 5px;
    }
  }
  @media ${({ theme }) => theme.devices.laptopMin} {
    header {
      white-space: nowrap;
    }
    strong {
      white-space: nowrap;
    }
  }
  @media ${({ theme }) => theme.devices.FourKMin} {
    box-shadow: 20px 20px 0 ${({ theme }) => theme.colors.pink500};
    header {
      ${({ theme }) => theme.typography.heading.xl};
    }
    strong {
      ${({ theme }) => theme.typography.heading.xxl};
    }
  }
`
const CallToAction = ({
  children,
  subhead,
  maxSize,
  minSize,
  multiLine,
  width,
  ...props
}) => (
  <Container {...props}>
    <Box>
      <header>{subhead}</header>
      <strong>{children}</strong>
    </Box>
  </Container>
)

CallToAction.defaultProps = {
  width: '50vw'
}

CallToAction.propTypes = {
  children: node.isRequired,
  maxSize: number,
  minSize: number,
  multiLine: bool,
  subhead: node,
  width: string
}

export default CallToAction
