import { css } from 'styled-components'

const breakpoints = {
  lg: 1024,
  md: 900,
  sm: 600,
  xlg: 1200,
  xs: 478,
  xxs: 0
}

const deviceConfigs = [
  ['mobileSmall', 320],
  ['mobileMedium', 375],
  ['mobileLarge', 425],
  ['tablet', 768],
  ['laptop', 1024],
  ['laptopLarge', 1440],
  ['FourK', 2560]
]

const maxMediaQueries = {
  lg: `@media (max-width: ${breakpoints.xlg - 1}px)`,
  md: `@media (max-width: ${breakpoints.lg - 1}px)`,
  sm: `@media (max-width: ${breakpoints.md - 1}px)`,
  xlg: `@media (min-width: ${breakpoints.xlg}px)`,
  xs: `@media (max-width: ${breakpoints.sm - 1}px)`,
  xxs: `@media (max-width: ${breakpoints.xs - 1}px)`
}

const devices = deviceConfigs.reduce(
  (queries, [deviceName, width], index) => ({
    ...queries,
    [`${deviceName}Min`]: `(min-width: ${width}px)`,
    [`${deviceName}Max`]:
      deviceConfigs[index + 1] &&
      `(max-width: ${deviceConfigs[index + 1][1] - 1}px)`,
    [`${deviceName}Only`]:
      deviceConfigs[index + 1] &&
      `(min-width: ${width}px) and (max-width: ${deviceConfigs[index + 1][1] -
        1}px)`
  }),
  {}
)

const exclusiveQueries = {
  lg: (...args) => css`
    @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xlg -
        1}px) {
      ${css(...args)}
    }
  `,
  md: (...args) => css`
    @media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg -
        1}px) {
      ${css(...args)}
    }
  `,
  sm: (...args) => css`
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md -
        1}px) {
      ${css(...args)}
    }
  `,
  xlg: (...args) => css`
    @media (min-width: ${breakpoints.xlg}px) {
      ${css(...args)}
    }
  `,
  xs: (...args) => css`
    @media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.sm -
        1}px) {
      ${css(...args)}
    }
  `,
  xxs: (...args) => css`
    @media (max-width: ${breakpoints.xs - 1}px) {
      ${css(...args)}
    }
  `
}

export {
  breakpoints,
  devices,
  exclusiveQueries as onlyMedia,
  maxMediaQueries as maxMedia
}

export default {
  lg: `@media (min-width: ${breakpoints.lg}px)`,
  md: `@media (min-width: ${breakpoints.md}px)`,
  sm: `@media (min-width: ${breakpoints.sm}px)`,
  xlg: `@media (min-width: ${breakpoints.xlg}px)`,
  xs: `@media (min-width: ${breakpoints.xs}px)`,
  xxs: `@media (max-width: ${breakpoints.xs - 1}px)`
}
