import media, { breakpoints, devices, maxMedia, onlyMedia } from './media'
import colors from './colors'
import spacer from './spacer'
import typography from './typography/typography'

export default {
  breakpoints,
  colors,
  devices,
  maxMedia,
  media,
  navHeight: '75px',
  onlyMedia,
  spacer,
  typography,
  zIndex: {
    heroContent: 10
  }
}
